import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
} from "reactstrap";

interface Params extends RouteComponentProps {
  isOpen: boolean;
  toggle: () => void;
  email: string;
  phone: string;
  gstin: string;
  pan: string;
}

type State = {
  modalInfo: boolean;
  isSending: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  phone: string;
  gstin: string;
  pan: string;
};

class VendorInfoModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalInfo: false,
      isSending: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      phone: "",
      gstin: "",
      pan: "",
    };
  }

  componentWillReceiveProps(props: Params) {
    this.setState({
      modalInfo: props.isOpen,
      email: props.email,
      phone: props.phone,
      gstin: props.gstin,
      pan: props.pan,
    });
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
        >
          <div className="modal-content">
            <div className="modal-body inv_modal_body custom-modal-body">
              <table className="table table-bordered custom-table">
                <tbody>
                  <tr>
                    <td className="w-25">GST</td>
                    <td className="w-75">{this.state.gstin}</td>
                  </tr>
                  <tr>
                    <td className="w-25">PAN</td>
                    <td className="w-75">{this.state.pan}</td>
                  </tr>
                  <tr>
                    <td className="w-25">Email</td>
                    <td className="w-75">{this.state.email}</td>
                  </tr>
                  <tr>
                    <td className="w-25">Mobile</td>
                    <td className="w-75">{this.state.phone}</td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex custom-modal-footer">
                <div className="text-center w-100 custom-text-center">
                  <Button
                    type="button"
                    className="btn-sm bmt-btn-close inv-info"
                    onClick={() => this.props.toggle()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(VendorInfoModal);
