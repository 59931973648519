import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "reactstrap";

// Import Editor
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { post } from "src/helpers/api_helper";

interface Params extends RouteComponentProps {
  id: string;
  customer_name: string;
  isOpen: boolean;
  toggle: () => void;
  is_ewaybilled: number;
  invoice_no: string;
  customer_id: string;
}

type State = {
  id: string;
  customer_name: string;
  modalInfo: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  modelLedger: boolean;
  isCustomerChecked: boolean;
  isTransportChecked: boolean;
  isSupplierChecked: boolean;
  isdeliverynoteChecked: boolean;
  isEwayBillChecked: boolean;
  invoice_no: string;
  customer_id: string;
  isProgress: number;
  is_ewaybilled: number;
};

class DownloadModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    const html = `<p>download invoice</p>`;
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.state = {
      id: "",
      customer_name: "",
      modalInfo: false,
      isSending: false,
      editorState: EditorState.createWithContent(content),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      modelLedger: false,
      isCustomerChecked: true,
      isTransportChecked: false,
      isSupplierChecked: false,
      isdeliverynoteChecked: false,
      isEwayBillChecked: false,
      isProgress: 0,
      invoice_no: "",
      customer_id: "",
      is_ewaybilled: 0,
    };
  }

  componentWillReceiveProps(props: Params) {
    this.setState({
      id: props.id,
      customer_name: props.customer_name,
      modalInfo: props.isOpen,
      invoice_no: props.invoice_no,
      is_ewaybilled: props.is_ewaybilled,
    });
  }
  changeSupplier = (e: any) => {
    this.setState({ isSupplierChecked: e.target.checked });
  };

  changeTransport = (e: any) => {
    this.setState({ isTransportChecked: e.target.checked });
  };
  changedeliverynote = (e: any) => {
    this.setState({ isdeliverynoteChecked: e.target.checked });
  };
  changeCustomer = (e: any) => {
    this.setState({ isCustomerChecked: e.target.checked });
  };
  changeEwayBill = (e: any) => {
    this.setState({ isEwayBillChecked: e.target.checked });
  };
  downloadPdfClose = () => {
    this.setState({ modalInfo: false });
    this.setState({
      isCustomerChecked: true,
      isTransportChecked: false,
      isSupplierChecked: false,
      isdeliverynoteChecked: false,
      isEwayBillChecked: false,
    });
  };
  downloadpdf = async () => {
    this.setState({ isProgress: 1 });
    try {
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/invoice_download_pdf",
        this.state
      );
      const downloadLink = document.createElement("a");
      downloadLink.href = resp.file;
      downloadLink.download = resp.filename;
      downloadLink.click();
      this.setState({
        isCustomerChecked: true,
        isTransportChecked: false,
        isSupplierChecked: false,
        isdeliverynoteChecked: false,
        isEwayBillChecked: false,
      });
      this.props.toggle();
      this.setState({ isProgress: 0 });
      // Handle response here
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  render() {
    const {
      isCustomerChecked,
      isTransportChecked,
      isSupplierChecked,
      isdeliverynoteChecked,
    } = this.state;

    const isButtonDisabled = !(
      isCustomerChecked ||
      isTransportChecked ||
      isSupplierChecked ||
      isdeliverynoteChecked
    );
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            {/* <ModalHeader
                            toggle={this.props.toggle}
                            style={{ borderBottom: "none" }}
                        >
                        </ModalHeader> */}
            <ModalBody style={{ paddingBottom: 0 }}>
              <div className="pt-0 pb-0">
                <div className="row pt-0 pb-0">
                  <span
                    className="px-2 pb-1"
                    style={{
                      fontSize: 14,
                      marginBottom: "-5px",
                    }}
                  >
                    Invoice Number {this.state.invoice_no}
                  </span>
                  <span
                    className="px-2 py-1"
                    style={{
                      fontSize: 14,
                      marginBottom: "-5px",
                    }}
                  >
                    {this.state.customer_name}
                  </span>
                  <span
                    style={{
                      fontSize: 12,
                    }}
                  >
                    Select the document and download in PDF
                  </span>
                </div>
                <Row
                  className="download-row"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Col lg="12" className="border-bottom border-top py-1">
                    <div className="form-check">
                      <input
                        className="form-check-input wide-checkbox me-2"
                        type="checkbox"
                        id="formCheck11"
                        checked={this.state.isCustomerChecked}
                        onChange={this.changeCustomer}
                      />
                      <label
                        className="form-check-label my-1"
                        htmlFor="formCheck11"
                      >
                        Invoice Original for Customer
                      </label>
                    </div>
                  </Col>
                  <Col lg="12" className="border-bottom py-1">
                    <div className="form-check">
                      <input
                        className="form-check-input wide-checkbox me-2"
                        type="checkbox"
                        id="formCheck12"
                        checked={this.state.isTransportChecked}
                        onChange={this.changeTransport}
                      />
                      <label
                        className="form-check-label my-1"
                        htmlFor="formCheck12"
                      >
                        Invoice Duplicate for Transport
                      </label>
                    </div>
                  </Col>
                  <Col lg="12" className="border-bottom py-1">
                    <div className="form-check">
                      <input
                        className="form-check-input wide-checkbox me-2"
                        type="checkbox"
                        id="formCheck13"
                        checked={this.state.isSupplierChecked}
                        onChange={this.changeSupplier}
                      />
                      <label
                        className="form-check-label my-1"
                        htmlFor="formCheck13"
                      >
                        Invoice Triplicate for Supplier
                      </label>
                    </div>
                  </Col>
                  <Col lg="12" className="border-bottom py-1">
                    <div className="form-check">
                      <input
                        className="form-check-input wide-checkbox me-2"
                        type="checkbox"
                        id="formCheck14"
                        checked={this.state.isdeliverynoteChecked}
                        onChange={this.changedeliverynote}
                      />
                      <label
                        className="form-check-label my-1"
                        htmlFor="formCheck14"
                      >
                        Delivery Note
                      </label>
                    </div>
                  </Col>
                  {this.state.is_ewaybilled == 1 ? (
                    <Col lg="12" className="border-bottom py-1">
                      <div className="form-check">
                        <input
                          className="form-check-input wide-checkbox me-2"
                          type="checkbox"
                          id="formCheck15"
                          checked={this.state.isEwayBillChecked}
                          onChange={this.changeEwayBill}
                        />
                        <label
                          className="form-check-label my-1"
                          htmlFor="formCheck15"
                        >
                          E-way Bill
                        </label>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </ModalBody>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              {/* <Button type="submit" color="primary" style={{ backgroundColor: "#00af50" }}>
                                GST Status
                            </Button> */}
              <>
                {this.state.isProgress ? (
                  <>
                    <Button
                      type="submit"
                      className="bmt-btn-submit w-50"
                      disabled={true}
                    >
                      Submitting{" "}
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="button"
                      className="bmt-btn-close inv-info"
                      onClick={() => {
                        this.downloadPdfClose();
                        this.props.toggle();
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      className="bmt-btn-submit inv-info"
                      onClick={() => this.downloadpdf()}
                      disabled={isButtonDisabled}
                    >
                      Download
                    </Button>
                  </>
                )}
              </>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(DownloadModal);
