import React from "react";
import { Row } from "reactstrap";

const lazyLoader = (importComp:any) => {
	return class extends React.Component {
		constructor(props:any) {
            super(props);
            this.state = {
                component: null //initializing state
            }
        }           

		//loading the component and setting it to state
		componentDidMount() {
			importComp().then((comp:any) => this.setState({ component: comp.default }));
		}

		//rendering the component
		render() {
			const C = this.state.component;
			return C ? (
				<C {...this.props} />
			) : (
				<div className="page-content">
                <Row>
                <div>Loading...</div>
                </Row>
            </div>
			);
           
			// If component is not loaded then return fallback component, if fallback is not provided then use default loading
		}
	};
};


export default lazyLoader;