import {PermissionsTypes} from "./actionTypes"

const INIT_STATE = {
  permissions: [],  
  error: {},
}

const Permissions = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case PermissionsTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.data,
      }
    case PermissionsTypes.GET_PERMISSIONS:
      return {
        ...state,
        permissions: state.permissions,
      }
    case PermissionsTypes.GET_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Permissions
