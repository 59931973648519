import React, { useCallback, useState } from "react";

import IncomeList from "./IncomeList";

const Incomes = () => {  

  return (
    <React.Fragment>          
      <IncomeList />              
    </React.Fragment>
  );
};

export default Incomes;
