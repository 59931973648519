import {TaxesTypes} from "./actionTypes"

const INIT_STATE = {
  taxes: [],  
  error: {},
}

const Taxes = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case TaxesTypes.GET_TAXES_SUCCESS:
      return {
        ...state,
        taxes: action.payload.data,
      }
    case TaxesTypes.GET_TAXES:
      return {
        ...state,
        taxes: state.taxes,
      }
    case TaxesTypes.GET_TAXES_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Taxes
