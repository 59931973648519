import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from "reactstrap";
import template1 from "../../assets/images/temp1.png";
import template2 from "../../assets/images/temp2.png";
import template3 from "../../assets/images/temp3.png";
interface Params extends RouteComponentProps {
    tempid: number;
    isOpen: boolean;
    toggle: () => void;
    switchTemplate: (id: any) => void;
}

type State = {
    modalInfo: boolean;
    templateId: number;
};

class TemplatesModal extends Component<Params, State>  {
    constructor(props: any) {
        super(props);

        this.state = {
            modalInfo: false,
            templateId: 0
        }
    }

    componentWillReceiveProps(props: Params) {
        this.setState({
            modalInfo: props.isOpen,
            templateId: props.tempid
        })
    }



    render() {
        let selected;
        if (this.state.templateId == 1) {
            selected = "selected";
        }
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.modalInfo}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    toggle={this.props.toggle}
                    size="lg"
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => this.props.toggle()}
                        >
                            Choose Templates
                        </ModalHeader>

                        <ModalBody style={{ paddingBottom: 0 }}>
                            <div className="chse_temp">
                                <ul>
                                    <li className={this.state.templateId == 1 ? "selected" : ""}><a href="#" onClick={() => this.props.switchTemplate(1)}>
                                        <div className="sucss">
                                            <div className="content">
                                                <i className="fas fa-check-circle"></i>
                                                <label>Selected Template</label>
                                            </div>
                                        </div>
                                        <img className="img-fluid" src={template1} alt="" />
                                    </a>
                                        <label>Template 1</label>
                                    </li>
                                    <li className={this.state.templateId == 2 ? "selected" : ""}><a href="#" onClick={() => this.props.switchTemplate(2)}>
                                        <div className="sucss">
                                            <div className="content">
                                                <i className="fas fa-check-circle"></i>
                                                <label>Selected Template</label>
                                            </div>
                                        </div>
                                        <img className="img-fluid" src={template2} alt="" />
                                    </a>
                                        <label>Template 2</label>
                                    </li>
                                    <li className={this.state.templateId == 3 ? "selected" : ""}><a href="#" onClick={() => this.props.switchTemplate(3)}>
                                        <div className="sucss">
                                            <div className="content">
                                                <i className="fas fa-check-circle"></i>
                                                <label>Selected Template</label>
                                            </div>
                                        </div>
                                        <img className="img-fluid" src={template3} alt="" />
                                    </a>
                                        <label>Template 3</label>
                                    </li>
                                </ul>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ textAlign: "center", display: "block" }}>

                        </ModalFooter>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(TemplatesModal);