import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { getAccessToken } from "src/helpers/api_helper";
import SuccessAlert from "src/components/SuccessAlert";

interface Props {
  closeModal: () => void;
}

const LogoModal = (props: Props) => {
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [errorMsgLogoSign, seterrorMsgLogoSign] = useState<any | null>(null);
  const [errorSubMsgLogoSign, seterrorSubMsgLogoSign] = useState<any | null>(
    null
  );

  const companies = useSelector((state: any) => state.companies.companies);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const handleValidSubmit = async (event: any, values: any) => {
    event.preventDefault();
    const token = getAccessToken();
    const formData = new FormData();
    const fileInput = event.target.logo;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      if (file && file.size > 150 * 1024) {
        seterrorMsgLogoSign("Logo upload failed");
        seterrorSubMsgLogoSign(
          "Your logo file size exceeds the 200KB upload limit. Please upload a smaller file."
        );
        event.target.value = "";
        return;
      }
      setIsModalProgress(true);
      formData.append("logo", fileInput.files[0]);
      formData.append("id", companies.id);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/save_logo_and_signature`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to upload logo");
        }
        const responseData = await response.json();
        console.log("Upload response:", responseData);
        props.closeModal();
      } catch (error) {
        console.error("Network error:", error);
      } finally {
        setIsModalProgress(false);
      }
    } else {
      setIsModalProgress(false);
    }
  };

  return (
    <React.Fragment>
      <AvForm
        onValidSubmit={handleValidSubmit}
        encType="multipart/form-data"
        id="company_form"
      >
        <div className="modal-body pt-1">
          <div className="row d-flex align-items-center">
            <div className="col-md-12">
              <div className="item_form pt-0 pb-0">
                <div className="row">
                  <div className="heading">Update your logo</div>
                </div>
                <Row>
                  <label
                    className="col-sm-4 col-form-label"
                    style={{ textAlign: "start", alignSelf: "center" }}
                  >
                    Logo
                  </label>
                  <Col sm={8}>
                    <div className="d-flex">
                      <div className="col-md-6">
                        <span className="pic-bg">
                          {logoPreview && (
                            <img
                              src={logoPreview || ""}
                              style={{
                                width: "200px",
                                maxHeight: "100px",
                                padding: "3px",
                                backgroundColor: "#f0f0f0",
                                paddingInlineEnd: "40px",
                                paddingInlineStart: "40px",
                                borderRadius: 5,
                              }}
                            />
                          )}
                        </span>
                      </div>
                      <div className="col-md-2 align-self-center">
                        <label className="bmt-btn-submit text-center itemsubmit">
                          Browse
                          <input
                            type="file"
                            name="logo"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="d-block text-center pt-2">
                  {isModalProgress ? (
                    <Button
                      type="button"
                      className="bmt-btn-submit itemsubmit"
                      disabled
                    >
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      Submitting
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="button"
                        className="bmt-btn-close itemsubmit"
                        onClick={() => props.closeModal()}
                      >
                        Skip
                      </Button>
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AvForm>
      <SuccessAlert
        isOpen={errorMsgLogoSign}
        message={errorMsgLogoSign}
        subMsg={errorSubMsgLogoSign}
        onClose={() => {
          seterrorMsgLogoSign(false);
          seterrorSubMsgLogoSign(false);
        }}
      />
    </React.Fragment>
  );
};

export default LogoModal;
