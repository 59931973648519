import {HsnCodeTypes} from "./actionTypes"

const INIT_STATE = {
  hsncode: [],  
  error: {},
}

const hsncode = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case HsnCodeTypes.GET_HSNCODE_SUCCESS:
      return {
        ...state,
        hsncode: action.payload.data,
      }
    case HsnCodeTypes.GET_HSNCODE:
      return {
        ...state,
        hsncode: state.hsncode,
      }
    case HsnCodeTypes.GET_HSNCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default hsncode
