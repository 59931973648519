import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import "../../assets/scss/custom/dashboard_style.scss";
import { Row, Col, Card } from "reactstrap";
import ExpenseIcon from "src/assets/images/icons/Expense";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import Estimate2Icon from "src/assets/images/icons/Estimate2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import gstBanner from "src/assets/images/banner.jpg";
import ReactApexChart from "react-apexcharts";
import { post } from "src/helpers/api_helper";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { number_format } from "src/helpers/common";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";

interface MyProps extends RouteComponentProps<any> {}

interface MyState {
  series: Array<any>;
  options: any;
  loading: boolean;
  totalIncome: number;
  totalExpense: number;
  profit_loss: number;
  startDate: Date;
  endDate: Date;
  selectedYear: string;
  modal_item_is_open: boolean;
  modal_cust_is_open: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

class DashboardContent extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: MyProps) {
    super(props);
    this._isMounted = false;
    this.state = {
      loading: true,
      totalIncome: 0,
      totalExpense: 0,
      profit_loss: 0,
      modal_item_is_open: false,
      modal_cust_is_open: false,
      startDate: new Date(new Date().getFullYear(), 3, 1),
      endDate: new Date(new Date().getFullYear() + 1, 2, 31),
      selectedYear: `${new Date().getFullYear()}-${
        new Date().getFullYear() + 1
      }`,
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#43aa77","#259efa"],
        xaxis: {
          categories: [
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
            "Feb",
            "Mar",
          ],
        },
      },
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/income_expense`,
          param
        );
        if (this._isMounted) {
          if (resp.success) {
            this.setState({
              series: [
                {
                  name: "Income",
                  data: resp.income,
                },
                {
                  name: "Expenses",
                  data: resp.expense,
                },
              ],
              totalExpense: resp.totalExpense,
              totalIncome: resp.totalIncome,
              profit_loss: resp.profit_loss,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = event.target.value;
    const startYear = parseInt(selectedYear.split("-")[0], 10);
    const startDate = new Date(startYear, 3, 1); // April 1st
    const endDate = new Date(startYear + 1, 2, 31); // March 31st of the next year
    this.setState({ selectedYear, startDate, endDate });
    this.fetchData({
      startDate: startDate,
      endDate: endDate,
    });
  };

  async componentDidMount() {
    try {
      this._isMounted = true;
      this.fetchData({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      });
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  render() {
    const { selectedYear } = this.state;
    const currentYear = new Date().getFullYear();
    const financialYears = Array.from({ length: 10 }, (_, i) => {
      const startYear = currentYear - i;
      const endYear = startYear + 1;
      return `${startYear}-${endYear}`;
    });

    return (
      <React.Fragment>
        <Card className="DashboardCard">
          <Row style={{ padding: "25px" }}>
            <Col md={6}>
              <div className="mb-5 text-start">
                <img src={gstBanner} alt="" className="w-75 h-50" />
              </div>
            </Col>
            <Col md={6}>
              <div className="">
                <ul className="qck-link1 d-flex justify-content-around">
                  <li>
                    <Link to="#" onClick={() => this.openModalItem()}>
                      <span>
                        <ExpenseIcon />
                      </span>
                      <label>Add Item</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => this.openCustModal()}>
                      <span>
                        <Addcustomer2Icon />
                      </span>
                      <label>Add Customer</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/estimation_add">
                      <span>
                        <Estimate2Icon />
                      </span>
                      <label>New Estimate</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/invoice_add">
                      <span>
                        <Invoice2Icon />
                      </span>
                      <label>New Invoice</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/proforma_add">
                      <span>
                        <Estimate2Icon />
                      </span>
                      <label>New Proforma</label>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Row className="d-flex border ms-1 p-3">
              <Col md={6}>
                <span className="m-2 font-size-18 display-6">Income And Expenses</span>
              </Col>
              <Col md={6}>
                {this.state.loading ? (
                  <Skeleton width={"100%"} height={"50%"} />
                ) : (
                  <div className="d-flex justify-content-end">
                    <select
                      className="font-size-14 form-select w-25"
                      value={selectedYear}
                      onChange={this.handleYearChange}
                    >
                      {financialYears.map(year => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <div className="border ms-2 p-2">
                    <span className="font-size-14 display-6">Start Date:{" "}
                      {moment(this.state.startDate).format("DD-MM-YYYY")} - End
                      Date: {moment(this.state.endDate).format("DD-MM-YYYY")}</span>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={12}>
                <div id="chart">
                  <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={350}
                  />
                </div>
                <div className="d-flex justify-content-center font-size-14 display-6">
                  <table>
                    <tr>
                      <td>Total Income</td>
                      <td className="px-1"> : </td>
                      <td className="text-end">
                        {" "}
                        {number_format(this.state.totalIncome)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Expense</td>
                      <td className="px-1"> : </td>
                      <td className="text-end">
                        {" "}
                        {number_format(this.state.totalExpense)}
                      </td>
                    </tr>
                    <tr>
                      <td>Profit/(Loss)</td>
                      <td className="px-1"> : </td>
                      <td className="text-end">
                        {" "}
                        {number_format(this.state.profit_loss)}
                      </td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
        {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        {this.state.modal_item_is_open == true && (
          <ProductCreateModalNew
            modal_pro_is_open={this.state.modal_item_is_open}
            openProductModal={this.openModalItem}
            closeProductModal={this.closeModalItem}
            parentCallback={this.parentCallbackItem}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(DashboardContent);
