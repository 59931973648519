import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import SalesCustomer from "./SalesCustomer";
import SalesItems from "./SalesItems";
import SalesGstr1Modal from "./SalesGstr1Modal";
import TDSPayable from "./TDSPayable";
import PurchaseItem from "./PurchaseItem";
import ProfitLoss from "./ProfitLoss";

function ReportMenu(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showCusModal, setShowCusModal] = useState(false);
  const [showItmModal, setShowItmModal] = useState(false);
  const [showPurItmModal, setShowPurItmModal] = useState(false);
  const [showSalesGst, setShowSalesGst] = useState(false);
  const [showProfitLossModal, setShowProfitLossModal] = useState(false);

  const [showTdsModal, setShowTdsModal] = useState(false);
  const [mouseTab, setmouseTab] = useState(false);
  const ledgerCusOpen = () => {
    setShowCusModal(true);
  };
  const ledgerItmOpen = () => {
    setShowItmModal(true);
  };
  const ledgerPurItmOpen = () => {
    setShowPurItmModal(true);
  };
  const ledgerSalesGst = () => {
    setShowSalesGst(true);
  };
  const ledgerTdsOpen = () => {
    setShowTdsModal(true);
  };
  const ledgerProfitLoss = () => {
    setShowProfitLossModal(true);
  };
  const { datas }: { datas: Array<any> } = useSelector((state: any) => ({
    datas: state.bankorcash.bankorcash,
  }));

  const handleMouseEnter = () => {
    setmouseTab(true);
  };
  const handleMouseLeave = () => {
    setmouseTab(false);
  };
  return (
    <>
      <Dropdown
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center p-1"
          id="page-header-user-dropdown"
          tag="button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="icon-block-01">
            <i
              className="dripicons-document-new mt-2 font-size-24"
              style={{ color: "#176955" }}
            ></i>
            {mouseTab && <div className="tooltip-header">Reports</div>}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="cash-dropDown">
            <h6 className="dropDown-header cash-list-item">Reports</h6>
            <ul className="cash-list-wrap">
              <div>
                <li className="cash-list-item">
                  <div className="d-flex">
                    <div className="bank-block">
                      <span className="d-block cash-title-bank">
                        SALES - Customer Wise
                      </span>
                    </div>
                    <div className="btn-set">
                      <button
                        className="btn btn-statement normal-btn hash-button"
                        onClick={() => ledgerCusOpen()}
                      >
                        Statement
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <div>
                <li className="cash-list-item">
                  <div className="d-flex">
                    <div className="bank-block">
                      <span className="d-block cash-title-bank">
                        SALES - Items Wise
                      </span>
                    </div>
                    <div className="btn-set">
                      <button
                        className="btn btn-statement normal-btn hash-button"
                        onClick={() => ledgerItmOpen()}
                      >
                        Statement
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <div>
                <li className="cash-list-item">
                  <div className="d-flex">
                    <div className="bank-block">
                      <span className="d-block cash-title-bank">
                        SALES - GSTR1
                      </span>
                    </div>
                    <div className="btn-set">
                      <button
                        className="btn btn-statement normal-btn hash-button"
                        onClick={() => ledgerSalesGst()}
                      >
                        Statement
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <div>
                <li className="cash-list-item">
                  <div className="d-flex">
                    <div className="bank-block">
                      <span className="d-block cash-title-bank">
                        Purchase - Items Wise
                      </span>
                    </div>
                    <div className="btn-set">
                      <button
                        className="btn btn-statement normal-btn hash-button"
                        onClick={() => ledgerPurItmOpen()}
                      >
                        Statement
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <div>
                <li className="cash-list-item">
                  <div className="d-flex">
                    <div className="bank-block">
                      <span className="d-block cash-title-bank">
                      TDS Payable
                      </span>
                    </div>
                    <div className="btn-set">
                      <button
                        className="btn btn-statement normal-btn hash-button"
                        onClick={() => ledgerTdsOpen()}
                      >
                        Statement
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <div>
                {/* <li className="cash-list-item">
                  <div className="d-flex">
                    <div className="bank-block">
                      <span className="d-block cash-title-bank">
                       Profit/Loss
                      </span>
                    </div>
                    <div className="btn-set">
                      <button
                        className="btn btn-statement normal-btn hash-button"
                        onClick={() => ledgerProfitLoss()}
                      >
                        Statement
                      </button>
                    </div>
                  </div>
                </li> */}
              </div>
            </ul>
          </div>
        </DropdownMenu>
      </Dropdown>
      {showCusModal ? (
        <SalesCustomer
          openModal={() => setShowCusModal(true)}
          modal_is_open={showCusModal}
          closeModal={() => setShowCusModal(false)}
        />
      ) : null}
      {showItmModal ? (
        <SalesItems
          openModal={() => setShowItmModal(true)}
          modal_is_open={showItmModal}
          closeModal={() => setShowItmModal(false)}
        />
      ) : null}
      {showPurItmModal ? (
        <PurchaseItem
          openModal={() => setShowPurItmModal(true)}
          modal_is_open={showPurItmModal}
          closeModal={() => setShowPurItmModal(false)}
        />
      ) : null}
      {showSalesGst ? (
        <SalesGstr1Modal
          openModal={() => setShowSalesGst(true)}
          modal_is_open={showSalesGst}
          closeModal={() => setShowSalesGst(false)}
        />
      ) : null}
      {showTdsModal ? (
        <TDSPayable
          openModal={() => setShowTdsModal(true)}
          modal_is_open={showTdsModal}
          closeModal={() => setShowTdsModal(false)}
        />
      ) : null}
      {showProfitLossModal ? (
        <ProfitLoss
          openModal={() => setShowProfitLossModal(true)}
          modal_is_open={showProfitLossModal}
          closeModal={() => setShowProfitLossModal(false)}
        />
      ) : null}
    </>
  );
}

export default React.memo(ReportMenu);
