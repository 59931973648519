import React, { Component, Dispatch } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button } from "reactstrap";
import "react-loading-skeleton/dist/skeleton.css";

interface Params extends RouteComponentProps {
  modal_is_open: boolean;
  openProductModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>;
  closeProductModal: Function;
}

class ExpenseLearnMore extends Component<Params> {
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal_is_open}
          toggle={this.props.openProductModal}
          className="md-700"
        >
          <ModalBody className="pb-0">
            <div className="row gst-km">
              <h3>Expense Bill</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </ModalBody>
          <ModalFooter style={{ textAlign: "center", display: "block" }}>
            <Button
              type="button"
              onClick={() => this.props.closeProductModal()}
              className="bmt-btn-close itemsubmit"
              data-dismiss="modal"
            >
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(ExpenseLearnMore);
