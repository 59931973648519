import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  Table,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

// Import Editor
import {number_format } from "src/helpers/common";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";

interface Params extends RouteComponentProps {
  invoice_id: string;
  customer_id: string;
  isOpen: boolean;
  toggle: () => void;
  invoice_amount: any;
  infoRes: any;
}

type State = {
  invoice_id: string;
  modalInfo: boolean;
  received_amount: number;
  tds: number;
  discount: number;
  exchange_loss_or_gain: number;
  balance_due: number;
  notes: string;
  success: number;
  total_amount_due: number;
  total_amount_received: number;
  payData: any;
  modal_is_open: boolean;
  customer_id: string;
};

class CompanyInfoModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      invoice_id: "",
      modalInfo: false,
      received_amount: 0,
      tds: 0,
      discount: 0,
      exchange_loss_or_gain: 0,
      balance_due: 0,
      notes: "",
      success: 0,
      total_amount_due: 0,
      total_amount_received: 0,
      payData: [],
      modal_is_open: false,
      customer_id: "",
    };
  }

  componentWillReceiveProps(props: Params) {
    this.setState({
      modalInfo: props.isOpen,
      customer_id: props.customer_id,
    });
  }

  componentDidUpdate(prevProps: Params) {
    if (this.props.invoice_id !== prevProps.invoice_id) {
    }
  }

  calcTotal = async () => {
    let total_amount_due: number = this.state.total_amount_due;
    let deduction: number =
      this.state.received_amount +
      this.state.tds +
      this.state.discount +
      this.state.exchange_loss_or_gain;
    var balance_due: number = 0;
    balance_due =
      total_amount_due + this.state.total_amount_received - deduction;
    var balance: any = [];
    balance["balance_due"] = balance_due;
  };

  openModal = () => {
    this.setState({ modal_is_open: true });
  };

  closeModal = () => {
    this.setState({ modal_is_open: false });
  };

  handleRefresh = () => {
    console.log("handleRefresh all");
  };

  recordsDetails = (arr: any) => {
    if (arr.details.length > 0) {
      let headName = "";
      let amount = "";
      arr.details.map(
        (detail: {
          head: string;
          amount: number;
          bank_cashbook_name: string;
          heads_id: number;
        }) => {
          headName += `<div>${detail.head}</div>`;
          amount += `<div>${detail.amount}</div>`;
        }
      );
      return (
        <>
          <td>
            {arr.bank_cashbook_name ? arr.bank_cashbook_name : arr.head}
            <div dangerouslySetInnerHTML={{ __html: headName }} />
          </td>
          <td style={{ textAlign: "right" }}>
            <div>{arr.amount}</div>
            <div dangerouslySetInnerHTML={{ __html: amount }} />
          </td>
        </>
      );
    } else {
      return (
        <>
          <td>{arr.bank_cashbook_name ? arr.bank_cashbook_name : arr.head}</td>
          <td style={{ textAlign: "right" }}>{arr.amount}</td>
        </>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
          <ModalBody style={{ paddingBottom: 0 }}>
              <div className="green_form pt-0 pb-0">
                <h5
                  className="row"
                  style={{
                    fontSize: 16,
                    textTransform: "uppercase",
                    justifyContent: "center",
                    marginBottom: "-5px",
                  }}
                >
                  Payment Received
                </h5>
                <div className="row">
                  <label className="col-sm-4 col-form-label w-50">Date </label>
                  <div
                    className="col-sm-8 w-50 ps-1"
                    style={{
                      padding: "inherit",
                      border: "ridge 1px",
                      font: "caption",
                    }}
                  >
                    {" "}
                    {moment(this.props.invoice_amount.date).format(
                            "DD/MM/YYYY"
                          )}
                  </div>
                </div>
                <div className="row ">
                  <label className="col-sm-4 col-form-label w-50">Payment Type </label>
                  <div
                    className="col-sm-8 w-50 ps-1"
                    style={{
                      padding: "inherit",
                      border: "ridge 1px",
                      font: "caption",
                    }}
                  >
                    {" "}
                    {this.props.invoice_amount.mode_name}
                  </div>
                </div>
                <div className="row ">
                  <label className="col-sm-4 col-form-label w-50">Amount </label>
                  <div
                    className="col-sm-8 w-50 ps-1"
                    style={{
                      padding: "inherit",
                      border: "ridge 1px",
                      font: "caption",
                    }}
                  >
                    {" "}
                    {number_format(this.props.invoice_amount.amount)}
                  </div>
                </div>
                
              </div>
            </ModalBody>

            {/* <ModalBody
              className="pt-2 payment-history-modal"
              style={{ paddingBottom: 0 }}
            >
              <div className="row">
                <div className="heading">Payment Received</div>
                <div className="table-responsive">
                  <Table className="align-middle mb-0 table-payment-history">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Payment Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={this.props.invoice_amount.id}>
                        <td>
                          {moment(this.props.invoice_amount.date).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{this.props.invoice_amount.mode_name}</td>
                        <td>{this.props.invoice_amount.amount}</td>
                      </tr>
                      {this.props.invoice_amount.info &&
                        this.props.invoice_amount.info.map(
                          (item: any, index: any) => (
                            <tr key={item.id}>
                              <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                              <td>{item.mode_name}</td>
                              <td>{item.amount}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </ModalBody> */}
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                type="button"
                className="bmt-btn-close"
                onClick={() => this.props.toggle()}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(CompanyInfoModal);
