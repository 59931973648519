import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Modal, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

interface Params extends RouteComponentProps {
  terms_modal_open: boolean;
  terms_openModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  terms_closeModal: () => void;
  parentCallback: (values: any) => void;
  prop_termsAndConditions?: string;
  title?: string;
}

type State = {
  termsAndConditions: string;
  title: string;
};

class TermsAndConditionsModal extends Component<Params, State> {
  constructor(props: Params) {
    super(props);
    this.state = {
      termsAndConditions: "",
      title:"invoivce",
    };
  }

  componentDidUpdate(prevProps: Params) {
    if (prevProps.terms_modal_open !== this.props.terms_modal_open) {
      this.setState({
        termsAndConditions: this.props.prop_termsAndConditions || "",
        title: this.props.title || "invoivce",
      });
    }
    
  }
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  handleValidSubmit = async (values: any) => {
    values["termsAndConditions"] = this.state.termsAndConditions;
    this.props.parentCallback(values);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.terms_modal_open}
        toggle={this.props.terms_openModal}
        className="md-400"
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-body pt-1">
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="inv_form pt-0 pb-0">
                  <div className="row">
                    <div className="heading">Terms and Conditions</div>
                    <span>This will be displayed on the {this.state.title}</span>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <AvField
                        name="termsAndConditions"
                        type="textarea"
                        rows="10"
                        placeholder="Terms and Conditions"
                        value={this.state.termsAndConditions}
                        onChange={this.onChangehandler}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    <>
                      <Button
                        type="button"
                        className="bmt-btn-close itemsubmit"
                        onClick={this.props.terms_closeModal}
                      >
                        Close
                      </Button>{" "}
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                      >
                        Save
                      </Button>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    );
  }
}

export default withRouter(TermsAndConditionsModal);
