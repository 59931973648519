import {SacCodeTypes} from "./actionTypes"

const INIT_STATE = {
  saccode: [],  
  error: {},
}

const saccode = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case SacCodeTypes.GET_SACCODE_SUCCESS:
      return {
        ...state,
        saccode: action.payload.data,
      }
    case SacCodeTypes.GET_SACCODE:
      return {
        ...state,
        saccode: state.saccode,
      }
    case SacCodeTypes.GET_SACCODE_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default saccode
