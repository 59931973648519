import {TdsHeadTypes} from "./actionTypes"

export const getTdsHead = () => ({
  type: TdsHeadTypes.GET_TDSHEAD,
})

export const getTdsHeadSuccess = (taxes : any) => ({
  type: TdsHeadTypes.GET_TDSHEAD_SUCCESS,
  payload: taxes,
})

export const getTdsHeadFail = (error : any) => ({
  type: TdsHeadTypes.GET_TDSHEAD_FAIL,
  payload: error,
})



