import React, { useCallback, useState } from "react";

import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import TDSDeductedList from "./TDSDeductedList";

const TDSDeducted = () => { 

  return (
    <React.Fragment>      
          
          <TDSDeductedList />          
            
    </React.Fragment>
  );
};

export default TDSDeducted;