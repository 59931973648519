import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PdfIcon from "src/assets/images/icons/Pdf";
import { post } from "src/helpers/api_helper";
import { number_format, predefinedRanges } from "src/helpers/common";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";

interface Props {
  modal_is_open: boolean;
  openModal?:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
}

function ProfitLoss(props: Props) {
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [isProgress, setIsProgress] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()));
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [Incomedata, setIncomeData] = useState<Array<any>>([]);
  const [Expensedata, setExpenseData] = useState<Array<any>>([]);
  const [tdsheads, setTdsheads] = useState<Array<any>>([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const closeThis = () => {
    setStartDate(null);
    setEndDate(null);
    setDateRange([startOfMonth(new Date()), endOfMonth(new Date())]);
    setIncomeData([]);
    setTdsheads([]);
    setIsSubmit(false);
    props.closeModal();
  };

  const setStartDateNew = (dateRange: any) => {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    setDateRange(dateRange);
    setStartDate(startDate ? new Date(startDate) : null);
    setEndDate(endDate ? new Date(endDate) : null);
  };

  const submit = async (startD: Date | null, endD: Date | null) => {
    setIsProgress(true);
    search(startD, endD);
  };
  const search = async (startD: Date | null, endD: Date | null) => {
    try {
      let start_date = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      let postValues = {
        startDate: start_date,
        endDate: end_date,
      };
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/profit_loss_statement`,
        postValues
      );
      if (resp.success) {
        setIsProgress(false);
        setIncomeData(resp.income_data);
        setExpenseData(resp.expense_data);
        setTdsheads(resp.tdsheads);
        setIsSubmit(true);
      }
    } catch (err) {
      setIsProgress(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.modal_is_open) {
      let startD = startOfMonth(new Date());
      let endD = endOfMonth(new Date());
      setStartDate(startD);
      setEndDate(endD);
      search(startD, endD);
    }
  }, [props.modal_is_open]);

  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });
  const totalIncome = Incomedata.reduce(
    (acc, item) => acc + parseFloat(item.amount),
    0
  );
  const totalExpense = Expensedata.reduce(
    (acc, item) => acc + parseFloat(item.amount),
    0
  );

  const profitOrLoss = totalIncome - totalExpense;
  return (
    <Modal
      isOpen={props.modal_is_open}
      toggle={props.openModal}
      className={
        "md-1000 ledger-modal modal-dialog-centered modal-dialog-scrollable h-100 my-0"
      }
    >
      <div className="modal-header d-block ledger-header border-0 justify-content-center pb-0">
        <Row className="pb-2">
          <Col lg={12}>
            <h5 className="modal-title text-center mt-0 fw-normal">
              Profit and Loss
            </h5>
          </Col>
          <Col lg={4} className="offset-lg-3 pe-0">
            <DateRangePicker
              ranges={predefinedRanges}
              format="dd/MM/yyyy"
              placeholder=" "
              value={dateRange}
              style={{ width: 330 }}
              onChange={(date: DateRange | null) => setStartDateNew(date)}
            />
          </Col>
          <Col lg={4}>
            {isProgress ? (
              <>
                <Button
                  type="submit"
                  className="bmt-btn-submit-1"
                  disabled={true}
                >
                  Submitting
                  <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  style={{ padding: "5px 25px" }}
                  className="bmt-btn-submit me-1"
                  onClick={() => submit(startDate, endDate)}
                >
                  Submit
                </Button>
              </>
            )}
          </Col>
        </Row>
        <button
          type="button"
          onClick={() => closeThis()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeButton"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <div className="mb-3">
          <Row>
            <Col xs="12">
              <div>
                <div className="form-inline">
                  {isSubmit && (
                    <Row>
                      <Col lg={12}>
                        <h5 className="mt-3 fw-normal text-primary">Income</h5>
                        <Table
                          className="align-middle mb-0 ledger-table mt-1"
                          style={{
                            width: "100%",
                          }}
                        >
                          <tbody>
                            {Incomedata.map((statement, index) => {
                              return (
                                <tr key={index}>
                                  <td>{statement.name}</td>
                                  <td style={{ textAlign: "right" }}>
                                    {number_format(statement.amount)}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td>
                                <b>Total Income</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(totalIncome)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col lg={12}>
                        <h5 className="mt-3 fw-normal text-primary">
                          Expenses
                        </h5>
                        <Table
                          className="align-middle mb-0 ledger-table mt-1"
                          style={{
                            width: "100%",
                          }}
                        >
                          <tbody>
                            {Expensedata.map((statement, index) => {
                              return (
                                <tr key={index}>
                                  <td>{statement.name}</td>

                                  <td style={{ textAlign: "right" }}>
                                    {number_format(statement.amount)}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td>
                                <b>Total Expense</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(totalExpense)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col lg={12}>
                        <Table
                          className="align-middle mb-0 ledger-table mt-1"
                          style={{
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <span className="text-primary h5 fw-normal">Profit</span>
                                <span className="h5 fw-normal">/</span>
                                <span className="text-danger h5 fw-normal">Loss</span>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {profitOrLoss < 0 ? (
                                  <b className="text-danger">
                                    {number_format(Math.abs(profitOrLoss))}
                                  </b>
                                ) : (
                                  <b className="text-primary">
                                    {number_format(Math.abs(profitOrLoss))}
                                  </b>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(ProfitLoss);
