import { call, put, takeEvery } from "redux-saga/effects"

import {
  getHsnCodeSuccess,
  getHsnCodeFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { HsnCodeTypes } from "./actionTypes"

export const getHsnCode = () => get(process.env.REACT_APP_API_URL + '/api/all_hsn_code')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchHsncode() {
  try {
    const response : apiRes = yield call(getHsnCode);  
    if (response.success) {
      yield put(getHsnCodeSuccess(response))
    } 
  } catch (error) {
    yield put(getHsnCodeFail(error))
  }
}


function* hsncodeSaga() {  
  yield takeEvery(HsnCodeTypes.GET_HSNCODE, fetchHsncode);
}

export {fetchHsncode}
export default hsncodeSaga
