import {PaymentMethodsTypes} from "./actionTypes"

const INIT_STATE = {
  payment_methods: [],  
  error: {},
}

const PaymentMethods = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case PaymentMethodsTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        payment_methods: action.payload.data.results,
      }
    case PaymentMethodsTypes.GET_PAYMENT_METHODS:
      return {
        ...state,
        payment_methods: state.payment_methods,
      }
    case PaymentMethodsTypes.GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default PaymentMethods
