import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Modal } from "reactstrap";
import { connect } from "react-redux";

interface Params extends RouteComponentProps {
  isOpen: boolean;
  value: string;
  toggle: () => void;
  parentCallback: (values: string) => void;
  saccode: Array<any>;
}

type State = {
  isOpen: boolean;
  value: string;
  sacCodes: Array<{ SAC_Code: string; SAC_description: string }>;
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
};

class SACModal extends Component<Params, State> {
  filterTimeout: ReturnType<typeof setTimeout> | null = null;
  constructor(props: Params) {
    super(props);
    this.state = {
      isOpen: false,
      value: "",
      sacCodes: [],
      currentPage: 1,
      totalPages: 1,
      isLoading: false,
    };
  }
  componentWillReceiveProps(nextProps: Params) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
      if (nextProps.value) {
        this.setState({ value: this.props.value });
        this.fetchSACCodes(this.props.value, 1);
      }
    }
  }
  fetchSACCodes = async (
    search: string = "",
    page: number = 1,
    limit: number = 10
  ) => {
    if (this.state.value !== "") {
      this.setState({ isLoading: page === 1 ? true : false });
      try {
        const filteredSACCodes = this.props.saccode.filter(
          sac =>
            sac.SAC_description.toLowerCase().includes(search.toLowerCase()) ||
            sac.SAC_Code.toLowerCase().includes(search.toLowerCase())
        );
        const startIndex = (page - 1) * limit;
        const paginatedHsnCodes = filteredSACCodes.slice(
          startIndex,
          startIndex + limit
        );
        this.setState(prevState => ({
          sacCodes:
            page === 1
              ? paginatedHsnCodes
              : [...prevState.sacCodes, ...paginatedHsnCodes],
          currentPage: page,
          totalPages: Math.ceil(filteredSACCodes.length / limit),
          isLoading: false,
        }));
      } catch (error) {
        console.error("Failed to fetch SAC codes:", error);
        this.setState({ isLoading: page === 1 ? false : false });
      }
    } else {
      this.setState({ sacCodes: [] });
    }
  };

  handleSACClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const SACCode = event.currentTarget.getAttribute("data-id");
    if (SACCode) {
      this.props.parentCallback(SACCode);
      this.props.toggle();
    }
  };
  handleChange = (e: any) => {
    const value = e.target.value;
    this.setState({ value });
    this.setState({ isLoading: true});
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      this.fetchSACCodes(value, 1);
    }, 500);
  };

  loadMoreSACCodes = () => {
    const { value, currentPage, totalPages } = this.state;
    if (currentPage < totalPages) {
      this.fetchSACCodes(value, currentPage + 1);
    }
  };

  render() {
    const { sacCodes, value, isLoading, currentPage, totalPages } = this.state;
    const highlightText = (description: string, searchValue: string) => {
      if (!searchValue) return description;
      const regex = new RegExp(`(${searchValue})`, "gi");
      const highlighted = description.replace(
        regex,
        match => `<span class="highlight">${match}</span>`
      );
      return highlighted;
    };
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isOpen}
          role="dialog"
          className="modal-dialog-scrollable md-650"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="item_form border-none-sm">
                <div className="heading">Search SAC</div>
                <input
                  type="text"
                  className="form-control"
                  value={value}
                  onChange={this.handleChange}
                  placeholder="Search by SAC code or description"
                />
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th>SAC</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value == "" ? (
                        <tr>
                          <td colSpan={2} className="text-center">
                            Find SAC Code
                          </td>
                        </tr>
                      ) : isLoading ? (
                        <tr>
                          <td colSpan={2} className="text-center">
                            Loading.....
                          </td>
                        </tr>
                      ) : sacCodes && sacCodes.length > 0 ? (
                        sacCodes.map(sac => (
                          <tr key={sac.SAC_Code}>
                            <td className="w-25">
                              <span
                                className="cursor-pointer text-info"
                                data-id={sac.SAC_Code}
                                onClick={this.handleSACClick}
                              >
                                {sac.SAC_Code}
                              </span>
                            </td>
                            <td
                              className="w-75"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(
                                  sac.SAC_description,
                                  value
                                ),
                              }}
                            />
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className="text-center">
                            No SAC codes found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {value !== "" && currentPage < totalPages && (
                  <div className="mt-2 text-center">
                    <span
                      className="bmt-btn-submit create btn-sm"
                      onClick={this.loadMoreSACCodes}
                    >
                      Load More ....
                    </span>
                  </div>
                )}
                <div className="d-flex custom-modal-footer">
                  <div className="text-center w-100 custom-text-center">
                    <Button
                      type="button"
                      className="btn-sm bmt-btn-close inv-info"
                      onClick={this.props.toggle}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: { saccode: any }) => {
  return {
    saccode: state.saccode.saccode,
  };
};

export default connect(mapStateToProps)(withRouter(SACModal));
