import React, { Component, Dispatch } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  Table,
  ModalFooter,
  Card,
  CardBody,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { post, get, del } from "src/helpers/api_helper";
import { number_format } from "src/helpers/common";
import moment from "moment";
import RecordPurchasePaymentEditModal from "./RecordPurchasePaymentEditModal";
import { connect } from "react-redux";
import { setbankorcashSuccess } from "src/store/actions";
import ConfirmationModal from "src/components/ConfirmationModal";

interface Params extends RouteComponentProps {
  invoice_id: string;
  isOpen: boolean;
  toggle: () => void;
  invoice_amount: number;
  handleRefresh: () => void;
  setCashOrBank: () => void;
}

type State = {
  invoice_id: string;
  modalInfo: boolean;
  payments: Array<any>;
  received_amount: number;
  tds: number;
  discount: number;
  exchange_loss_or_gain: number;
  balance_due: number;
  notes: string;
  success: number;
  total_amount_due: number;
  total_amount_received: number;
  payData: any;
  modal_is_open: boolean;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
};

class RecordPurchasePaymentHistory extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      invoice_id: "",
      modalInfo: false,
      payments: [],
      received_amount: 0,
      tds: 0,
      discount: 0,
      exchange_loss_or_gain: 0,
      balance_due: 0,
      notes: "",
      success: 0,
      total_amount_due: 0,
      total_amount_received: 0,
      payData: [],
      modal_is_open: false,
      isOpenConformModal: false,
      Rowdelete: null,
    };
  }

  componentWillReceiveProps(props: Params) {
    this.setState({
      modalInfo: props.isOpen,
    });
  }

  componentDidUpdate(prevProps: Params) {
    if (
      this.props.invoice_id !== prevProps.invoice_id ||
      this.props.isOpen !== prevProps.isOpen
    ) {
      if (this.props.isOpen == true) {
        this.fetchList(this.props.invoice_id);
      }
    }
  }

  fetchList = async (invoice_id: any) => {
    const respPaymentLst: any = await get(
      process.env.REACT_APP_API_URL + `/api/expense_bill_list/${invoice_id}`
    );
    let payments: Array<any> = respPaymentLst.data;
    const total_amount_received: number = respPaymentLst.received_amount;
    let total_amount_due: number =
      this.props.invoice_amount - total_amount_received;
    if (Math.abs(total_amount_due) < 0.01) {
      total_amount_due = 0.0;
    }
    this.setState({
      payments: payments,
      total_amount_received: total_amount_received,
      total_amount_due: total_amount_due,
    });
  };
  delete = (id: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: id });
  };
  handleDeleteConfirm = async () => {
    try {
      const param = { id: this.state.Rowdelete };
      const resp: any = await post(
        process.env.REACT_APP_API_URL + "/api/expense_payment_delete",
        param
      );
      if (resp.success) {
        this.fetchList(this.props.invoice_id);
        this.props.setCashOrBank();
        this.props.handleRefresh();
      }
    } catch (err) {
      console.error(err);
    }
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  edit = async (event: any, id: any) => {
    event.stopPropagation();
    this.openModal();
    try {
      const resp: any = await get(
        process.env.REACT_APP_API_URL + "/api/expense_bill_get_payment/" + id
      );
      console.log(resp);

      if (resp.success) {
        let pdata = resp.data;
        pdata["total_amount_due"] = this.state.total_amount_due;
        pdata["total_amount_received"] = this.state.total_amount_received;
        pdata["edit"] = 1;
        this.setState({ payData: pdata });
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  calcTotal = async () => {
    let total_amount_due: number = this.state.total_amount_due;
    let deduction: number =
      this.state.received_amount +
      this.state.tds +
      this.state.discount +
      this.state.exchange_loss_or_gain;
    var balance_due: number = 0;
    balance_due =
      total_amount_due + this.state.total_amount_received - deduction;
    var balance: any = [];
    balance["balance_due"] = balance_due;
  };

  openModal = () => {
    this.setState({ modal_is_open: true });
  };

  closeModal = () => {
    this.setState({
      modal_is_open: false,
      payData: [],
    });
  };

  handleRefresh = () => {
    this.fetchList(this.props.invoice_id);
  };

  recordsDetails = (arr: any) => {
    if (arr.details.length > 0) {
      let headName = "";
      let amount = "";
      arr.details.map(
        (detail: {
          head: string;
          amount: number;
          bank_cashbook_name: string;
          heads_id: number;
        }) => {
          // console.log(detail.head);
          headName += `<div>${detail.head}</div>`;
          amount += `<div>${detail.amount}</div>`;
        }
      );
      return (
        <>
          <td>
            {arr.bank_cashbook_name ? arr.bank_cashbook_name : arr.head}
            <div dangerouslySetInnerHTML={{ __html: headName }} />
          </td>
          <td style={{ textAlign: "right" }}>
            <div>{number_format(arr.amount)}</div>
            <div dangerouslySetInnerHTML={{ __html: amount }} />
          </td>
        </>
      );
    } else {
      return (
        <>
          <td>{arr.bank_cashbook_name ? arr.bank_cashbook_name : arr.head}</td>
          <td style={{ textAlign: "right" }}>{number_format(arr.amount)}</td>
        </>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal md-650"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            <div
              className="modal-body p-0 payment-history-modal"
            >
                <div className="border-0">
                  <CardBody className="p-0">
                    <div className="header-info">
                    <div className="row d-flex">
                      <div className="row">
                        <div className="col-md-3">
                          <p className="font-size-14 m-0">Bill Amount :</p>
                        </div>
                        <div className="col-md-3">
                          {number_format(this.props.invoice_amount)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <p className="font-size-14 m-0">Amount Paid :</p>
                        </div>
                        <div className="col-md-3">
                          {number_format(this.state.total_amount_received)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <p className="font-size-14 m-0">Pending Amount :</p>
                        </div>
                        <div className="col-md-3">
                          {this.state.total_amount_due == 0
                            ? "-"
                            : number_format(this.state.total_amount_due)}
                        </div>
                      </div>
                    </div>
                    </div>
                  </CardBody>
                </div>
                <div className="mx-2">
                <div className="heading">Payment Received</div>
                <div className="table-responsive">
                  <Table className="align-middle mb-0 table-payment-history">
                    <thead>
                      <tr>
                        <th style={{ width: "90px" }}>Date</th>
                        <th>Payment Type</th>
                        <th style={{ textAlign: "center", width: "120px" }}>
                          Amount
                        </th>
                        <th style={{ textAlign: "center", width: "192px" }}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.payments.map(
                        (payment: {
                          id: string;
                          payment_date: string;
                          head: string;
                          amount: number;
                          bank_cashbook_name: string;
                          heads_id: number;
                          details: Array<any>;
                        }) => {
                          return (
                            <tr key={payment.id}>
                              <td>
                                {moment(payment.payment_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              {this.recordsDetails(payment)}
                              <td
                                className="text-nowrap"
                                style={{ textAlign: "center" }}
                              >
                                <div className="table-icons d-flex justify-content-end">
                                  {payment.heads_id == 1 ||
                                  payment.heads_id == 2 ||
                                  payment.heads_id == 0 ? (
                                    <span>
                                      <button
                                        className="brown-btn btn-record-his"
                                        title="Edit"
                                        style={{ background: "#fce4d7" }}
                                        onClick={(e: any) => {
                                          this.edit(e, payment.id);
                                        }}
                                      >
                                        Edit
                                      </button>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "70px",
                                      }}
                                    ></span>
                                  )}
                                  <span className="ms-2 me-2">
                                    <button
                                      className="brown-btn btn-record-his"
                                      title="Delete"
                                      style={{ background: "#bbd8ef" }}
                                      onClick={() => {
                                        this.delete(payment.id);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
                </div>
              </div>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                type="button"
                className="bmt-btn-close"
                onClick={() => this.props.toggle()}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        />
        <RecordPurchasePaymentEditModal
          modal_is_open={this.state.modal_is_open}
          openModal={this.openModal}
          closeModal={this.closeModal}
          data={this.state.payData}
          invoice_id={this.props.invoice_id}
          invoice_amount={this.props.invoice_amount}
          handleRefresh={this.handleRefresh}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setCashOrBank: () => {
      setbankorcashSuccess(dispatch);
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(RecordPurchasePaymentHistory));
