import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SweetAlert from "react-bootstrap-sweetalert";
import ExpCategoryModal from "./ExpCategoryModal";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import ConfirmationModal from "src/components/ConfirmationModal";
import classnames from "classnames";

interface MyProps extends RouteComponentProps<any> {}

interface MyState {
  sizePerPage: number;
  totalSize: number;
  activeTab: number;
  data: any;
  sortField: string;
  sortOrder: string;
  permissions: Array<any>;
  loading: boolean;
  error_dlg: boolean;
  dynamic_title: string;
  dynamic_description: string;
  modalExpCategory: boolean;
  expCatId: string;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

class ExpCategoryList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      activeTab: 1,
      totalSize: 0,
      data: [],
      sortField: "id",
      sortOrder: "asc",
      loading: true,
      permissions: [],
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      modalExpCategory: false,
      expCatId: "0",
      isOpenConformModal: false,
      Rowdelete: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  handleEdit = (row: any) => {
    this.setState({ expCatId: row.id, modalExpCategory: true });
  };
  openModalExpCategory = () => {
    this.setState({ modalExpCategory: true, expCatId: "0" });
  };

  closeModalExpCategory = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
    this.setState({ modalExpCategory: false });
  };
  showactiveTab = (activeTab: any) => {
    this.fetchData({
      page: 1,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: activeTab,
    });
  }
  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/exp_category_list_grid`,
          param
        );

        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              data: resp.data.results,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  sendGetRequest = async () => {
      try {
      if (this._isMounted) this.setState({ loading: false });
        const respPer = await get(
          process.env.REACT_APP_API_URL + `/api/permissions/7`
        );
        if (respPer.success) {
          if (this._isMounted) {
            this.setState({
              permissions: respPer.data,
            });
          }
          this.fetchData({
            page: 1,
            searchText: "",
            sizePerPage: this.state.sizePerPage,
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder,
            activeTab: this.state.activeTab,
          });
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
  componentDidMount() {
    this._isMounted = true;
    this.sendGetRequest();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.fetchData({
      page: param.page,
      searchText: "",
      sizePerPage: param.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
  };
  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        const resp: any = await del(
          process.env.REACT_APP_API_URL +
            "/api/exp_category_delete/" +
            this.state.Rowdelete
        );
        if (resp.success == true) {
          this.setState({
            error_dlg: false,
            dynamic_title: "",
            dynamic_description: "",
          });
        } else {
          this.setState({
            error_dlg: true,
            dynamic_title: "Deletion Failed",
            dynamic_description: resp.message,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };

  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "category_name",
        text: "Category Name",
        sort: true,
      },
      {
        dataField: "parent_name",
        text: "Parent Category",
        sort: true,
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <div className="table-button">
              <button
                className="round-btn"
                title="Action"
                style={{
                  marginRight: 12,
                  width: 35,
                  height: 35,
                }}
              >
                <UncontrolledDropdown className="mt-4 mt-sm-0">
                  <DropdownToggle tag="a">
                    <EllipsisIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.permissions.find(
                      ({ action, is_active }) =>
                        action == "modify" && is_active == 1
                    ) && (
                      <Link to="#" onClick={() => this.handleEdit(row)}>
                        <DropdownItem to="#" tag="span">
                          <i
                            className="fas fa-edit"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit"
                            style={{ color: "#28a745" }}
                          />{" "}
                          Edit
                        </DropdownItem>
                      </Link>
                    )}
                    {row.is_system == 0 && (
                      <>
                        {this.state.permissions.find(
                          ({ action, is_active }) =>
                            action == "delete" && is_active == 1
                        ) && (
                          <DropdownItem
                            to="#"
                            tag="span"
                            onClick={() => this.handleDelete(row)}
                          >
                            <i
                              className="fas fa-trash-alt"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete"
                              style={{ color: "#dc3545" }}
                            />{" "}
                            Delete
                          </DropdownItem>
                        )}
                      </>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={this.state.data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <div
                        className="search-box me-2 mb-2 d-inline-block custm-search"
                        style={{
                          left: 120,
                        }}
                      >
                        <SearchBar {...toolkitProps.searchProps} />
                        {/* <i className="bx bx-search-alt search-icon" /> */}
                      </div>
                      {this.state.permissions.find(
                        ({ action, is_active }) =>
                          action == "create" && is_active == 1
                      ) && (
                        <Link
                          to="#"
                          onClick={this.openModalExpCategory}
                          className="custm-search"
                          style={{
                            left: 353,
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary create"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-plus"></i> Create
                          </button>
                        </Link>
                      )}
                    </div>
                    <Row className="mt-3">
                      <Col xl={2} lg={3}>
                        <Nav
                          className="custom-tab"
                          style={{
                            minHeight: "400px",
                          }}
                        >
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 1 });
                                this.showactiveTab(1);
                            }}
                            >
                              Assets
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 2 });
                                this.showactiveTab(2);
                            }}
                            >
                              Liabilities
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 3,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 3 });
                                this.showactiveTab(3);
                            }}
                            >
                              Expense
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 4,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 4 });
                                this.showactiveTab(4);
                            }}
                            >
                              Income
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col xl={10} lg={9}>
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            remote
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap tbl-invoice"
                            }
                            headerWrapperClasses={"thead-light"}
                            onTableChange={this.handleTableChange}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        {this.state.error_dlg ? (
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => {
              this.setState({ error_dlg: false });
            }}
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}
        {this.state.modalExpCategory && (
          <ExpCategoryModal
            modal_is_open={this.state.modalExpCategory}
            openModal={this.openModalExpCategory}
            closeModal={this.closeModalExpCategory}
            id={this.state.expCatId}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ExpCategoryList);
