import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, post, get } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { number_format } from "src/helpers/common";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import RecordPaymentModal from "./RecordPaymentModal";
import RecordPurchasePaymentHistory from "./RecordPurchasePaymentHistory";
import DeleteModal from "src/components/DeleteModal";
import ExpenseBillDetailsModel from "./ExpenseBillDetailsModel";
import { connect } from "react-redux";
import { isNull } from "lodash";
import PurAlertModal from "../Purchase/PurAlertModal";

interface MyProps extends RouteComponentProps<any> {
  valueVendor?: Option | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
  setTotalAmount: ({}) => void;
  countries: Array<any>;
  states: Array<any>;
}

interface MyState {
  sizePerPage: number;
  page: number;
  totalSize: number;
  data: any;
  payData: any;
  sortField: string;
  sortOrder: string;
  totalAmount: number;
  modal_is_open: boolean;
  isOpenPayModal: boolean;
  active_inv_id: string;
  active_inv_number: number;
  active_invoice_amount: number;
  active_cust_name: string;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
  alertCantDelClose: boolean;
  active_vendor_name: string;
  modalView: boolean;
  billing_address: string;
  active_invoice_date: Date | null;
  active_invoice_no: string;
  phone: string;
  gstin: string;
  pan: string;
  email: string;
  active_ven_id: string;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
  sortOrder: string;
  sortField: string;
}

interface Option {
  label: string;
  value: string;
}

class ExpenseBillUnPaidList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      page: 1,
      totalSize: 0,
      payData: [],
      data: [],
      sortField: "invoice_date",
      sortOrder: "desc",
      totalAmount: 0,
      modal_is_open: false,
      isOpenPayModal: false,
      active_inv_id: "",
      active_inv_number: 0,
      active_invoice_amount: 0,
      active_cust_name: "",
      isOpenConformModal: false,
      Rowdelete: null,
      alertCantDelClose: false,
      active_vendor_name: "",
      modalView: false,
      billing_address: "",
      active_invoice_date: null,
      active_invoice_no: "",
      phone: "",
      gstin: "",
      pan: "",
      email: "",
      active_ven_id: "",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/expense_bill_unpaidlist_grid`,
          param
        );
        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              totalAmount: resp.data.totalAmount,
              data: resp.data.results,
            });
            this.props.setTotalAmount({
              unpaidamt: resp.data.totalAmount,
              unpaidLen: resp.data.count,
              unpaid: resp.data.unpaid,
              un_partially_paid: resp.data.un_partially_paid,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };
  async componentDidMount() {
    try {
      this._isMounted = true;
    } catch (err) {
      console.error(err);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.setState({ page: param.page });
    this.setState({ sortField: param.sortField });
    this.setState({ sortOrder: param.sortOrder });
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: param.page,
      searchText: "",
      sizePerPage: param.sizePerPage,
      sortField: param.sortField,
      sortOrder: param.sortOrder,
      vendor_id: this.props.valueVendor?.value,
      startDate: startDate,
      endDate: endDate,
    });
  };
  handleCantDel = () => {
    this.setState({ alertCantDelClose: true });
  };
  handleCantDelClose = () => {
    this.setState({ alertCantDelClose: false });
  };
  handleDelete = (row: any) => {
    console.log(row);
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/purchase_delete/" +
            this.state.Rowdelete
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: this.state.page,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      vendor_id: this.props.valueVendor,
      startDate: startDate,
      endDate: endDate,
    });
    this.props.setRefresh(true);
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  recordPayment = (
    event: any,
    row: { id: string; invoice_no: number; amount: number; vendor_id: string }
  ) => {
    event.stopPropagation();
    this.setState({
      active_inv_id: row.id,
      active_inv_number: row.invoice_no,
      active_invoice_amount: row.amount,
      active_ven_id: row.vendor_id,
    });
    this.openModal();
  };

  openModal = () => {
    this.setState({ modal_is_open: true });
  };

  closeModal = () => {
    this.setState({ modal_is_open: false });
  };

  PayHistory = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      // this.setState({ active_inv_id: row.id, isOpenPayModal: !this.state.isOpenPayModal });
      this.setState({
        active_inv_id: row.id,
        active_ven_id: row.vendor_id,
        isOpenPayModal: !this.state.isOpenPayModal,
        active_cust_name: row.customer_name,
        // active_invoice_no:
        //   this.state.invoice_prefix + this.row.invoice_no,
        active_invoice_amount: row.gross_total_base,
      });
    } catch (err) {
      console.error(err);
    }
  };

  togglePay = () => {
    try {
      this.setState({ isOpenPayModal: !this.state.isOpenPayModal });
    } catch (err) {
      console.error(err);
    }
  };

  componentDidUpdate(prevProps: MyProps) {
    if (
      this.props.valueVendor !== prevProps.valueVendor ||
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.refresh !== prevProps.refresh
    ) {
      let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
      this.fetchData({
        page: this.state.page,
        searchText: "",
        vendor_id: this.props.valueVendor?.value,
        startDate: startDate,
        endDate: endDate,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  view = async (row: any) => {
    try {
      let billing_state = undefined;
      if (row.billing_country == "99") {
        billing_state = this.props.states.find(
          ({ id }) => id === row.billing_state
        ).state;
      } else {
        billing_state = row.billing_state;
      }
      let billing_country = this.props.countries.find(
        ({ id }) => id === row.billing_country
      );
      let billing_address = "";
      if (!isNull(row.billing_address1)) {
        billing_address += `${row.billing_address1} `;
      }
      if (!isNull(row.billing_address2)) {
        billing_address += `${row.billing_address2} `;
      }
      if (!isNull(row.billing_town_or_city)) {
        billing_address += `${row.billing_town_or_city}\n`;
      }
      if (!isNull(billing_state)) {
        billing_address += `${billing_state} - `;
      }
      if (!isNull(row.billing_pincode)) {
        billing_address += `${row.billing_pincode} `;
      }
      if (billing_country !== undefined) {
        billing_address += `${billing_country.name}`;
      }
      this.setState({
        active_inv_id: row.id,
        active_vendor_name: row.vendor_name,
        modalView: true,
        billing_address: billing_address,
        active_invoice_no: row.invoice_no,
        active_invoice_date: row.invoice_date,
        active_ven_id: row.vendor_id,
        email: row.email,
        phone: row.phone,
        gstin: row.gstin,
        pan: row.pan,
      });
    } catch (err) {
      console.error(err);
    }
  };
  toggleView = () => {
    try {
      this.setState({ modalView: false });
    } catch (err) {
      console.error(err);
    }
  };
  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    this.view(row);
  };

  handleRefresh = () => {
    this.props.setRefresh(true);
  };

  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "invoice_date",
        text: "Bill date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {moment(row.invoice_date).format("DD MMM YYYY")}
              <br />
              <span style={{ fontSize: "10px" }}>
                {moment(row.created_at).format("DD MMM YYYY h:mm:ss A")}
              </span>
            </>
          );
        },
      },
      {
        dataField: "vendor_name",
        text: "Vendor",
        sort: true,
      },
      {
        dataField: "invoice_no",
        text: "Bill Number",
        sort: true,
      },
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{`₹ ${number_format(cellContent)}`}</>;
        },
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          let due_date = moment(row.due_date);
          let cur_date = moment();
          let days = cur_date.diff(due_date, "days");
          let status;
          if (row.status == 2) {
            status = (
              <>
                <span
                  className="status sent"
                  onClick={(e: any) => this.PayHistory(e, row)}
                >
                  Partially Paid
                </span>
                {/* <label className="status-mess">{days} days due</label> */}
              </>
            );
          } else if (row.status == 3) {
            status = (
              <span
                onClick={(e: any) => this.PayHistory(e, row)}
                className="status partially"
              >
                Paid
              </span>
            );
          } else {
            status = (
              <>
                <span className="status pending">Unpaid</span>
                {/* <label className="status-mess">{days} days due</label> */}
              </>
            );
          }
          return <>{status}</>;
        },
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <div className="table-button">
              <div className="icon-wrapper">
                <button className="round-btn">
                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a">
                      <EllipsisIcon />
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link to={"/expense_bill_edit/" + row.id}>
                        <DropdownItem to="#" tag="span">
                          <i
                            className="fas fa-edit"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit"
                            style={{ color: "#28a745" }}
                          />{" "}
                          Edit
                        </DropdownItem>
                      </Link>
                      {row.status !== 2 && row.status !== 3 ? (
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleDelete(row)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete"
                            style={{ color: "#dc3545" }}
                          />{" "}
                          Delete
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleCantDel()}
                        >
                          <i
                            className="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete"
                            style={{ color: "#dc3545" }}
                          />{" "}
                          Delete
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </button>
                <div className="tooltip-icon">Action</div>
              </div>
              <div className="icon-wrapper">
                <button
                  onClick={() => this.view(row)}
                  className="normal-btn-info"
                >
                  Info
                </button>
                <div className="tooltip-icon">Info</div>
              </div>
              <div className="icon-wrapper">
                <Link
                  to="#"
                  className="mx-2"
                  onClick={(e: any) => this.recordPayment(e, row)}
                >
                  <button className="normal-btn">
                    Record <i className="fas fa-solid fa-arrow-down"></i>
                  </button>
                </Link>
                <div className="tooltip-icon">Record Payment</div>
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={this.state.data}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive tbl-invoice">
                        <BootstrapTable
                          // responsive
                          remote
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          onTableChange={this.handleTableChange}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          noDataIndication={() => <NoDataIndication />}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
        <DeleteModal
          isOpen={this.state.isOpenConformModal}
          message="Are you sure you want to delete this Expense Bills?"
          subMsg="The expense bills will be permanently deleted."
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></DeleteModal>
        <RecordPaymentModal
          modal_is_open={this.state.modal_is_open}
          openModal={this.openModal}
          closeModal={this.closeModal}
          data={this.state.payData}
          purchase_hdr_id={this.state.active_inv_id}
          /* customer_name={this.state.active_cust_name} invoice_no={this.state.active_invoice_no} */ invoice_amount={
            this.state.active_invoice_amount
          }
          handleRefresh={this.handleRefresh}
        />
        {this.state.modalView ? (
          <ExpenseBillDetailsModel
            info={1}
            id={this.state.active_inv_id}
            vendor_name={this.state.active_vendor_name}
            billing_address={this.state.billing_address}
            invoice_no={this.state.active_invoice_no}
            invoice_date={this.state.active_invoice_date}
            vendor_id={this.state.active_ven_id}
            phone={this.state.phone}
            gstin={this.state.gstin}
            pan={this.state.pan}
            email={this.state.email}
            isOpen={this.state.modalView}
            toggle={this.toggleView}
          />
        ) : null}
        <RecordPurchasePaymentHistory
          handleRefresh={this.handleRefresh}
          invoice_id={this.state.active_inv_id}
          isOpen={this.state.isOpenPayModal}
          toggle={this.togglePay}
          invoice_amount={this.state.active_invoice_amount}
        />
        <PurAlertModal
          isOpen={this.state.alertCantDelClose}
          onClose={this.handleCantDelClose}
        ></PurAlertModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: { countries: any; states: any }) => {
  return {
    countries: state.countries.countries,
    states: state.states.states,
  };
};
export default connect(mapStateToProps)(withRouter(ExpenseBillUnPaidList));
