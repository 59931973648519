import {CompaniesTypes} from "./actionTypes"

export const getCompanies = () => ({
  type: CompaniesTypes.GET_COMPANIES,
})

export const getCompaniesSuccess = (companies : any) => ({
  type: CompaniesTypes.GET_COMPANIES_SUCCESS,
  payload: companies,
})

export const getCompaniesFail = (error : any) => ({
  type: CompaniesTypes.GET_COMPANIES_FAIL,
  payload: error,
})



