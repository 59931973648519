import {StatesTypes} from "./actionTypes"

export const getStates = () => ({
  type: StatesTypes.GET_STATES,
})

export const getStatesSuccess = (states : any) => ({
  type: StatesTypes.GET_STATES_SUCCESS,
  payload: states,
})

export const getStatesFail = (error : any) => ({
  type: StatesTypes.GET_STATES_FAIL,
  payload: error,
})



