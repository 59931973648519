import {UnitsTypes} from "./actionTypes"

export const getUnits = () => ({
  type: UnitsTypes.GET_UNITS,
})

export const getUnitsSuccess = (units : any) => ({
  type: UnitsTypes.GET_UNITS_SUCCESS,
  payload: units,
})

export const getUnitsFail = (error : any) => ({
  type: UnitsTypes.GET_UNITS_FAIL,
  payload: error,
})



