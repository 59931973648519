import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Button,
  Alert,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo_login.png";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { Razorpay } from "./Razorpay";
import { round } from "lodash";

type WizardState = {
  breadcrumbItems: Object;
  activeTabProgress: number;
  payment: string;
  designation: string;
  phone: string;
  password: string;
  cpassword: string;
  companyName: string;
  companyAaddress: string;
  companyAaddress2: string;
  companyTownOrCity: string;
  companyState: string;
  companyPincode: string;
  gstno: string;
  companyEmail: string;
  companyTelephone: string;
  code: string;
  amount: number;
  r_key: string;
  order_id: string;
  currency: string;
  merchant_order_id: string;
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  is_success: number;
  is_progress: number;
  is_error: number;
  plans: Array<any>;
  plan_name: string;
  states: Array<any>;
};
interface MatchParams {
  code: string;
}

interface response {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  orderId: string;
}
class RegisterWizard extends Component<RouteComponentProps<MatchParams>, WizardState> {
  constructor(props: any) {
    super(props);
    const { code } = this.props.match.params;
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Wizard", link: "#" },
      ],
      activeTabProgress: 1,
      payment: 'T',
      designation: "",
      phone: "",
      password: "",
      cpassword: "",
      companyName: "",
      companyAaddress: "",
      companyAaddress2: "",
      companyTownOrCity: "",
      companyState: "",
      companyPincode: "",
      gstno: "",
      companyEmail: "",
      companyTelephone: "",
      code: code,
      amount: 0,
      r_key: '',
      order_id: '',
      currency: '',
      merchant_order_id: '',
      razorpay_payment_id: '',
      razorpay_order_id: '',
      razorpay_signature: '',
      is_success: 0,
      is_progress: 0,
      is_error: 0,
      plans: [],
      plan_name: '',
      states: []
    };
    this.toggleTabProgress.bind(this);
    this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit.bind(this);
    this.onPaymentHandler.bind(this);
  }

  toggleTabProgress(tab: any) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTabProgress: tab,
        });
      }
    }
  }
  toggleTab2(plan: any) {
    this.setState({
      payment: plan.id,
      plan_name: plan.plan_name,
      amount: plan.plan_price
    });
  }
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  }
  handleInvalidSubmit = (event: any, errors: Array<any>, values: any) => {
    if (errors.includes('companyName') || errors.includes('companyAaddress') || errors.includes('gstno') || errors.includes('companyEmail') || errors.includes('companyTelephone')) {
      this.setState({
        activeTabProgress: 2,
      });
    }
    if (errors.includes('designation') || errors.includes('phone') || errors.includes('password') || errors.includes('cpassword')) {
      this.setState({
        activeTabProgress: 1,
      });
    }
  }

  onPaymentHandler = (response: response) => {
    /* const data = {
        orderCreationId:  this.state.order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
    };

  console.log('data on payment handler');
  console.log(data); */

    this.setState({
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
    });

    const sendGetRequest = async () => {
      try {
        this.setState({
          is_progress: 1,
        });
        const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/create_account', this.state);
        console.log('success');
        console.log(resp.data.success);
        if (resp.data.success) {
          this.setState({
            is_success: 1,
          });
        } else {
          this.setState({
            is_progress: 0,
            is_success: 0,
            is_error: 1,
          });
        }

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  }

  handleValidSubmit = async (event: any, values: any) => {

    if (this.state.payment > '1') {
      let amount: number = round((this.state.amount * 118 / 100),2);
      const result = await axios.post(process.env.REACT_APP_API_URL + '/api/razorpay_init', { amount: amount });

      if (!result) {
        console.log("Server error.");
      }

      this.setState(result.data.data);

      const orderId = this.state.order_id;
      const state = this.state;
      const appName = process.env.REACT_APP_NAME;
      const options = {
        key: this.state.r_key,
        amount: this.state.amount,
        currency: this.state.currency,
        name: appName,
        description: `${this.state.plan_name} Payment`,
        order_id: orderId,
        handler: this.onPaymentHandler.bind(this),
        prefill: {
          name: this.state.companyName,
          email: this.state.companyEmail,
          contact: this.state.companyTelephone,
        },
        notes: {
          address: this.state.companyAaddress,
          merchant_order_id: this.state.merchant_order_id
        },
        theme: {
          color: "#6266b0",
        },
      };

      const paymentObject = Razorpay(options);
      paymentObject.open();
    } else {
      const sendGetRequest = async () => {
        try {
          this.setState({
            is_progress: 1,
          });
          const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/create_account', this.state);
          if (resp.data.success) {
            this.setState({
              is_success: 1,
            });
          }

        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      };

      sendGetRequest();

    }

  }

  loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  componentDidMount() {

    const sendGetRequest = async () => {
      try {

        const res = await this.loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        const resp = await axios.get(process.env.REACT_APP_API_URL + '/api/verify/' + this.state.code);
        if (!resp.data.data.verified) {
          this.props.history.push("/pages-404");
        } else {
          this.setState({ plans: resp.data.data.plans, states: resp.data.data.states });
        }

        /*  const result = await axios.post(process.env.REACT_APP_API_URL + '/api/razorpay_init', { amount: this.state.amount });
 
         if (!result) {
           console.log("Server error.");
         }
 
         this.setState(result.data.data); */

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();

  }

  render() {
    const appName = process.env.REACT_APP_NAME;

    return (
      <React.Fragment>
        <Col lg={8} md={6} className="col-xxl-8 auth-full-page-content">
          <AvForm id="form1" onInvalidSubmit={this.handleInvalidSubmit} onValidSubmit={this.handleValidSubmit}>
            <div className="w-100">
              {this.state.is_progress ?
                (
                  <CardBody>
                    <div className="mb-4 mt-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                      {/* <img className="img-fluid" src={logo} height="90" alt="" /> */}
                      <img src={logo} alt="" height="90" />
{" "} 
                        {/* <span className="logo-txt">{appName}</span> */}
                      </Link>
                    </div>
                    <div className="text-center">
                      <h5 className="mb-0">Register Account</h5>
                      <p className="text-muted mt-2">
                        Get your {appName} account now.
                      </p>
                    </div>
                    {this.state.is_success === 0 ?
                      (
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              <div>
                                <h5>Account Creating</h5>
                                <p className="text-muted">
                                  Please wait, your invoice management system will be ready soon.<br />
                                  (It may take few seconds.)
                                </p>
                              </div>
                            </div>
                          </Col>
                        </div>
                      ) : (
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                              </div>
                              <div>
                                <h5>Success</h5>
                                <p className="text-muted">
                                  Your account has been successfully created. Thank you for choosing {appName}.
                                </p>
                                <div className="mt-4">
                                  <Link
                                    to="/"
                                    className="btn btn-primary w-10"
                                  >
                                    Back to Home
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      )
                    }
                  </CardBody>
                ) : (
                  <CardBody>

                    <div className="mb-4 mt-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logo} alt="" height="90" />{" "}
                        {/* <span className="logo-txt">{appName}</span> */}
                      </Link>
                    </div>
                    <div className="text-center">
                      <h5 className="mb-0">Register Account</h5>
                      <p className="text-muted mt-2">
                        Get your {appName} account now.
                      </p>
                    </div>
                    {this.state.is_error === 1 ?
                      (
                        <Alert color="danger">
                          Something went wrong
                        </Alert>
                      ) : null
                    }

                    <div id="progrss-wizard" className="twitter-bs-wizard px-5">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames("nav-link ", {
                              active: this.state.activeTabProgress === 1,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(1);
                            }}
                          >
                            <div
                              className="step-icon"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Seller Details"
                            >
                              <i className="bx bx-list-ul"></i>
                            </div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("nav-link", {
                              active: this.state.activeTabProgress === 2,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(2);
                            }}
                          >
                            <div
                              className="step-icon"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Company Document"
                            >
                              <i className="bx bx-book-bookmark"></i>
                            </div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("nav-link", {
                              active: this.state.activeTabProgress === 3,
                            })}
                            onClick={() => {
                              this.toggleTabProgress(3);
                            }}
                          >
                            <div
                              className="step-icon"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Bank Details"
                            >
                              <i className="bx bxs-bank"></i>
                            </div>
                          </NavLink>
                        </NavItem>
                      </ul>

                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <div className="text-center mb-4">
                            <h5>Account Profile</h5>
                            <p className="card-title-desc">
                              Fill all information below
                            </p>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="progresspill-address-input">
                                  Designation
                                </label>
                                <AvField
                                  type="textarea"
                                  name="designation"
                                  id="progresspill-address-input"
                                  className="form-control"
                                  rows={1}
                                  onChange={this.onChangehandler}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="progresspill-phoneno-input">
                                  Phone
                                </label>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  name="phone"
                                  id="progresspill-phoneno-input"
                                  onChange={this.onChangehandler}
                                  minLength="10"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="progresspill-password-input">
                                  Password
                                </label>
                                <AvField
                                  type="password"
                                  className="form-control"
                                  id="progresspill-password-input"
                                  name="password"
                                  onChange={this.onChangehandler}
                                  validate={{
                                    required: { value: true },
                                    pattern: { value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage: "Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length" },
                                    minLength: { value: 6, errorMessage: "Password must contain atleast 6 characters" }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="progresspill-cpassword-input">
                                  Confirm Password
                                </label>
                                <AvField
                                  type="password"
                                  className="form-control"
                                  id="progresspill-cpassword-input"
                                  name="cpassword"
                                  validate={{
                                    required: { value: true },
                                    match: { value: "password" },
                                    pattern: { value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage: "Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length" },
                                    minLength: { value: 6, errorMessage: "Password must contain atleast 6 characters" }
                                  }}
                                  onChange={this.onChangehandler}
                                />
                              </div>
                            </div>
                          </div>

                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <div className="text-center mb-4">
                              <h5>Company Details</h5>
                              <p className="card-title-desc">
                                Fill all information below
                              </p>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-company-name-input"
                                    className="form-label"
                                  >
                                    Company Name
                                  </label>
                                  <AvField
                                    type="text"
                                    className="form-control"
                                    id="progresspill-company-name-input"
                                    name="companyName"
                                    onChange={this.onChangehandler}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-company-address-input">
                                    {/* Address */}
                                    Flat, House no., Building, Apartment
                                  </label>
                                  <AvField
                                    type="textarea"
                                    name="companyAaddress"
                                    id="progresspill-company-address-input"
                                    className="form-control"
                                    rows={1}
                                    onChange={this.onChangehandler}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-company-address-input">
                                    Area, Street, Sector, Village
                                  </label>
                                  <AvField
                                    type="textarea"
                                    name="companyAaddress2"
                                    id="progresspill-company-address-input"
                                    className="form-control"
                                    rows={1}
                                    onChange={this.onChangehandler}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-company-address-input">
                                    Town/City
                                  </label>
                                  <AvField
                                    type="text"
                                    name="companyTownOrCity"
                                    id="progresspill-company-address-input"
                                    className="form-control"
                                    onChange={this.onChangehandler}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-company-address-input">
                                    State
                                  </label>
                                  <AvField type="select" name="companyState" validate={{ required: { value: true } }} onChange={this.onChangehandler}>
                                    <option key={-1} value={""}>--Select--</option>
                                    {this.state.states.map((state) => (
                                      <option value={state.id} key={state.id}>{state.state}</option>
                                    ))}
                                  </AvField>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="progresspill-company-address-input">
                                    Pincode
                                  </label>
                                  <AvField
                                    type="text"
                                    name="companyPincode"
                                    id="progresspill-company-address-input"
                                    className="form-control"
                                    onChange={this.onChangehandler}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-gstno-input"
                                    className="form-label"
                                  >
                                    GST No.
                                  </label>
                                  <AvField
                                    type="text"
                                    className="form-control"
                                    id="progresspill-gstno-input"
                                    name="gstno"
                                    onChange={this.onChangehandler}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-company-email-input"
                                    className="form-label"
                                  >
                                    Company Email
                                  </label>
                                  <AvField
                                    type="email"
                                    className="form-control"
                                    id="progresspill-company-email-input"
                                    name="companyEmail"
                                    onChange={this.onChangehandler}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="progresspill-telephone-input"
                                    className="form-label"
                                  >
                                    Telephone
                                  </label>
                                  <AvField
                                    type="text"
                                    className="form-control"
                                    id="progresspill-telephone-input"
                                    name="companyTelephone"
                                    onChange={this.onChangehandler}
                                    minLength="10"
                                    required
                                  />
                                </div>
                              </div>

                            </div>

                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div className="text-center mb-4">
                            <h5>Plans & Payments</h5>
                            <p className="card-title-desc">
                              Choose Plan
                            </p>
                          </div>

                          <div className="row">
                            <Nav pills className="flex-column pricing-tab-box">
                              {this.state.plans.map(plan => {
                                return (
                                  <NavLink
                                    to="#"
                                    className={classnames(
                                      {
                                        active: this.state.payment === plan.id,
                                      },
                                      "mb-3"
                                    )}
                                    onClick={() => {
                                      this.toggleTab2(plan);
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <i className="bx bx-check-circle h3 mb-0 me-4"></i>
                                      <div className="flex-1">
                                        <h2 className="fw-medium">
                                          {plan.plan_price > 0 ? plan.plan_price : "Free"}{" "}
                                          <span className="text-muted font-size-15">
                                          {plan.plan_price > 0 ? "+ GST" : ""} / {plan.plan_name}
                                          </span>
                                        </h2>
                                        <p className="fw-normal mb-0 text-muted">
                                          {plan.description}
                                        </p>
                                      </div>
                                    </div>
                                  </NavLink>
                                )
                              })}

                            </Nav>
                          </div>
                        </TabPane>
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className={
                              this.state.activeTabProgress === 1
                                ? "btn btn-primary disabled"
                                : "btn btn-primary"
                            }
                            onClick={() => {
                              this.toggleTabProgress(
                                this.state.activeTabProgress - 1
                              );
                            }}
                          >
                            <i className="bx bx-chevron-left me-1"></i> Previous
                          </Link>
                        </li>
                        {
                          this.state.activeTabProgress === 3
                            ? (
                              <li
                                className="next"
                              >
                                <Button type="submit" color="primary">Submit</Button>
                              </li>
                            ) : (
                              <li
                                className="next"
                              >
                                <Link
                                  to="#"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    this.toggleTabProgress(
                                      this.state.activeTabProgress + 1
                                    );
                                  }}
                                >
                                  Next <i className="bx bx-chevron-right ms-1"></i>
                                </Link>
                              </li>
                            )
                        }


                      </ul>
                    </div>
                  </CardBody>)
              }

            </div>
            <div className="mt-4 mt-md-5 text-center position-relative Register-footer" >
              <p className="mb-0">
                © {new Date().getFullYear()} {appName}
              </p>
            </div>
          </AvForm>

        </Col>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterWizard);
