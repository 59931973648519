import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PdfIcon from "src/assets/images/icons/Pdf";
import { post } from "src/helpers/api_helper";
import { number_format, predefinedRanges } from "src/helpers/common";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";

interface Props {
  modal_is_open: boolean;
  openModal?:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
  invoice_id?: string;
  item_name: string;
  item_id: string;
}
function itemLedger(props: Props) {
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [isProgress, setIsProgress] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()));
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [data, setData] = useState<Array<any> | any>([]);
  const [parentCategoryId, setParentCategoryId] = useState<Array<any> | any>(
    []
  );
  const [openDate, setOpendate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [isSubmit, setIsSubmit] = useState(false);

  const closeThis = () => {
    setStartDate(null);
    setEndDate(null);
    setData([]);
    setParentCategoryId([]);
    setIsSubmit(false);
    props.closeModal();
    setOpendate(null);
  };
  const setStartDateNew = (dateRange: any) => {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    let start_date = moment(startDate);
    const formatted_start_date = new Date(start_date.format("YYYY-MM-DD"));
    setDateRange(dateRange);
    setStartDate(formatted_start_date);
    let end_date = moment(endDate);
    const formatted_end_date = new Date(end_date.format("YYYY-MM-DD"));
    setEndDate(formatted_end_date);
  };
  const submit = async (startD: Date | null, endD: Date | null) => {
    setIsProgress(true);
    search(startD, endD);
  };
  const search = async (startD: Date | null, endD: Date | null) => {
    try {
      let start_date = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      let postValues = {
        item_id: props.item_id,
        startDate: start_date,
        endDate: end_date,
      };
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/item_statement`,
        postValues
      );
      if (resp.success) {
        setIsProgress(false);
        setOpendate(startDate);
        setData(resp.data.statements);
        setParentCategoryId(resp.data.parentCategoryId);
        setIsSubmit(true);
      }
    } catch (err) {
      setIsProgress(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.modal_is_open) {
      let startD = startOfMonth(new Date());
      let endD = endOfMonth(new Date());
      setStartDate(startD);
      setEndDate(endD);
      search(startD, endD);
    }
  }, [props.modal_is_open, props.item_id]);

  const downloadPdf = async () => {
    let start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    let postValues = {
      item_id: props.item_id,
      startDate: start_date,
      endDate: end_date,
    };
    const pdfResp = await post(
      process.env.REACT_APP_API_URL + `/api/item_statement_pdf`,
      postValues
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfResp.file;
    downloadLink.download = pdfResp.fname;
    downloadLink.click();
  };

  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });

  var balance = 0;
  var totalCredit = 0;
  var totalDebit = 0;
  return (
    <Modal
      isOpen={props.modal_is_open}
      toggle={props.openModal}
      className={
        "md-1000 ledger-modal modal-dialog-centered modal-dialog-scrollable h-100 my-0"
      }
    >
      <div className="modal-header d-block ledger-header border-0 justify-content-center pb-0">
        <Row className="pb-2">
          <Col lg={12}>
            <h5 className="modal-title text-center mt-0 fw-normal">
              Item Statement
            </h5>
          </Col>
          <Col lg={12}>
            <div
              className="text-center text-uppercase"
              style={{ fontSize: 20 }}
            >
              {props.item_name}
            </div>
          </Col>
          <Col lg={4} className="offset-lg-3 pe-0">
            <DateRangePicker
              ranges={predefinedRanges}
              format="dd/MM/yyyy"
              placeholder=" "
              value={dateRange}
              style={{ width: 330 }}
              onChange={(date: DateRange | null) => setStartDateNew(date)}
            />
          </Col>
          <Col lg={4}>
            {isProgress ? (
              <>
                <Button
                  type="submit"
                  className="bmt-btn-submit-1"
                  disabled={true}
                >
                  Submitting
                  <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  style={{ padding: "5px 25px" }}
                  className="bmt-btn-submit me-1"
                  onClick={() => submit(startDate, endDate)}
                >
                  Submit
                </Button>
              </>
            )}
            <Link to="#" onClick={(e: any) => downloadPdf()}>
              <button className="btn round-btn btn-pdf" title="PDF">
                <PdfIcon />
              </button>
            </Link>
          </Col>
        </Row>
        <button
          type="button"
          onClick={() => closeThis()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeButton"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <div className="mb-3">
          <Row>
            <Col xs="12">
              <div>
                <div className="form-inline">
                  {isSubmit && (
                    <Row>
                      <Col lg={12}>
                        <Table
                          className="align-middle mb-0 ledger-table mt-1"
                          style={{
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "110px" }}>Date</th>
                              <th>Reference</th>
                              <th
                                style={{ textAlign: "center", width: "120px" }}
                              >
                                Debit
                              </th>
                              <th
                                style={{ textAlign: "center", width: "120px" }}
                              >
                                Credit
                              </th>
                              <th
                                style={{ textAlign: "center", width: "120px" }}
                              >
                                Balance
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map(
                              (
                                statement: {
                                  expense_date: string;
                                  bank_cashbook_name: string;
                                  amount: number;
                                  credit_amount: number;
                                  debit_amount: number;
                                  type: number;
                                  vendor_name: string;
                                  invoice_no: string;
                                  journal_entry_prefix: string;
                                },
                                index: number
                              ) => {
                                let debit_amount = 0;
                                let credit_amount = 0;
                                if(statement.type === 3){
                                  if (
                                    parentCategoryId === "1" ||
                                    parentCategoryId === "3"
                                  ) {
                                    balance = Number(
                                      (
                                        Number(balance) - Number(statement.amount)
                                      ).toFixed(2)
                                    );
                                    credit_amount = statement.amount;
                                    totalCredit =
                                      Number(totalCredit) +
                                      Number(statement.amount);
                                  } else {
                                    balance = Number(
                                      (
                                        Number(balance) + Number(statement.amount)
                                      ).toFixed(2)
                                    );
                                    credit_amount = statement.amount;
                                    totalCredit =
                                      Number(totalCredit) +
                                      Number(statement.amount);
                                  }
                                }else if(statement.type === 2){
                                  if (statement.credit_amount !== 0) {
                                    balance = Number(
                                      (
                                        Number(balance) - Number(statement.credit_amount)
                                      ).toFixed(2)
                                    );
                                    credit_amount = statement.credit_amount;
                                    totalCredit =
                                      Number(totalCredit) +
                                      Number(statement.credit_amount);
                                  }
                                  if (statement.debit_amount !== 0) {
                                    balance = Number(
                                      (
                                        Number(balance) + Number(statement.debit_amount)
                                      ).toFixed(2)
                                    );
                                    debit_amount = statement.debit_amount;
                                    totalDebit =
                                      Number(totalDebit) +
                                      Number(statement.debit_amount);
                                  }
                                }
                                else{
                                  if (
                                    parentCategoryId === "1" ||
                                    parentCategoryId === "3"
                                  ) {
                                    balance = Number(
                                      (
                                        Number(balance) + Number(statement.amount)
                                      ).toFixed(2)
                                    );
                                    debit_amount = statement.amount;
                                    totalDebit =
                                      Number(totalDebit) +
                                      Number(statement.amount);
                                  } else {
                                    balance = Number(
                                      (
                                        Number(balance) + Number(statement.amount)
                                      ).toFixed(2)
                                    );
                                    credit_amount = statement.amount;
                                    totalCredit =
                                      Number(totalCredit) +
                                      Number(statement.amount);
                                  }
                                }
                                return (
                                  <tr key={index}>
                                    <td>
                                      {moment(statement.expense_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {statement.type === 0
                                        ? `${statement.vendor_name} - (${statement.invoice_no})`
                                        : statement.type === 1
                                        ? `${statement.bank_cashbook_name} - (Exp${statement.invoice_no})`
                                        : statement.type === 2
                                        ? `Journal-(${statement.journal_entry_prefix}${statement.invoice_no})`
                                        : statement.type === 3
                                        ? `${statement.bank_cashbook_name} - (INC${statement.invoice_no})`
                                        : null}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {debit_amount > 0
                                        ? number_format(debit_amount)
                                        : "-"}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {credit_amount > 0
                                        ? number_format(credit_amount)
                                        : "-"}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {number_format(balance)}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(totalDebit)}</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(totalCredit)}</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(balance)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(itemLedger);
