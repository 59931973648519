import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Table,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SentIcon from "src/assets/images/icons/Sent";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";

// Import Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import SendEstModel from "./SendEstModel";
import RenewPlan from "../Payments/RenewPlan";
import EstimationDetailsPdf from "./PerformaDetailsPdf";
import PdfIcon from "src/assets/images/icons/Pdf";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";
import { getLetters, number_format, padLeadingZeros } from "src/helpers/common";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";
import PerformaDetailsModel from "./PerformaDetailsModel";
import ConfirmationModal from "src/components/ConfirmationModal";
import Tooltip from "src/helpers/tooltip/Tooltip";
interface MyProps extends RouteComponentProps<any> {
  valueCustomer?: Option | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  // setTotalAmount: ({ }) => void;
  isPaginationRequire?: boolean;
  // setDownloadpdf: (id: string) => void;
  customers: Array<any>;
  permissions: Array<any>;
  companies: { orders: any; logoFile: string };
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
  setTotalAmount: ({}) => void;
}

interface MyState {
  id: string;
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  proforma_invoice_prefix: string;
  permissions: Array<any>;
  isLoading: boolean;
  options: Option[];
  valueCustomer: Option | null | undefined;
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  totalAmount: number;
  modal: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  loading: boolean;
  instance_id: string;
  modalView: boolean;
  company_name: string;
  isPlanExpired: number;
  modalPlan: boolean;
  pdfId: string;
  active_est_no: string;
  modal_cust_is_open: boolean;
  modal_item_is_open: boolean;
  isOpenConformModal: boolean;
  convertToInvoiceModal: boolean;
  Rowdelete: string | null;
  RowConvert: string | null;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});

class PerformaAllMobile extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "proforma_invoice_no",
      sortOrder: "desc",
      proforma_invoice_prefix: "",
      permissions: [],
      isLoading: true,
      options: [],
      valueCustomer: null,
      customers: [],
      // startDate: new Date(),
      // endDate: new Date(moment().add(6, 'days').format("YYYY-MM-DD"))
      startDate: null,
      endDate: null,
      totalAmount: 0,
      modal: false,
      isSending: false,
      editorState: EditorState.createEmpty(),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      loading: true,
      instance_id: "",
      modalView: false,
      company_name: "",
      isPlanExpired: 0,
      pdfId: "",
      modalPlan: false,
      active_est_no: "",
      modal_cust_is_open: false,
      modal_item_is_open: false,
      isOpenConformModal: false,
      convertToInvoiceModal: false,
      Rowdelete: null,
      RowConvert: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        param["startDate"] = param["startDate"]
          ? moment(param["startDate"]).format("YYYY-MM-DD")
          : null;
        param["endDate"] = param["endDate"]
          ? moment(param["endDate"]).format("YYYY-MM-DD")
          : null;
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/proforma_invoice_list_grid`,
          param
        );
        if (resp.success) {
          this.setState({
            totalSize: resp.data.count,
            totalAmount: resp.data.totalAmount,
            data: resp.data.results,
            proforma_invoice_prefix: resp.data.proforma_invoice_prefix,
            instance_id: resp.data.instance_id,
            company_name: resp.data.company_name,
          });
          this.props.setTotalAmount({
            totalAmount: resp.data.totalAmount,
            totalSize: resp.data.count,
          });
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.fetchData(param);
  };

  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/proforma_invoice_delete/" +
            this.state.Rowdelete
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);;
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);;
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };

  downloadPdf = (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ pdfId: row.id });
  };
  downloadPdfclose = () =>{
    this.setState({ pdfId: "" });
  }
  convertToInvoice = async (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ convertToInvoiceModal: true });
    this.setState({ RowConvert: row.id });
  };
  handleConvertToInvoiceConfirm = () => {
    this.props.history.push("/invoice_convert/" + this.state.RowConvert);
    this.setState({ convertToInvoiceModal: false });
    this.setState({ RowConvert: null });
  };
  handleConvertToInvoiceClose = () => {
    this.setState({ convertToInvoiceModal: false });
    this.setState({ RowConvert: null });
  };

  send = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let est_no = this.state.proforma_invoice_prefix
        ? this.state.proforma_invoice_prefix +
          row.proforma_invoice_no
        : "INV" + row.proforma_invoice_no;
      this.setState({
        id: row.id,
        active_est_no: est_no,
        email: row.email,
        modal: !this.state.modal,
      });
    } catch (err) {
      console.error(err);
    }
  };

  view = async (row: any) => {
    try {
      // this.setState({ id: row.id, modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  isPlanExpired = async (e: any, location: string) => {
    try {
      if (this.state.isPlanExpired) {
        this.props.history.push(location);
      } else {
        this.openModalPlan();
      }
    } catch (err) {
      console.error(err);
    }
  };

  toggleView = () => {
    try {
      this.setState({ modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  toggleSend = () => {
    try {
      this.setState({ modal: !this.state.modal });
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
    this.fetchData({
      page: 1,
      searchText: "",
      customer: newValue?.value,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);;
  };
  loadData = async () => {
    try {
      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers: any = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map((customer: any) => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      let estmationPermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.ESTIMATION
      );

      this.setState({
        customers: customers,
        options: customerOptions,
        permissions: estmationPermissions,
      });

      this.setState({
        isLoading: false,
        loading: false,
      });
    } catch (err) {
      console.error(err);
    }
  };
  componentDidUpdate(prevProps: MyProps) {
    if (this.props.refresh !== prevProps.refresh) {
      this.fetchData({
        page: 1,
        searchText: "",
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  async componentWillReceiveProps(nextProps: MyProps) {
    await this.loadData();

    if (
      this.state.valueCustomer !== nextProps.valueCustomer ||
      this.state.startDate !== nextProps.startDate ||
      this.state.endDate !== nextProps.endDate
    ) {
      this.setState({
        valueCustomer: nextProps.valueCustomer,
        startDate: nextProps.startDate || null,
        endDate: nextProps.endDate || null,
      });

      this.fetchData({
        page: 1,
        searchText: "",
        customer: nextProps.valueCustomer?.value,
        startDate: nextProps.startDate || null,
        endDate: nextProps.endDate || null,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  componentDidMount() {
    this.loadData();
  }
  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
    this.fetchData({
      page: 1,
      searchText: "",
      customer: this.state.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  }
  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/proforma_invoice_sent",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };

  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    // this.props.history.push("/proforma_details/" + row.id);
    this.setState({ id: row.id, modalView: !this.state.modalView });
  };

  preventParentAction = (event: any) => {
    event.stopPropagation();
  };
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
      showTotal: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columnsMob = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <Tooltip content={cellContent} direction="right">
            <span title={cellContent} >
                {getLetters(cellContent)}                            
            </span>
            </Tooltip>)
        },
      },
      {
        dataField: "invoice_no",
        text: "Number",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {this.state.proforma_invoice_prefix
                ? this.state.proforma_invoice_prefix +
                  row.proforma_invoice_no
                : "EST" + row.proforma_invoice_no}
              <br />
              {moment(row.invoice_date).format("DD/MM/YYYY")}
            </>
          );
        },
      },
      {
        dataField: "gross_total_base",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{`₹ ${number_format(cellContent)}`}</>;
        },
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          let due_date = moment(row.due_date);
          let cur_date = moment();
          let days = cur_date.diff(due_date, "days");
          let status;
          if (row.status == 4) {
            status = (
              <>
                <span className="status sent">Partially Paid</span>
                <label className="status-mess">{days} days due</label>
              </>
            );
          } else if (row.status == 5) {
            status = <span className="status partially">Paid</span>;
          } else {
            status = (
              <>
                <span className="status pending">Unpaid</span>
                <label className="status-mess">{days} days due</label>
              </>
            );
          }
          return <>{status}</>;
        },
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="table-button btns-inv">
                <button
                  className="round-btn"
                  title="Actions"
                  onClick={this.preventParentAction}
                >
                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a">
                      <EllipsisIcon />
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem
                        to="#"
                        tag="span"
                        onClick={(e: any) => this.downloadPdf(e, row)}
                      >
                        <PdfIcon /> Download Pdf
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        tag="span"
                        onClick={(e: any) => this.send(e, row)}
                      >
                        <SentIcon /> Send
                      </DropdownItem>
                      <a
                        target="_blank"
                        href={`https://api.whatsapp.com/send?text=Hello *${
                          row.customer_name
                        }*,%0a%0a Thanks for your business!%0a%0a Please find your Invoice ${
                          this.state.proforma_invoice_prefix
                        }${row.invoice_no} here,%0a ${
                          process.env.REACT_APP_URL
                        }/estimation-view/${this.state.instance_id}/${
                          row.id
                        }%0a%0a%0a Thanks%0a *${
                          this.state.company_name
                        }* %0a Sent using ${
                          process.env.REACT_APP_NAME
                        }(https://simblebooks.com/)`}
                        onClick={this.preventParentAction}
                      >
                        <DropdownItem to="#" tag="span">
                          <WhatsappIcon /> Whatsapp
                        </DropdownItem>
                      </a>
                      <Link to={"/invoice_edit/" + row.id}>
                        <DropdownItem to="#" tag="span">
                          <i
                            className="fas fa-edit"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit"
                            style={{ color: "#28a745" }}
                          />{" "}
                          View Pdf
                        </DropdownItem>
                      </Link>
                      <DropdownItem
                        to="#"
                        tag="span"
                        onClick={() => this.handleDelete(row)}
                      >
                        <i
                          className="fas fa-trash-alt"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Delete"
                          style={{ color: "#dc3545" }}
                        />{" "}
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </button>
              </div>
            </>
          );
        },
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <Tooltip content={cellContent} direction="right">
            <span title={cellContent} >
                {getLetters(cellContent)}                            
            </span>
            </Tooltip>
        )
        }
      },
      {
        dataField: "proforma_invoice_no",
        text: "Number",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {row.prefix ? row.prefix + row.proforma_invoice_no : "PRO" + row.proforma_invoice_no}
              <br />
              {moment(row.invoice_date).format("DD/MM/YYYY")}
            </>
          );
        }
      },
      {
        dataField: "gross_total_base",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>{`₹ ${number_format(cellContent)}`}</>
          );
        }
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          let status;
          if (row.status == 4) {
            status = <span className="status partially">Invoiced</span>;
          } else if (row.status == 3) {
            status = <span className="status pending">Viewed</span>;
          } else if (row.status == 2) {
            status = <span className="status pending">Mail Sent</span>;
          } else {
            status = <span className="status pending">Generated</span>;
          }
          return <>{status}</>;
        }
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className='table-button btns-inv'>
                <button className="round-btn" title="Actions" onClick={this.preventParentAction}>
                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a">
                      <EllipsisIcon />
                    </DropdownToggle>

                    <DropdownMenu>
                      {this.props.isPaginationRequire && (
                        <DropdownItem to="#" tag="span" onClick={(e: any) => this.downloadPdf(e, row)} ><PdfIcon /> Download Pdf</DropdownItem>
                      )}
                      <DropdownItem to="#" tag="span" onClick={(e: any) => this.send(e, row)} ><SentIcon /> Send</DropdownItem>
                      <a
                        target="_blank"
                        href={`https://api.whatsapp.com/send?text=Hello *${row.customer_name}*,%0a%0a Thanks for your business!%0a%0a Please find your Invoice ${this.state.proforma_invoice_prefix}${row.invoice_no} here,%0a ${process.env.REACT_APP_URL}/estimation-view/${this.state.instance_id}/${row.id}%0a%0a%0a Thanks%0a *${this.state.company_name}* %0a Sent using ${process.env.REACT_APP_NAME}(https://simblebooks.com/)`}
                        onClick={this.preventParentAction}
                      >
                        <DropdownItem to="#" tag="span"><WhatsappIcon /> Whatsapp</DropdownItem>
                      </a>
                      <Link to={"/proforma_edit/" + row.id} >
                        <DropdownItem to="#" tag="span" ><i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} /> View Pdf</DropdownItem>
                      </Link>
                      <DropdownItem to="#" tag="span" onClick={() => this.handleDelete(row)} ><i className="fas fa-trash-alt" data-toggle="tooltip" data-placement="bottom" title="Delete" style={{ color: '#dc3545' }} /> Delete</DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </button>
              </div>
            </>
          );
        }
      }
    ];

    const title = "Proforma";
    const breadcrumbItem = "Proforma List";

    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <>
            <CardTitle className="h4">
              <Skeleton height={"10%"} width={"10%"} />
            </CardTitle>
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <></>
        )}
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={this.state.data}
              search
            >
              {toolkitProps => (
                <React.Fragment>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          // responsive    
                          selectRow={{
                            mode: "radio",
                            hideSelectColumn: true,
                            clickToSelect: true,
                            // onSelect: this.selectRow
                          }}
                          remote
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap tbl-invoice table-mobile"}
                          headerWrapperClasses={"thead-light"}
                          onTableChange={this.handleTableChange}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          noDataIndication={() => <NoDataIndication />}
                        />
                      </div>
                    </Col>
                  </Row>

                  {this.props.isPaginationRequire && (
                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                          />
                          <PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  )
                  }

                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
        <ConfirmationModal
          isOpen={this.state.convertToInvoiceModal}
          message="Do you want to convert as invoice?"
          onConfirm={this.handleConvertToInvoiceConfirm}
          onClose={this.handleConvertToInvoiceClose}
        ></ConfirmationModal>
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        <EstimationDetailsPdf
          id={this.state.pdfId}
          companies={this.props.companies}
          close={this.downloadPdfclose}
        />
        <SendEstModel
          id={this.state.id}
          invoice_no={this.state.active_est_no}
          isOpen={this.state.modal}
          toggle={this.toggleSend}
          email={this.state.email}
        />
        {/* <EstimationDetailsModel id={this.state.id} isOpen={this.state.modalView} toggle={this.toggleView} instance_id={this.state.instance_id} email={this.state.email} /> */}
        {this.state.modalView == true && (
          <PerformaDetailsModel
            id={this.state.id}
            isOpen={this.state.modalView}
            toggle={this.toggleView}
          />
        )}
        {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        {this.state.modal_item_is_open == true && (
          <ProductCreateModalNew
            modal_pro_is_open={this.state.modal_item_is_open}
            openProductModal={this.openModalItem}
            closeProductModal={this.closeModalItem}
            parentCallback={this.parentCallbackItem}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: {
  customers: any;
  permissions: any;
  companies: any;
}) => {
  return {
    customers: state.customers.customers,
    permissions: state.permissions.permissions,
    companies: state.companies.companies,
  };
};
export default connect(mapStateToProps)(withRouter(PerformaAllMobile));
