import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Card, CardBody, Table, Modal } from "reactstrap";
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { post } from "src/helpers/api_helper";
import { number_format } from "src/helpers/common";
import VendorLedger from "../Vendors/VendorLedger";
import VendorInfoModal from "../Vendors/VendorInfoModal";

interface Params extends RouteComponentProps {
  id: string;
  isOpen: boolean;
  vendor_name: string;
  toggle: () => void;
  billing_address: string;
  invoice_no: string;
  invoice_date: Date | null;
  gstin: string;
  phone: string;
  email: string;
  pan: string;
  vendor_id: string;
  info: number;
}

type State = {
  isOpen: boolean;
  id: string;
  vendor_name: string;
  billing_address: string;
  invoice_no: string;
  invoice_date: string | null;
  details: Array<any>;
  tax_details: Array<any>;
  modelContact: boolean;
  phone: string;
  gstin: string;
  pan: string;
  email: string;
  modelLedger: boolean;
};
class ExpenseBillDetailsModel extends Component<Params, State> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "",
      isOpen: true,
      vendor_name: "",
      billing_address: "",
      invoice_no: "",
      invoice_date: null,
      details: [],
      tax_details: [],
      modelContact: false,
      phone: "",
      gstin: "",
      pan: "",
      email: "",
      modelLedger: false,
    };
  }
  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/expense_bill_details`,
          param
        );
        if (this._isMounted) {
          if (resp.success) {
            this.setState({
              details: resp.data,
              tax_details: resp.tax,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
  };
  loadData = async () => {
    try {
      if (this._isMounted) {
        let invoice_date = this.props.invoice_date
          ? moment(this.props.invoice_date).format("DD-MM-YYYY")
          : null;
        this.setState({
          id: this.props.id,
          isOpen: this.props.isOpen,
          vendor_name: this.props.vendor_name,
          billing_address: this.props.billing_address,
          invoice_no: this.props.invoice_no,
          invoice_date: invoice_date,
          phone: this.props.phone,
          gstin: this.props.gstin,
          pan: this.props.pan,
          email: this.props.email,
        });
        this.fetchData({
          id: this.props.id,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  calculateTotals = () => {
    let totalAmount = 0;
    let totalTax = 0;
    this.state.details.forEach(item => {
      totalAmount += parseFloat(item.taxable_amount) || 0;
    });
    this.state.tax_details.forEach(tax => {
      totalTax += parseFloat(tax.tax_amount) || 0;
    });
    return {
      totalAmount,
      total: totalAmount + totalTax,
    };
  };
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  ledgerOpen = () => {
    this.setState({ modelLedger: true });
  };
  ledgerClose = () => {
    this.setState({ modelLedger: false });
  };
  ContactOpen = () => {
    this.setState({ modelContact: true });
  };
  toggleInfo = () => {
    try {
      this.setState({ modelContact: !this.state.modelContact });
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    const { total, totalAmount } = this.calculateTotals();
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
        >
          <div className="modal-content">
            {/* <div className="modal-header px-2 py-1 border-0">
              <h5
                className="modal-title inv_header_h5"
                id="exampleModalLabel"
                style={{
                  textTransform: "uppercase",
                }}
              ></h5>
            </div> */}
            <div
              className="modal-body inv_modal_body"
              style={{ paddingBottom: 10 }}
            >
              <div className="border-0">
                <CardBody className="p-0">
                  <div className="header-info">
                    <p className="font-size-16 font-weight-500 m-0">
                      {this.state.vendor_name}
                    </p>
                    <p className="font-size-14 m-0">
                      {this.state.billing_address}
                    </p>
                    <p className="font-size-14 m-0">
                      Bill No: {this.state.invoice_no}
                    </p>
                    <p className="font-size-14 m-0">
                      Bill Date: {this.state.invoice_date}
                    </p>
                  </div>
                  <div className="mx-2">
                    <Table className="align-middle mb-0 detail_table mt-1">
                      <thead className="p-0">
                        <tr>
                          <th>Item</th>
                          <th style={{ textAlign: "center" }}>Qty</th>
                          <th style={{ textAlign: "center" }}>Rate</th>
                          <th style={{ textAlign: "center" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody className="p-0">
                        {this.state.details.map(
                          (
                            statement: {
                              item_name: string;
                              qty: number;
                              rate: number;
                              taxable_amount: number;
                            },
                            index: number
                          ) => (
                            <tr key={index}>
                              <td>{statement.item_name}</td>
                              <td style={{ textAlign: "center" }}>
                                {statement.qty}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.rate)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.taxable_amount)}
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            {number_format(totalAmount)}
                          </td>
                        </tr>
                        {this.state.tax_details.map(
                          (
                            statement: {
                              label: string;
                              tax_rate: string;
                              tax_amount: number;
                            },
                            index: number
                          ) => (
                            <tr key={index}>
                              <td>
                                {statement.label}({statement.tax_rate})
                              </td>
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.tax_amount)}
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td>Gross Total(INR)</td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            {number_format(total)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </div>
              {/* <div>
                <button
                  onClick={this.props.toggle}
                  type="button"
                  className="close"
                  aria-label="Close"
                  style={{ fontSize: "14px", top: "-6px" }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div> */}
              <div className="inv_sec_2 d-flex">
                <div
                  className="text-center w-100"
                  style={{ textAlign: "center", display: "block" }}
                >
                  <Button
                    type="button"
                    className="bmt-btn-close inv-info"
                    onClick={() => this.props.toggle()}
                  >
                    Close
                  </Button>
                  {this.props.info == 1 ? (
                    <>
                  <Button
                    type="submit"
                    className="bmt-btn-submit btn-sm inv-info"
                    onClick={() => this.ContactOpen()}
                  >
                    Contact
                  </Button>
                  <Button
                    type="submit"
                    className="bmt-btn-submit btn-sm inv-info"
                    onClick={() => this.ledgerOpen()}
                  >
                    View Ledger
                  </Button>
                  </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <VendorInfoModal
          phone={this.state.phone}
          gstin={this.state.gstin}
          pan={this.state.pan}
          isOpen={this.state.modelContact}
          toggle={this.toggleInfo}
          email={this.state.email}
        />
        {this.state.modelLedger && (
          <VendorLedger
            openModal={this.ledgerOpen}
            modal_is_open={this.state.modelLedger}
            closeModal={this.ledgerClose}
            vendor_name={this.state.vendor_name}
            vendor_id={this.props.vendor_id}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ExpenseBillDetailsModel);
