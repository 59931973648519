import { call, put, takeEvery } from "redux-saga/effects";
import { initalData } from "src/store/sagas";
import { RegisterTypes } from "./actionTypes";
import { registerUserFailed, registerUserSuccessful } from "./actions";
import { isValidToken } from "src/helpers/api_helper";

function* registerUser({ payload: { user, history } }: any) {
  try {
    if (isValidToken()) {
      yield put(registerUserSuccessful(user));
      history.push("/invoices");
    }
  } catch (error) {
    yield put(registerUserFailed("Registration failed"));
  }
}

function* regisrterSuccessUser() {
  try {
    yield call(initalData);
  } catch (error) {
    console.error("Initial data loading failed:", error);
  }
}

function* registerSaga() {
  yield takeEvery(RegisterTypes.REGISTER_USER, registerUser);
  yield takeEvery(RegisterTypes.REGISTER_USER_SUCCESSFUL, regisrterSuccessUser);
}

export default registerSaga;
