import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  BreadcrumbItem,
} from "reactstrap";

import "../Datatable/datatables.scss";
import classnames from "classnames";
import InvoiceListAll from "./InvoiceListAll";
import InvoiceListUnpaid from "./InvoiceListUnpaid";
import InvoiceListPaid from "./InvoiceListPaid";
import InvoiceListDelete from "./InvoiceListDelete";
import Select, { components } from "react-select";
import {
  ActionMeta,
  ControlProps,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MetaTags from "react-meta-tags";
import ExpenseIcon from "../../assets/images/icons/Expense";
import Estimate2Icon from "../../assets/images/icons/Estimate2";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import RenewPlan from "../Payments/RenewPlan";
import InvoiceDetailsPdf from "./InvoiceDetailsPdf";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";
import MediaQuery from "../Media";
import { number_format } from "src/helpers/common";
import InvoiceListAllMobile from "./InvoiceListAllMobile";
import InvoiceListPaidMobile from "./InvoiceListPaidMobile";
import InvoiceListUnpaidMobile from "./InvoiceListUnpaidMobile";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { predefinedRanges } from "src/helpers/common";
import CompanyForm from "../GST/CompanyForm";
import { getCompanies } from "src/store/actions";
import InvoiceListDeleteMobile from "./InvoiceListDeleteMobile";

interface MyProps extends RouteComponentProps<any> {
  getCompanies: () => void;
  customers: Array<any>;
  permissions: Array<any>;
  companies: Company;
}
interface Company {
  company_state: string | undefined;
}

interface MyState {
  activeTab: number;
  totalAll: number;
  totalAllPaid: number;
  totalAllUnpaid: number;
  countAll: number;
  countAllPaid: number;
  countAllUnpaid: number;
  countAllDeleted: number;
  permissions: Array<any>;
  isLoading: boolean;
  isLoadingTab: boolean;
  valueCustomer: Option | null | undefined;
  valueTab: Option | null | undefined;
  options: Option[];
  optionsTab: Option[];
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  loading: boolean;
  isLoadedThis: boolean;
  isLoadedTabAll: boolean;
  isLoadedTabPaid: boolean;
  isLoadedTabUnpaid: boolean;
  isLoadedTabDelete: boolean;
  isPlanExpired: number;
  modalPlan: boolean;
  pdfId: string;
  isDownloadPdf: boolean;
  modal_cust_is_open: boolean;
  modal_item_is_open: boolean;
  refresh: boolean;
  verifyCompanyData: boolean;
  mouseTab: number;
  tax_amount: number;
  rounded_amount: number;
  total_taxable_amount: number;
  partially_paid: number;
  total_paid: number;
  unpaid: number;
  un_partially_paid: number;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});

const Control = ({ children, ...props }: ControlProps<Option, false>) => {  
  return (
    <components.Control {...props}>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="30"
          viewBox="0 0 32.815 49.055"
        >
          <path
            id="Path_441"
            data-name="Path 441"
            d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
            transform="translate(-1.415 49.055)"
          />
        </svg>
      </span>
      {children}
    </components.Control>
  );
};

class InvoiceList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      activeTab: 1,
      totalAll: 0.0,
      totalAllPaid: 0.0,
      totalAllUnpaid: 0.0,
      countAll: 0,
      countAllPaid: 0,
      countAllUnpaid: 0,
      countAllDeleted: 0,
      permissions: [],
      isLoading: true,
      isLoadingTab: true,
      valueCustomer: null,
      valueTab: null,
      options: [],
      optionsTab: [],
      customers: [],
      startDate: null,
      endDate: null,
      loading: true,
      isLoadedThis: false,
      isLoadedTabAll: false,
      isLoadedTabPaid: false,
      isLoadedTabUnpaid: false,
      isLoadedTabDelete: false,
      isPlanExpired: 0,
      pdfId: "",
      isDownloadPdf: false,
      modalPlan: false,
      modal_cust_is_open: false,
      modal_item_is_open: false,
      refresh: false,
      verifyCompanyData: false,
      mouseTab: 0,
      tax_amount: 0,
      rounded_amount: 0,
      total_taxable_amount: 0,
      partially_paid: 0,
      total_paid: 0,
      unpaid: 0,
      un_partially_paid: 0,
    };
  }
  setRefresh = (param: any) => {
    if (param == true) {
      this.setState({ refresh: !this.state.refresh });
    }
  };
  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  toggleTab = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };
  MouseEnter = (tab: any) => {
    this.setState({ mouseTab: tab });
  };
  MouseLeave = () => {
    this.setState({ mouseTab: 0 });
  };

  setDownloadpdf = (id: string) => {
    this.setState({ isDownloadPdf: true, pdfId: id });
    // console.log('setDownloadpdf');
  };

  isDownloadCompleted = () => {
    this.setState({ isDownloadPdf: false, pdfId: "" });
    // console.log('isDownloadCompleted');
  };
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
  };
  handleChangeTab = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueTab: newValue });
    this.toggleTab(Number(newValue?.value));
  };

  setTotalAmount = async (param: {}) => {
    this.setState(param);
    var loading = false;
    if (
      this.state.isLoadedThis &&
      this.state.isLoadedTabAll &&
      this.state.isLoadedTabPaid &&
      this.state.isLoadedTabUnpaid &&
      this.state.isLoadedTabDelete
    ) {
      loading = true;
    }

    let optionsTab = [
      { value: '1', label: `TOTAL(${this.state.countAll})\n ${number_format(this.state.totalAll)}` },
      { value: '2', label: `PAID(${this.state.countAllPaid})\n ${number_format(this.state.totalAllPaid)}` },
      { value: '3', label: `UNPAID(${this.state.countAllUnpaid})\n ${number_format(this.state.totalAllUnpaid)}` },
      { value: '4', label: `DELETED` },
    ];
    let valueTab = optionsTab[0];
    this.setState({ loading, optionsTab, valueTab });
  };

  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
  }
  setStartDateNew(dateRange: any) {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState({ startDate: startDate, endDate: endDate });
  }
  isPlanExpired = async (e: any, location: string) => {
    try {
      if (this.state.isPlanExpired) {
        this.props.history.push(location);
      } else {
        this.openModalPlan();
      }
    } catch (err) {
      console.error(err);
    }
  };

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };
  getCompanies = () => {
    this.props.getCompanies();
  };
  loadData = async () => {
    try {
      const respCom = this.props.companies;
      if (!respCom) {
        if (this._isMounted) this.setState({ verifyCompanyData: false });
      } else if (respCom.company_state === null) {
        if (this._isMounted) this.setState({ verifyCompanyData: true });
      } else {
        if (this._isMounted) this.setState({ verifyCompanyData: false });
      }

      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      if (this._isMounted) this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map((customer) => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      if (this._isMounted) this.setState({ customers: customers, options: customerOptions });

      let invoicePermissions = this.props.permissions.filter(
        (permission) =>
          permission.module_permission_id === PermissionModule.INVOICE
      );
      if (this._isMounted) this.setState({ permissions: invoicePermissions });

      var loading = false;
      if (
        this.state.isLoadedThis &&
        this.state.isLoadedTabAll &&
        this.state.isLoadedTabPaid &&
        this.state.isLoadedTabUnpaid &&
        this.state.isLoadedTabDelete
      ) {
        loading = true;
      }

      let optionsTab = [
        { value: '1', label: `TOTAL(${this.state.countAll})\n ${number_format(this.state.totalAll)}` },
        { value: '2', label: `PAID(${this.state.countAllPaid})\n ${number_format(this.state.totalAllPaid)}` },
        { value: '3', label: `UNPAID(${this.state.countAllUnpaid})\n ${number_format(this.state.totalAllUnpaid)}` },
        { value: '4', label: `DELETED` },
      ];
      let valueTab = optionsTab[0];

      if (this._isMounted) {
        this.setState({
          isLoading: false,
          isLoadingTab: false,
          isLoadedThis: true,
          loading,
          optionsTab,
          valueTab,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  componentWillReceiveProps(props: MyProps) {
    this.loadData();
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  render() {
    const title = "Invoices";
    const breadcrumbItem = "Invoices List";
    return (
      <React.Fragment>
        <div className="page-content" style={{ minHeight: "685px" }}>
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header custom-1">
                    {" "}
                    <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={6}>
                            {this.state.loading == true ? (
                              <Skeleton width={"100%"} height={"50%"} />
                            ) : (
                              <Select
                              styles={{
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                  backgroundColor: state.isSelected
                                    ? "#10a37f"
                                    : "inherit",
                                }),
                              }}
                                isClearable={true}
                                isDisabled={this.state.isLoading}
                                isLoading={this.state.isLoading}
                                onChange={this.handleChange}
                                options={this.state.options}
                                value={this.state.valueCustomer}
                                name="customer"
                                placeholder="Customer"
                              />
                            )}
                          </Col>
                          <Col lg={6}>
                            {this.state.loading == true ? (
                              <Skeleton width={"100%"} height={"50%"} />
                            ) : (
                              <>                               
                                <DateRangePicker
                                  ranges={predefinedRanges}
                                  format="dd/MM/yyyy"
                                  placeholder=" "
                                  
                                  onChange={(date: DateRange | null) =>
                                    this.setStartDateNew(date)
                                  }
                                />
                              </>
                            )}
                          </Col>
                          {/* this is display only in mobile */}
                          <MediaQuery
                            mobile={
                              <Col lg={6} className="">
                                {this.state.loading == true ? (
                                  <Skeleton width={"100%"} height={"50%"} />
                                ) : (
                                  <Select
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={this.state.isLoadingTab}
                                    isLoading={this.state.isLoadingTab}
                                    onChange={this.handleChangeTab}
                                    options={this.state.optionsTab}
                                    value={this.state.valueTab}
                                    name="tab"
                                    styles={{
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                        backgroundColor: state.isSelected
                                          ? "#10a37f"
                                          : "inherit",
                                      }),
                                    }}
                                    placeholder="Tab"
                                    components={{ Control }}
                                    className="react-select-container tab-cstm-drop"
                                    classNamePrefix="react-select"
                                  />
                                )}
                              </Col>
                            }
                            desktop={<></>}
                          />

                        </Row>
                      </AvForm>
                    </div>
                  </div>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader className="cus-card">
                    <div className="row">
                      <div className="col-md-12 col-xl-7 order-xl-1">
                        {/* this is display only in desktop */}
                        <MediaQuery
                          desktop={
                            <>
                              <ul className="values-inv">
                                {this.state.loading == true ? (
                                  <>
                                    <li>
                                      <Skeleton width={"30%"} height={"100%"} />
                                    </li>
                                    <li>
                                      <Skeleton width={"30%"} height={"100%"} />
                                    </li>
                                    <li>
                                      <Skeleton width={"30%"} height={"100%"} />
                                    </li>
                                    <li>
                                      <Skeleton width={"10%"} height={"100%"} />
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li
                                      className={
                                        this.state.activeTab == 1 ? "active" : ""
                                      }
                                    >
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab == 1,
                                        })}
                                        onClick={() => {
                                          this.toggleTab(1);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="32.815"
                                          height="49.055"
                                          viewBox="0 0 32.815 49.055"
                                        >
                                          <path
                                            id="Path_441"
                                            data-name="Path 441"
                                            d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                            transform="translate(-1.415 49.055)"
                                          />
                                        </svg>

                                        <span>
                                          {number_format(this.state.totalAll)}
                                        </span>
                                        <label>Total<i className="bx bx-message-square-dots comment_icon" onMouseEnter={()=>{this.MouseEnter(1);}}  onMouseLeave={this.MouseLeave}></i></label>
                                        {this.state.mouseTab == 1 && (                                         
                                          <div className="popup-content" onMouseEnter={() =>{this.MouseEnter(1);}} onMouseLeave={this.MouseLeave}>
                                            <table className="popup-table">
                                                    <tr>
                                                        <td className="fixed-width">Taxable Amount</td>
                                                        <td className="px-1"> : </td>
                                                        <td className="text-end">{number_format(this.state.total_taxable_amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fixed-width">Tax Amount</td>
                                                        <td className="px-1"> : </td>
                                                        <td className="text-end">{number_format(this.state.tax_amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fixed-width">Round Off Amount</td>
                                                        <td className="px-1"> : </td>
                                                        <td className="text-end">{number_format(this.state.rounded_amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fixed-width">Total Amount</td>
                                                        <td className="px-1"> : </td>
                                                        <td className="text-end">{number_format(this.state.totalAll)}</td>
                                                    </tr>
                                            </table>
                                        </div>
                                        )}
                                      </NavLink>
                                    </li>
                                    <li
                                      className={
                                        this.state.activeTab == 2 ? "active" : ""
                                      }
                                    >
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab == 2,
                                        })}
                                        onClick={() => {
                                          this.toggleTab(2);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="32.815"
                                          height="49.055"
                                          viewBox="0 0 32.815 49.055"
                                        >
                                          <path
                                            id="Path_441"
                                            data-name="Path 441"
                                            d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                            transform="translate(-1.415 49.055)"
                                          />
                                        </svg>

                                        <span>
                                          {number_format(this.state.total_paid)}
                                        </span>
                                        <label>Paid<i className="bx bx-message-square-dots comment_icon" onMouseEnter={()=>{this.MouseEnter(2);}}  onMouseLeave={this.MouseLeave}></i></label>
                                        {this.state.mouseTab == 2 && (
                                          <div className="popup-content" onMouseEnter={()=>{this.MouseEnter(2);}}  onMouseLeave={this.MouseLeave}>
                                            <table className="popup-table">
                                              <tr>
                                                <td className="fixed-width">Paid Invoice</td>
                                                <td className="px-1"> : </td>
                                                <td className="text-end">{number_format(this.state.totalAllPaid)}</td>
                                              </tr>
                                              <tr>
                                                <td className="fixed-width">Partially paid</td>
                                                <td className="px-1"> : </td>
                                                <td className="text-end">{number_format(this.state.partially_paid)}</td>
                                              </tr>
                                              <tr>
                                                <td className="fixed-width">Total Amount</td>
                                                <td className="px-1"> : </td>
                                                <td className="text-end">{number_format(this.state.total_paid)}</td>
                                              </tr>
                                            </table>
                                          </div>
                                        )}
                                      </NavLink>
                                    </li>
                                    <li
                                      className={
                                        this.state.activeTab == 3 ? "active" : ""
                                      }
                                    >
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab == 3,
                                        })}
                                        onClick={() => {
                                          this.toggleTab(3);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="32.815"
                                          height="49.055"
                                          viewBox="0 0 32.815 49.055"
                                        >
                                          <path
                                            id="Path_441"
                                            data-name="Path 441"
                                            d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                            transform="translate(-1.415 49.055)"
                                          />
                                        </svg>

                                        <span>
                                          {number_format(this.state.totalAllUnpaid)}
                                        </span>
                                        <label>Unpaid<i className="bx bx-message-square-dots comment_icon" onMouseEnter={()=>{this.MouseEnter(3);}}  onMouseLeave={this.MouseLeave}></i></label>
                                        {this.state.mouseTab == 3 && (
                                          <div className="popup-content" onMouseEnter={()=>{this.MouseEnter(3);}}  onMouseLeave={this.MouseLeave}>
                                            <table className="popup-table">
                                              <tr>
                                                <td className="fixed-width">Partially Unpaid</td>
                                                <td className="px-1"> : </td>
                                                <td className="text-end">{number_format(this.state.un_partially_paid)}</td>
                                              </tr>
                                              <tr>
                                                <td className="fixed-width">Unpaid Invoice</td>
                                                <td className="px-1"> : </td>
                                                <td className="text-end">{number_format(this.state.unpaid)}</td>
                                              </tr>
                                              <tr>
                                                <td className="fixed-width">Total Amount</td>
                                                <td className="px-1"> : </td>
                                                <td className="text-end">{number_format(this.state.totalAllUnpaid)}</td>
                                              </tr>
                                            </table>
                                          </div>
                                        )}
                                      </NavLink>
                                    </li>
                                    <li
                                      className={
                                        this.state.activeTab == 4 ? "active" : ""
                                      }
                                    >
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab == 4,
                                        })}
                                        onClick={() => {
                                          this.toggleTab(4);
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash-alt"
                                          style={{ color: "#fff" }}
                                        ></i>
                                        <span>&nbsp;</span>
                                        <label>&nbsp;</label>
                                      </NavLink>
                                    </li>
                                  </>
                                )}
                              </ul>
                              <ul className="new-inv-count">
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="31"
                                      height="31"
                                      viewBox="0 0 31 31"
                                    >
                                      <g
                                        id="Group_12"
                                        data-name="Group 12"
                                        transform="translate(-326 -668)"
                                      >
                                        <circle
                                          id="Ellipse_5"
                                          data-name="Ellipse 5"
                                          cx="15.5"
                                          cy="15.5"
                                          r="15.5"
                                          transform="translate(326 668)"
                                          fill="#3296cd"
                                        />
                                        <path
                                          id="Path_10"
                                          data-name="Path 10"
                                          d="M3728.683,669l8.305,6.207,11.513-16.287"
                                          transform="translate(-3395.042 13.241)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeWidth="4"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  {this.state.countAll} Invoices
                                </li>
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="31"
                                      height="31"
                                      viewBox="0 0 31 31"
                                    >
                                      <g
                                        id="Group_12"
                                        data-name="Group 12"
                                        transform="translate(-326 -668)"
                                      >
                                        <circle
                                          id="Ellipse_5"
                                          data-name="Ellipse 5"
                                          cx="15.5"
                                          cy="15.5"
                                          r="15.5"
                                          transform="translate(326 668)"
                                          fill="#b4b0ad"
                                        />
                                        <path
                                          id="Path_10"
                                          data-name="Path 10"
                                          d="M3728.683,669l8.305,6.207,11.513-16.287"
                                          transform="translate(-3395.042 13.241)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeWidth="4"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <span>{this.state.countAllPaid} Invoices</span>
                                </li>
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="31"
                                      height="31"
                                      viewBox="0 0 31 31"
                                    >
                                      <g
                                        id="Group_12"
                                        data-name="Group 12"
                                        transform="translate(-326 -668)"
                                      >
                                        <circle
                                          id="Ellipse_5"
                                          data-name="Ellipse 5"
                                          cx="15.5"
                                          cy="15.5"
                                          r="15.5"
                                          transform="translate(326 668)"
                                          fill="#43aa77"
                                        />
                                        <path
                                          id="Path_10"
                                          data-name="Path 10"
                                          d="M3728.683,669l8.305,6.207,11.513-16.287"
                                          transform="translate(-3395.042 13.241)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeWidth="4"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  {this.state.countAllUnpaid} Invoices
                                </li>
                              </ul></>
                          }
                          mobile={<></>}
                        />
                      </div>
                      <div className="col-md-12 col-xl-5 order-xl-1">
                        {this.state.permissions.find(
                          ({ action, is_active }) =>
                            action == "create" && is_active == 1
                        ) && (
                            <ul className="qck-link1">
                              <li>
                                <Link
                                /* to="/product_add" */ to="#"
                                  onClick={() => this.openModalItem()}
                                >
                                  <span>
                                    <ExpenseIcon />
                                  </span>
                                  <label>Add Item</label>
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => this.openCustModal()}>
                                  <span>
                                    <Addcustomer2Icon />
                                  </span>
                                  <label>Add Customer</label>
                                </Link>
                              </li>
                              <li>
                                <Link to="/estimation_add">
                                  <span>
                                    <Estimate2Icon />
                                  </span>
                                  <label>New Estimate</label>
                                </Link>
                              </li>

                              <li>
                                <Link to="/invoice_add">
                                  <span>
                                    <Invoice2Icon />
                                  </span>
                                  <label>New Invoice</label>
                                </Link>
                              </li>
                            </ul>
                          )}
                      </div>
                    </div>
                  </CardHeader>

                  <CardBody className="pt-0">
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="text-muted"
                    >
                      <TabPane tabId={1}>
                        <Row>
                          <Col sm="12">
                            <MediaQuery
                              mobile={
                                <InvoiceListAllMobile
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                />
                              }
                              desktop={
                                <InvoiceListAll
                                  key={1}
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Row>
                          <Col sm="12">
                            <MediaQuery
                              mobile={
                                <InvoiceListPaidMobile
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                />
                              }
                              desktop={
                                <InvoiceListPaid
                                  key={2}
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={3}>
                        <Row>
                          <Col sm="12">
                            <MediaQuery
                              mobile={
                                <InvoiceListUnpaidMobile
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                />
                              }
                              desktop={
                                <InvoiceListUnpaid
                                  key={3}
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId={4}>
                        <Row>
                          <Col sm="12">
                            <MediaQuery
                              mobile={
                                <InvoiceListDeleteMobile
                                  key={4}
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}                                  
                                />
                              }
                              desktop={
                                <InvoiceListDelete
                                  key={4}
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.verifyCompanyData == true && (
          <CompanyForm
            isOpen={this.state.verifyCompanyData}
            getCompanies={this.getCompanies}
          />
        )}
        <InvoiceDetailsPdf
          id={this.state.pdfId}
          isDownload={this.state.isDownloadPdf}
          isDownloadCompleted={this.isDownloadCompleted}
        />
        {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        {this.state.modal_item_is_open == true && (
          <ProductCreateModalNew
            modal_pro_is_open={this.state.modal_item_is_open}
            openProductModal={this.openModalItem}
            closeProductModal={this.closeModalItem}
            parentCallback={this.parentCallbackItem}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCompanies: () => {
      dispatch(getCompanies());
    },
  };
};
const mapStateToProps = (state: {
  customers: any;
  permissions: any;
  companies: any;
}) => {
  return {
    customers: state.customers.customers,
    permissions: state.permissions.permissions,
    companies: state.companies.companies,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceList));
