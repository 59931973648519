import {CurrenciesTypes} from "./actionTypes"

export const getCurrencies = () => ({
  type: CurrenciesTypes.GET_CURRENCIES,
})

export const getCurrenciesSuccess = (currencies : any) => ({
  type: CurrenciesTypes.GET_CURRENCIES_SUCCESS,
  payload: currencies,
})

export const getCurrenciesFail = (error : any) => ({
  type: CurrenciesTypes.GET_CURRENCIES_FAIL,
  payload: error,
})



