import React from 'react';

const SentIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 28 28">
    <g id="sent" transform="translate(-1064 -380)">
      <rect id="Rectangle_1" data-name="Rectangle 1" width="32" height="32" transform="translate(1064 380)" fill="#ffd6d6" opacity="0"/>
      <path id="envelope-solid" d="M16.952,64A1.754,1.754,0,0,1,18,67.157l-7.95,5.962a1.173,1.173,0,0,1-1.4,0L.7,67.157A1.754,1.754,0,0,1,1.754,64Zm-9,10.054a2.334,2.334,0,0,0,2.806,0l7.95-5.962v7.6a2.34,2.34,0,0,1-2.338,2.338H2.338A2.339,2.339,0,0,1,0,75.691v-7.6Z" transform="translate(1069 323)"/>
    </g>
  </svg>
  
  );
}

export default SentIcon;
