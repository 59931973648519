import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, UncontrolledAlert, ModalFooter, Button, Table } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, post, get } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import RecordPayment from "../Payments/RecordPayment";
import ViewIcon from "src/assets/images/icons/View";
import SentIcon from "src/assets/images/icons/Sent";
import ConvertIcon from "src/assets/images/icons/Convert";
import RecordIcon from "src/assets/images/icons/Record";
import ActionsIcon from "src/assets/images/icons/Actions";
import adbanner from "../../assets/images/ad.png";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";
import FilterIcon from "src/assets/images/icons/Filter";

// Import Editor
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SendInvModel from "./SendInvModel";
import InvoiceDetailsModel from "./InvoiceDetailsModel";
import InvoiceDetailsPdf from "./InvoiceDetailsPdf";
import PdfIcon from "src/assets/images/icons/Pdf";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { getLetters, number_format } from "src/helpers/common";
import Tooltip from "src/helpers/tooltip/Tooltip";

interface MyProps extends RouteComponentProps<any> {
    valueCustomer?: Option | null | undefined;
    startDate?: Date | null;
    endDate?: Date | null;
    setTotalAmount: ({ }) => void;
    isPaginationRequire?: boolean;
    setDownloadpdf: (id: string) => void;
}

interface MyState {
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    invoice_prefix: string;
    isLoading: boolean;
    options: Option[];
    valueCustomer: Option | null | undefined;
    customers: Array<any>;
    startDate: Date | null;
    endDate: Date | null;
    modal_is_open: boolean;
    active_inv_id: string;
    active_cust_name: string;
    active_invoice_no: string;
    active_invoice_amount: number;
    modal: boolean;
    isSending: boolean;
    editorState: EditorState;
    isHideSuccessMgs: boolean;
    isHideErrorMgs: boolean;
    email: string;
    loading: boolean;
    instance_id: string;
    modalView: boolean;
    company_name: string;
    pdfId: string;
    active_inv_no: string;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

interface Option {
    label: string;
    value: string;
}

const createOption = (label: string, value: string) => ({
    label,
    value,
});


class InvoiceListAllMobile extends Component<MyProps, MyState> {
    static defaultProps: {
        isPaginationRequire: boolean;
    };
    constructor(props: any) {
        super(props);
        this.state = {
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "invoice_no",
            sortOrder: "desc",
            invoice_prefix: "",
            isLoading: true,
            options: [],
            valueCustomer: null,
            customers: [],
            startDate: null,
            endDate: null,
            modal_is_open: false,
            active_inv_id: "",
            active_cust_name: "",
            active_invoice_no: "",
            active_invoice_amount: 0,
            modal: false,
            isSending: false,
            editorState: EditorState.createEmpty(),
            isHideSuccessMgs: true,
            isHideErrorMgs: true,
            email: "",
            loading: true,
            instance_id: '',
            modalView: false,
            pdfId: '',
            company_name: '',
            active_inv_no: ''
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {

                const resp = await post(process.env.REACT_APP_API_URL + `/api/invoice_list_grid`, param);

                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        data: resp.data.results,
                        invoice_prefix: resp.data.invoice_prefix,
                        instance_id: resp.data.instance_id,
                        company_name: resp.data.company_name
                    });
                    this.props.setTotalAmount({ totalAll: resp.data.total, countAll: resp.data.count, isLoadedTabAll: true });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }


        };

        sendGetRequest();
    }

    handleTableChange = (type: string, param: ChangeProp) => {
        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }

    handleDelete = (row: any) => {
        const sendGetRequest = async () => {
            try {

                const conf = window.confirm("Do you want to delete this record?");
                if (conf) {
                    const resp = del(process.env.REACT_APP_API_URL + '/api/invoice_delete/' + row.id);

                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    recordPayment = (event: any, row: { id: string, customer_name: string, invoice_no: number, gross_total_base: number }) => {
        event.stopPropagation();
        this.setState({ active_inv_id: row.id, active_cust_name: row.customer_name, active_invoice_no: this.state.invoice_prefix + row.invoice_no, active_invoice_amount: row.gross_total_base });
        this.openModal();
    }
    openModal = () => {
        this.setState({ modal_is_open: true });
    }

    closeModal = () => {
        this.setState({ modal_is_open: false });
    }

    send = async (event: any, row: any) => {
        try {
            event.stopPropagation();
            let inv_no = this.state.invoice_prefix ? this.state.invoice_prefix + row.invoice_no : "INV" + row.invoice_no;
            this.setState({ active_inv_id: row.id, active_inv_no: inv_no, email: row.email, modal: !this.state.modal });
        } catch (err) {
            console.error(err);
        }
    }

    view = async (row: any) => {
        try {
            this.setState({ active_inv_id: row.id, modalView: !this.state.modalView });
        } catch (err) {
            console.error(err);
        }
    }

    toggleView = () => {
        try {
            this.setState({ modalView: !this.state.modalView });
        } catch (err) {
            console.error(err);
        }
    }

    toggleSend = () => {
        try {
            this.setState({ modal: !this.state.modal });
        } catch (err) {
            console.error(err);
        }
    }

    padLeadingZeros(num: number, size: number) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    componentDidUpdate(prevProps: MyProps) {
        if (this.props.valueCustomer !== prevProps.valueCustomer || this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            let startDate = this.props.startDate ? moment(this.props.startDate).format("YYYY-MM-DD") : null;
            let endDate = this.props.endDate ? moment(this.props.endDate).format("YYYY-MM-DD") : null;
            this.fetchData({ page: 1, searchText: "", customer: this.props.valueCustomer?.value, startDate: startDate, endDate: endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
        }
    }

    handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        this.setState({ valueCustomer: newValue });
        this.fetchData({ page: 1, searchText: "", customer: newValue?.value, startDate: this.state.startDate, endDate: this.state.endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    };

    async componentDidMount() {
        try {
            this.setState({ loading: false });
        } catch (err) {
            console.error(err);
        }
    }
    setStartDate(dateRange: Array<any>) {
        const [startDate, endDate] = dateRange;
        this.setState({ startDate: startDate, endDate: endDate });
        this.fetchData({ page: 1, searchText: "", customer: this.state.valueCustomer?.value, startDate: startDate, endDate: endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    onEditorStateChange = (editorState: EditorState) => {
        this.setState({
            editorState,
        });
    }
    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isSending: true });
        let content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        values["content"] = content;
        values["code"] = this.state.active_inv_id;
        const resp = await post(process.env.REACT_APP_API_URL + '/api/invoice_sent', values);
        if (resp.success) {
            this.setState({ isHideSuccessMgs: false });
            setTimeout(() => {
                this.setState({ modal: false });
            }, 2000);

        } else {
            this.setState({ isHideErrorMgs: false });
        }
        this.setState({ isSending: false });
    }
    selectRow = (row: any, isSelect: any, rowIndex: any) => {
        this.view(row);
    };
    preventParentAction = (event: any) => {
        event.stopPropagation();
    }
    downloadPdf = (event: any, row: any) => {
        event.stopPropagation();
        this.setState({ pdfId: row.id });
        this.props.setDownloadpdf(row.id);
    }
    handleRefresh = () => {
        console.log('handleRefresh all');
    }
    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize,
            custom: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            {
                dataField: "customer_name",
                text: "Customer",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <Tooltip content={cellContent} direction="right">
                        <span title={cellContent} >
                            {getLetters(cellContent)}                            
                        </span>
                        </Tooltip>
                    )
                }
            },
            {
                dataField: "invoice_no",
                text: "Number",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>
                            {row.prefix ? row.prefix + row.invoice_no : "INV" + row.invoice_no}
                            <br />
                            {moment(row.invoice_date).format("DD/MM/YYYY")}
                        </>
                    );
                }
            },
            {
                dataField: "gross_total_base",
                text: "Amount",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>{`₹ ${number_format(cellContent)}`}</>
                    );
                }
            },
            {
                dataField: "status",
                text: "Status",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    let due_date = moment(row.due_date);
                    let cur_date = moment();
                    let days = cur_date.diff(due_date, 'days');
                    let status;
                    if (row.status == 4) {
                        status = <><span className="status sent">Partially Paid</span><label className="status-mess">{days} days due</label></>
                    } else if (row.status == 5) {
                        status = <span className="status partially">Paid</span>
                    } else {
                        status = <><span className="status pending">Unpaid</span><label className="status-mess">{days} days due</label></>
                    }
                    return (
                        <>
                            {status}
                        </>
                    );
                }
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>
                            <div className='table-button btns-inv'>
                                <button className="round-btn" title="Actions" onClick={this.preventParentAction}>
                                    <UncontrolledDropdown className="mt-4 mt-sm-0">
                                        <DropdownToggle tag="a">
                                            <EllipsisIcon />
                                        </DropdownToggle>

                                        <DropdownMenu>
                                            {this.props.isPaginationRequire && (
                                                <DropdownItem to="#" tag="span" onClick={(e: any) => this.downloadPdf(e, row)} ><PdfIcon /> Download Pdf</DropdownItem>
                                            )}
                                            <DropdownItem to="#" tag="span" onClick={(e: any) => this.send(e, row)} ><SentIcon /> Send</DropdownItem>
                                            <a target="_blank" href={`https://api.whatsapp.com/send?text=Hello *${row.customer_name}*,%0a%0a Thanks for your business!%0a%0a Please find your Invoice ${this.state.invoice_prefix}${row.invoice_no} here,%0a ${process.env.REACT_APP_URL}/invoice-view/${this.state.instance_id}/${row.id}%0a%0a%0a Thanks%0a *${this.state.company_name}* %0a Sent using ${process.env.REACT_APP_NAME}(https://simblebooks.com/)`} onClick={this.preventParentAction}>
                                                <DropdownItem to="#" tag="span"><WhatsappIcon /> Whatsapp</DropdownItem>
                                            </a>
                                            <Link to={"/invoice_edit/" + row.id} >
                                                <DropdownItem to="#" tag="span" ><i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} /> View Pdf</DropdownItem>
                                            </Link>
                                            <DropdownItem to="#" tag="span" onClick={() => this.handleDelete(row)} ><i className="fas fa-trash-alt" data-toggle="tooltip" data-placement="bottom" title="Delete" style={{ color: '#dc3545' }} /> Delete</DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </button>
                            </div>
                        </>
                    );
                }
            }
        ];

        return (
            <React.Fragment>
                {this.state.loading == true ? (
                    <Table className="table mb-0">
                        <thead>
                            <tr>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Skeleton height={20} /></td>
                                <td><Skeleton height={20} /></td>
                                <td><Skeleton height={20} /></td>
                                <td><Skeleton height={20} /></td>
                                <td><Skeleton height={20} /></td>
                                <td><Skeleton height={20} /></td>
                            </tr>
                        </tbody>
                    </Table>
                ) : (
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={this.state.data}
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>
                                        {/* <Row className="mb-2">
                                        <Col md="3">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <Select
                                                        isClearable={true}
                                                        isDisabled={this.state.isLoading}
                                                        isLoading={this.state.isLoading}
                                                        onChange={this.handleChange}
                                                        options={this.state.options}
                                                        value={this.state.valueCustomer}
                                                        name="customer"
                                                    />                                                    
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={(date: Array<any>) => this.setStartDate(date)}
                                                selectsRange={true}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                                isClearable={true}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                todayButton="Today"
                                            />
                                        </Col>
                                    </Row> */}

                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        // responsive    
                                                        selectRow={{
                                                            mode: "radio",
                                                            hideSelectColumn: true,
                                                            clickToSelect: true,
                                                            // onSelect: this.selectRow
                                                        }}
                                                        remote
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        classes={"table align-middle table-nowrap tbl-invoice table-mobile"}
                                                        headerWrapperClasses={"thead-light"}
                                                        onTableChange={this.handleTableChange}
                                                        {...toolkitProps.baseProps}
                                                        {...paginationTableProps}
                                                        noDataIndication={() => <NoDataIndication />}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        {this.props.isPaginationRequire && (
                                            <Row className="align-items-md-center mt-30">
                                                <Col className="inner-custom-pagination d-flex">
                                                    <div className="d-inline">
                                                        <SizePerPageDropdownStandalone
                                                            {...paginationProps}
                                                        />
                                                        <PaginationTotalStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                    <div className="text-md-right ms-auto">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                        }

                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        )}
                    </PaginationProvider>
                )}
                <RecordPayment modal_is_open={this.state.modal_is_open} openModal={this.openModal} closeModal={this.closeModal} invoice_id={this.state.active_inv_id} customer_name={this.state.active_cust_name} invoice_no={this.state.active_invoice_no} invoice_amount={this.state.active_invoice_amount} handleRefresh={this.handleRefresh} />

                <SendInvModel id={this.state.active_inv_id} invoice_no={this.state.active_inv_no} isOpen={this.state.modal} toggle={this.toggleSend} email={this.state.email} />
                <InvoiceDetailsModel key={1} id={this.state.active_inv_id} isOpen={this.state.modalView} toggle={this.toggleView} instance_id={this.state.instance_id} email={this.state.email} />
                {/* <InvoiceDetailsPdf id={this.state.pdfId}  /> */}
            </React.Fragment>
        );
    }
}

InvoiceListAllMobile.defaultProps = {
    isPaginationRequire: true
}

export default withRouter(InvoiceListAllMobile);
