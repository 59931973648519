import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
interface Params extends RouteComponentProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  subMsg?: string;
}

type State = {
  modalInfo: boolean;
  message: string;
  isProgress: number;
  subMsg: string | undefined;
};
class SuccessAlert extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalInfo: false,
      message: "",
      isProgress: 0,
      subMsg: "",
    };
  }

  componentWillReceiveProps(nextProps: Params) {
    this.setState({
      modalInfo: nextProps.isOpen,
      message: nextProps.message,
      subMsg :nextProps.subMsg
    });
  }

  onClose = () => {
    this.setState({ isProgress: 0, modalInfo: false });
  };

  render() {
    const { onClose } = this.props;
    const { isProgress } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          centered={true}
          className="exampleModal"
        >
          <div className="modal-content">
            <ModalBody style={{ paddingBottom: 0 }}>
              <h5 className="invoice-guard-heading">{this.state.message}</h5>
              <span className="text-muted">{this.state.subMsg}</span>
            </ModalBody>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                type="button"
                className="bmt-btn-submit itemsubmit"
                style={{ width: "100px" }}
                onClick={onClose}
              >
                OK
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(SuccessAlert);
