import { call, put, takeEvery } from "redux-saga/effects"

import {
  getExpItemsSuccess,
  getExpItemsFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { ExpItemsTypes } from "./actionTypes"

export const getExpItems = () => get(process.env.REACT_APP_API_URL + '/api/exp_items')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchExpItems() {
  try {
    const response : apiRes = yield call(getExpItems);  
    if (response.success) {
      yield put(getExpItemsSuccess(response))
    } 
  } catch (error) {
    yield put(getExpItemsFail(error))
  }
}


function* expItemSaga() {  
  yield takeEvery(ExpItemsTypes.GET_EXP_ITEMS, fetchExpItems);
}

export {fetchExpItems};
export default expItemSaga
