import React, { useEffect, useState } from "react";
import {
  Button,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Table,
} from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import support from "src/assets/images/icons/support.svg";
import cash from "src/assets/images/icons/cash.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { post } from "src/helpers/api_helper";
import DocumentBank from "./StatementBank";
import { number_format } from "src/helpers/common";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BankMenu(props: any) {
  const [bank_cash_name, set_bank_cash_name] = useState("");
  const [bank_id, set_bank_id] = useState("");
  const [modelLedger, setModelLedger] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mouseTab, setmouseTab] = useState(false);
  const { datas }: { datas: Array<any> } = useSelector((state: any) => ({
    datas: state.bankorcash.bankorcash,
  }));

  const ledgerOpen = (row: any) => {
    setModelLedger(true);
    set_bank_id(row.id);
    set_bank_cash_name(row.name);
  };
  const handleMouseEnter = () => {
    setmouseTab(true);
  };
  const handleMouseLeave = () => {
    setmouseTab(false);
  };
  return (
    <>
      <Dropdown
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center p-1"
          id="page-header-user-dropdown"
          tag="button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* <i className="fa fa-question-circle"></i> */}
          <div className="icon-block-01">
            <img src={cash} alt="Support" />
            {mouseTab && <div className="tooltip-header">Bank/Cash</div>}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="cash-dropDown">
            <h6 className="dropDown-header cash-list-item">Bank/Cash</h6>
            <ul className="cash-list-wrap">
              {datas.map((data, index) => {
                return (
                  <div key={index}>
                    <li className="cash-list-item">
                      <div className="d-flex">
                        <div className="bank-block">
                          <span className="d-block cash-title-bank">
                            {data.name}
                          </span>
                          <span className="d-block cash-amount">
                            {" "}
                            ₹ {number_format(data.closing_balance.total_amount)}
                          </span>
                        </div>
                        <div className="btn-set">
                          <button
                            className="btn btn-statement normal-btn hash-button"
                            onClick={() => ledgerOpen(data)}
                          >
                            Statement
                          </button>
                        </div>
                      </div>
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
        </DropdownMenu>
      </Dropdown>

      {modelLedger ? (
        <DocumentBank
          openModal={() => setModelLedger(true)}
          modal_is_open={modelLedger}
          closeModal={() => setModelLedger(false)}
          bank_name={bank_cash_name}
          bank_id={bank_id}
        />
      ) : null}
    </>
  );
}

export default React.memo(BankMenu);
