import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  BreadcrumbItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import ExpenseIcon from "src/assets/images/icons/Expense";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import classnames from "classnames";
import { number_format, predefinedRanges } from "src/helpers/common";
import Estimate2Icon from "src/assets/images/icons/Estimate2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import ExpenseBillList from "./ExpenseBillList";
import VendorModal from "../Vendors/VendorModal";
import ExpItemModal from "../ExpItems/ExpItemModal";
import ExpCategoryModal from "../ExpCategories/ExpCategoryModal";

import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import ExpenseBillAllList from "./ExpenseBillAllList";
import ExpenseBillPaidList from "./ExpenseBillPaidList";
import ExpenseBillUnPaidList from "./ExpenseBillUnPaidList";
import ExpenseLearnMore from "./ExpenseLearnMore";

interface MyProps extends RouteComponentProps<any> {}
interface Option {
  label: string;
  value: string;
}
interface MyState {
  activeTab: number;
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  permissions: Array<any>;
  loading: boolean;
  modal_is_open: boolean;
  expense_prefix: string;
  startDate: Date | null;
  endDate: Date | null;
  vendors: Array<any>;
  items: Array<any>;
  vendor: Option | null | undefined;
  item: Option | null | undefined;
  modalVendor: boolean;
  modalExpItem: boolean;
  modalExpCategory: boolean;
  modalLearnMore: boolean;
  totalAmount: number;
  unpaidLen: number;
  paidLen: number;
  paidamt: any;
  unpaidamt: any;
  refresh: boolean;
  mouseTab: number;
  tax_amount: number;
  total_taxable_amount: number;
  partially_paid: number;
  total_paid: number;
  unpaid: number;
  un_partially_paid: number;
  totalAllPaid: number;
}
interface Entry {
  status: number;
  amount: any;
}
interface ChangeProp {
  page: number;
  sizePerPage: number;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});

class Purchases extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      activeTab: 1,
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "invoice_no",
      sortOrder: "asc",
      loading: true,
      permissions: [],
      modal_is_open: false,
      expense_prefix: "",
      startDate: null,
      endDate: null,
      vendors: [],
      items: [],
      vendor: null,
      item: null,
      modalVendor: false,
      modalExpItem: false,
      modalExpCategory: false,
      modalLearnMore: false,
      totalAmount: 0,
      unpaidLen: 0,
      paidLen: 0,
      paidamt: 0,
      unpaidamt: 0,
      refresh: false,
      mouseTab: 0,
      tax_amount: 0,
      total_taxable_amount: 0,
      partially_paid: 0,
      total_paid: 0,
      unpaid: 0,
      un_partially_paid: 0,
      totalAllPaid: 0,
    };
  }
  openModalVendor = () => {
    this.setState({ modalVendor: true });
  };

  closeModalVendor = () => {
    this.setState({ modalVendor: false });
  };
  openModalExpItem = () => {
    this.setState({ modalExpItem: true });
  };

  closeModalExpItem = () => {
    this.setState({ modalExpItem: false });
  };
  openModalExpCategory = () => {
    this.setState({ modalExpCategory: true });
  };
  //learn more modal
  openModalLearnMore = () => {
    this.setState({ modalLearnMore: true });
  };

  closeModalLearnMore = () => {
    this.setState({ modalLearnMore: false });
  };

  closeModalExpCategory = () => {
    this.setState({ modalExpCategory: false });
  };
  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  toggleTab = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };
  MouseEnter = (tab: any) => {
    this.setState({ mouseTab: tab });
  };
  MouseLeave = () => {
    this.setState({ mouseTab: 0 });
  };
  setRefresh = (param: any) => {
    if (param == true) {
      this.setState({ refresh: !this.state.refresh });
    }
  };
  sendGetRequest = async () => {
    try {
      if (this._isMounted) this.setState({ loading: false });
      const respPer = await get(
        process.env.REACT_APP_API_URL + `/api/permissions/7`
      );
      if (respPer.success) {
        if (this._isMounted) {
          this.setState({
            permissions: respPer.data,
          });
        }
        const respE: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_expense_no"
        );

        if (this._isMounted)
          this.setState({ expense_prefix: respE.data.expense_prefix });

        const resV: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_vendors"
        );
        resV.data.map((vendor: { vendor_name: string; id: string }) => {
          let newOption = createOption(vendor.vendor_name, vendor.id);
          if (this._isMounted)
            this.setState({ vendors: [...this.state.vendors, newOption] });
        });

        const resI: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_exp_items"
        );
        resI.data.map((item: { item_name: string; id: string }) => {
          let newOption = createOption(item.item_name, item.id);
          if (this._isMounted)
            this.setState({ items: [...this.state.items, newOption] });
        });
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.sendGetRequest();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDelete = (row: any) => {
    const sendGetRequest = async () => {
      try {
        const conf = window.confirm("Do you want to delete this record?");
        if (conf) {
          const resp = del(
            process.env.REACT_APP_API_URL + "/api/expense_delete/" + row.id
          );
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    sendGetRequest();
  };

  openModal = () => {
    this.setState({ modal_is_open: true });
  };

  closeModal = () => {
    this.setState({ modal_is_open: false });
  };
  setTotalAmount = async (param: {}) => {
    this.setState(param);
  };
  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
  }
  setStartDateNew(dateRange: any) {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState({ startDate: startDate, endDate: endDate });
  }
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    let name: any = actionMeta.name;
    let data: any = {};
    data[name] = newValue;
    this.setState(data);
    if (name == "item") {
    }
  };
  render() {
    const title = "Expense Bills";
    const breadcrumbItem = "Expense Bills";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ minHeight: "685px", background: "#F1F1F1" }}
        >
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header">
                    {" "}
                    <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={6}>
                            <Select
                              styles={{
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  ":hover": {
                                    backgroundColor: "#10a37f",
                                    color: "#fff",
                                  },
                                  backgroundColor: state.isSelected
                                    ? "#10a37f"
                                    : "inherit",
                                }),
                              }}
                              isClearable={true}
                              onChange={this.handleChange}
                              options={this.state.vendors}
                              value={this.state.vendor}
                              name="vendor"
                              placeholder="Select Vendor"
                            />
                          </Col>
                          <Col lg={6}>
                            {/* <ReactDatePicker
                              selected={this.state.startDate}
                              onChange={(date: Array<any>) =>
                                this.setStartDate(date)
                              }
                              selectsRange={true}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              dateFormat="yyyy-MM-dd"
                              className="form-control calendar"
                              isClearable={true}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              todayButton="Today"
                              monthsShown={2}
                            /> */}
                            <DateRangePicker
                              ranges={predefinedRanges}
                              format="dd/MM/yyyy"
                              placeholder=" "
                              style={{ width: 280 }}
                              onChange={(date: DateRange | null) =>
                                this.setStartDateNew(date)
                              }
                            />
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </div>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                    <Col lg={12}>
                      <div className="bmt-btn-submit text-center itemsubmit">
                        <a
                          className="text-light"
                          href="#"
                          onClick={(e: any) => this.openModalLearnMore()}
                        >
                          Learn More
                        </a>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader className="cus-card">
                    <div className="row">
                      <div className="col-md-12 col-xl-7 order-xl-1">
                        {this.state.loading == true ? (
                          <>
                            <li>
                              <Skeleton width={"30%"} height={"100%"} />
                            </li>
                            <li>
                              <Skeleton width={"30%"} height={"100%"} />
                            </li>
                            <li>
                              <Skeleton width={"30%"} height={"100%"} />
                            </li>
                          </>
                        ) : (
                          <>
                            <ul className="values-inv">
                              <li
                                className={
                                  this.state.activeTab == 1 ? "active" : ""
                                }
                              >
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab == 1,
                                  })}
                                  onClick={() => {
                                    this.toggleTab(1);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32.815"
                                    height="49.055"
                                    viewBox="0 0 32.815 49.055"
                                  >
                                    <path
                                      id="Path_441"
                                      data-name="Path 441"
                                      d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                      transform="translate(-1.415 49.055)"
                                    />
                                  </svg>

                                  <span>
                                    {" "}
                                    {number_format(this.state.totalAmount)}
                                  </span>
                                  <label>
                                    Total
                                    <i
                                      className="bx bx-message-square-dots comment_icon"
                                      onMouseEnter={() => {
                                        this.MouseEnter(1);
                                      }}
                                      onMouseLeave={this.MouseLeave}
                                    ></i>
                                  </label>
                                  {this.state.mouseTab == 1 && (
                                    <div
                                      className="popup-content"
                                      onMouseEnter={() => {
                                        this.MouseEnter(1);
                                      }}
                                      onMouseLeave={this.MouseLeave}
                                    >
                                      <table className="popup-table">
                                        <tr>
                                          <td className="fixed-width">
                                            Taxable Amount
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.total_taxable_amount
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="fixed-width">
                                            Tax Amount
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.tax_amount
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="fixed-width">
                                            Total Amount
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.totalAmount
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  )}
                                </NavLink>
                              </li>
                              <li
                                className={
                                  this.state.activeTab == 2 ? "active" : ""
                                }
                              >
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab == 2,
                                  })}
                                  onClick={() => {
                                    this.toggleTab(2);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32.815"
                                    height="49.055"
                                    viewBox="0 0 32.815 49.055"
                                  >
                                    <path
                                      id="Path_441"
                                      data-name="Path 441"
                                      d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                      transform="translate(-1.415 49.055)"
                                    />
                                  </svg>

                                  <span>
                                    {" "}
                                    {number_format(this.state.total_paid)}
                                  </span>
                                  <label>
                                    Paid
                                    <i
                                      className="bx bx-message-square-dots comment_icon"
                                      onMouseEnter={() => {
                                        this.MouseEnter(2);
                                      }}
                                      onMouseLeave={this.MouseLeave}
                                    ></i>
                                  </label>
                                  {this.state.mouseTab == 2 && (
                                    <div
                                      className="popup-content"
                                      onMouseEnter={() => {
                                        this.MouseEnter(2);
                                      }}
                                      onMouseLeave={this.MouseLeave}
                                    >
                                      <table className="popup-table">
                                        <tr>
                                          <td className="fixed-width">
                                            Paid Bills
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(this.state.paidamt)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="fixed-width">
                                            Partially paid
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.partially_paid
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="fixed-width">
                                            Total Amount
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.total_paid
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  )}
                                </NavLink>
                              </li>
                              <li
                                className={
                                  this.state.activeTab == 3 ? "active" : ""
                                }
                              >
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab == 3,
                                  })}
                                  onClick={() => {
                                    this.toggleTab(3);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32.815"
                                    height="49.055"
                                    viewBox="0 0 32.815 49.055"
                                  >
                                    <path
                                      id="Path_441"
                                      data-name="Path 441"
                                      d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                      transform="translate(-1.415 49.055)"
                                    />
                                  </svg>
                                  <span>
                                    {" "}
                                    {number_format(this.state.unpaidamt)}
                                  </span>
                                  <label>
                                    Unpaid
                                    <i
                                      className="bx bx-message-square-dots comment_icon"
                                      onMouseEnter={() => {
                                        this.MouseEnter(3);
                                      }}
                                      onMouseLeave={this.MouseLeave}
                                    ></i>
                                  </label>
                                  {this.state.mouseTab == 3 && (
                                    <div
                                      className="popup-content"
                                      onMouseEnter={() => {
                                        this.MouseEnter(3);
                                      }}
                                      onMouseLeave={this.MouseLeave}
                                    >
                                      <table className="popup-table">
                                        <tr>
                                          <td className="fixed-width">
                                            Partially Unpaid
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.un_partially_paid
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="fixed-width">
                                            Unpaid Bills
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(this.state.unpaid)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="fixed-width">
                                            Total Amount
                                          </td>
                                          <td className="px-1"> : </td>
                                          <td className="text-end">
                                            {number_format(
                                              this.state.unpaidamt
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  )}{" "}
                                </NavLink>
                              </li>
                            </ul>
                          </>
                        )}
                        <ul className="inv-count">
                          <li>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                viewBox="0 0 31 31"
                              >
                                <g
                                  id="Group_12"
                                  data-name="Group 12"
                                  transform="translate(-326 -668)"
                                >
                                  <circle
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="15.5"
                                    cy="15.5"
                                    r="15.5"
                                    transform="translate(326 668)"
                                    fill="#3296cd"
                                  />
                                  <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M3728.683,669l8.305,6.207,11.513-16.287"
                                    transform="translate(-3395.042 13.241)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="4"
                                  />
                                </g>
                              </svg>
                            </span>
                            {this.state.totalSize} Records
                          </li>
                          <li>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                viewBox="0 0 31 31"
                              >
                                <g
                                  id="Group_12"
                                  data-name="Group 12"
                                  transform="translate(-326 -668)"
                                >
                                  <circle
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="15.5"
                                    cy="15.5"
                                    r="15.5"
                                    transform="translate(326 668)"
                                    fill="#b4b0ad"
                                  />
                                  <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M3728.683,669l8.305,6.207,11.513-16.287"
                                    transform="translate(-3395.042 13.241)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="4"
                                  />
                                </g>
                              </svg>
                            </span>
                            {this.state.paidLen} Records
                          </li>
                          <li>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                viewBox="0 0 31 31"
                              >
                                <g
                                  id="Group_12"
                                  data-name="Group 12"
                                  transform="translate(-326 -668)"
                                >
                                  <circle
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="15.5"
                                    cy="15.5"
                                    r="15.5"
                                    transform="translate(326 668)"
                                    fill="#43aa77"
                                  />
                                  <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M3728.683,669l8.305,6.207,11.513-16.287"
                                    transform="translate(-3395.042 13.241)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="4"
                                  />
                                </g>
                              </svg>
                            </span>
                            {this.state.unpaidLen} Records
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-12 col-xl-5 order-xl-1">
                        {this.state.permissions.find(
                          ({ action, is_active }) =>
                            action == "create" && is_active == 1
                        ) && (
                          <ul className="qck-link1">
                            <li>
                              <Link
                                to="#"
                                onClick={(e: any) =>
                                  this.openModalExpCategory()
                                }
                              >
                                <span>
                                  <ExpenseIcon />
                                </span>
                                <label>Add Category</label>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e: any) => this.openModalExpItem()}
                              >
                                <span>
                                  <ExpenseIcon />
                                </span>
                                <label>Add Item</label>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e: any) => this.openModalVendor()}
                              >
                                <span>
                                  <Addcustomer2Icon />
                                </span>
                                <label>Add Vendor</label>
                              </Link>
                            </li>

                            <li>
                              <Link to="/expense_bill_add">
                                <span>
                                  <Invoice2Icon />
                                </span>
                                <label>Add Expense bill</label>
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    {this.state.loading == true ? (
                      <>
                        <CardTitle className="h4">
                          <Skeleton height={"10%"} width={"10%"} />
                        </CardTitle>
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="text-muted"
                        >
                          <TabPane tabId={1}>
                            <Row>
                              <Col sm="12">
                                <ExpenseBillAllList
                                  valueVendor={this.state.vendor}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={2}>
                            <Row>
                              <Col sm="12">
                                <ExpenseBillPaidList
                                  valueVendor={this.state.vendor}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={3}>
                            <Row>
                              <Col sm="12">
                                <ExpenseBillUnPaidList
                                  valueVendor={this.state.vendor}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  refresh={this.state.refresh}
                                  setRefresh={this.setRefresh}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        {this.state.modalVendor && (
          <VendorModal
            modal_is_open={this.state.modalVendor}
            openModal={this.openModalVendor}
            closeModal={this.closeModalVendor}
            id={"0"}
          />
        )}
        {this.state.modalExpItem && (
          <ExpItemModal
            modal_is_open={this.state.modalExpItem}
            openModal={this.openModalExpItem}
            closeModal={this.closeModalExpItem}
            id={"0"}
          />
        )}
        {this.state.modalExpCategory && (
          <ExpCategoryModal
            modal_is_open={this.state.modalExpCategory}
            openModal={this.openModalExpCategory}
            closeModal={this.closeModalExpCategory}
            id={"0"}
          />
        )}
        {this.state.modalLearnMore && (
          <ExpenseLearnMore
            modal_is_open={this.state.modalLearnMore}
            openProductModal={this.openModalLearnMore}
            closeProductModal={this.closeModalLearnMore}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Purchases);
