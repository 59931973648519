import {CompaniesTypes} from "./actionTypes"

const INIT_STATE = {
  companies: [],  
  error: {},
}

const Companies = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case CompaniesTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload.data,
      }
    case CompaniesTypes.GET_COMPANIES:
      return {
        ...state,
        companies: state.companies,
      }
    case CompaniesTypes.GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Companies
