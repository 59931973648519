import React, { useCallback, useState } from "react";
import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ProformaList from "./PerformaList";

const Performa = () => {  

  return (
    <React.Fragment>
          <ProformaList />           
    </React.Fragment>
  );
};

export default Performa; 
