import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Col,
  Input,
  Label,
  Modal,
  Row,
  Table,
  UncontrolledAlert,
  Button,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { post, get, del } from "src/helpers/api_helper";
import moment from "moment";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import Moment from "moment";
import recordPaymentImg from "../../assets/images/record-payment.png";
import { setbankorcashSuccess } from "src/store/actions";
import { connect } from "react-redux";
import DatePicker from "rsuite/DatePicker";

interface Params extends RouteComponentProps {
  modal_is_open: boolean;
  openModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
  data: any;
  purchase_hdr_id: string;
  bankorcash?: any;
  // customer_name: string;
  // invoice_no: string;
  invoice_amount: number;
  handleRefresh: () => void;
  setCashOrBank: () => void;
}
interface BankCash {
  id: string;
  name: string;
  heads_id: string;
}
type State = {
  id: string;
  banksCash: Array<any>;
  payments: Array<any>;
  date_receipt: Date | null;
  payment_type: string;
  received_amount: number;
  tds: number;
  discount: number;
  exchange_loss_or_gain: number;
  balance_due: number;
  notes: string;
  success: number;
  total_amount_due: number;
  total_amount_received: number;
  isModalProgress: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  invoice_amount: number;
  isReadOnly: boolean;
  error_message: string;
};

class RecordPaymentModal extends Component<Params, State> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "",
      banksCash: [],
      payments: [],
      date_receipt: new Date(),
      payment_type: "",
      received_amount: 0,
      tds: 0,
      discount: 0,
      exchange_loss_or_gain: 0,
      balance_due: 0,
      notes: "",
      success: 0,
      total_amount_due: 0,
      total_amount_received: 0,
      isModalProgress: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      invoice_amount: 0,
      isReadOnly: false,
      error_message: "",
    };
  }

  onChangehandler = (e: any, isNum: number = 0) => {
    let name = e.target.name;
    let value = isNum ? parseFloat(e.target.value) : e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  set_date_receipt = (date: any) => {
    if (date == null) {
      this.setState({ date_receipt: null });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ date_receipt: formattedDate });
    }
  };
  calcTotal = async () => {
    let total_amount_due: number = isNaN(Number(this.state.total_amount_due))
      ? 0
      : Number(this.state.total_amount_due);
    let received_amount: number = isNaN(Number(this.state.received_amount))
      ? 0
      : Number(this.state.received_amount);
    let tds: number = isNaN(Number(this.state.tds))
      ? 0
      : Number(this.state.tds);
    let discount: number = isNaN(Number(this.state.discount))
      ? 0
      : Number(this.state.discount);
    let exchange_loss_or_gain: number = isNaN(
      Number(this.state.exchange_loss_or_gain)
    )
      ? 0
      : Number(this.state.exchange_loss_or_gain);
    let deduction: number =
      received_amount + tds + discount + exchange_loss_or_gain;
    var balance_due: number = 0;
    if (this.state.id) {
      balance_due =
        total_amount_due + this.state.total_amount_received - deduction;
    } else {
      balance_due = total_amount_due - deduction;
    }
    this.setState({ balance_due: balance_due });
  };

  componentWillReceiveProps(props: Params) {
    let data = props.data;
    this.setState({
      invoice_amount: this.props.invoice_amount,
      received_amount: data.received_amount,
      tds: data.tds,
      discount: data.discount,
      exchange_loss_or_gain: data.exchange_loss_or_gain,
      balance_due: data.total_amount_due,
      notes: data.notes,
      payment_type: data.payment_type,
      id: data.id,
      total_amount_due: data.total_amount_due,
      isReadOnly: data.edit == 1 ? false : true,
    });
    const dueDate = moment(data.date_receipt);
    this.setState({ date_receipt: new Date(dueDate.format("YYYY-MM-DD")) });
  }

  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isModalProgress: true, isHideErrorMgs: true });
    const selectedBankCash = this.props.bankorcash.find(
      (bankorcash: { id: any }) => bankorcash.id === values.payment_type
    );
    if (selectedBankCash && selectedBankCash.heads_id) {
      values.heads_id = selectedBankCash.heads_id;
    }
    values["purchase_hdr_id"] = this.props.purchase_hdr_id;
    values["date_receipt"] = this.state.date_receipt;
    values["id"] = this.state.id;

    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/purchase_payments_save",
      values
    );
    if (resp.success == true) {
      this.setState({
        isHideSuccessMgs: false,
      });
      setTimeout(() => {
        this.setState({
        success: 1,
        date_receipt: null,
        payment_type: "",
        received_amount: 0,
        tds: 0,
        discount: 0,
        exchange_loss_or_gain: 0,
        balance_due: 0,
        notes: "",
        id: "",
          isHideSuccessMgs: true,
        });
        this.props.handleRefresh();
        this.props.closeModal();
        this.props.setCashOrBank();
      }, 1000);
    } else {
      this.setState({
        success: 0,
        isHideErrorMgs: false,
        error_message: resp.message,
      });
    }
    this.setState({ isModalProgress: false });
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Params) {
    if (
      /* this.props.purchase_hdr_id !== prevProps.purchase_hdr_id */ this.props
        .modal_is_open !== prevProps.modal_is_open
    ) {
      this.fetchList(this.props.purchase_hdr_id);
    }
  }

  fetchList = async (purchase_hdr_id: any) => {
    const respPaymentLst: any = await get(
      process.env.REACT_APP_API_URL +
        `/api/purchase_payments_list/${purchase_hdr_id}`
    );

    // let payments: Array<any> = respPaymentLst.data;
    const total_amount_received: number = respPaymentLst.received_amount;
    const total_amount_due: number =
      this.props.invoice_amount - total_amount_received;
    let readOny =
      total_amount_due > 0 ? false : this.props.data.edit == 1 ? false : true;
    if (this._isMounted) {
      this.setState({
        total_amount_received: total_amount_received,
        total_amount_due: total_amount_due,
        balance_due: total_amount_due,
        isReadOnly: readOny,
      });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modal_is_open}
        toggle={this.props.openModal}
        className={"md-700"}
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-body pt-1">
            <UncontrolledAlert
              hidden={this.state.isHideSuccessMgs}
              color="success"
            >
              Purchase bills Payment successfully submitted.
            </UncontrolledAlert>

            <UncontrolledAlert
              hidden={this.state.isHideErrorMgs}
              color="danger"
            >
              {this.state.error_message}
            </UncontrolledAlert>
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="item_form pt-0 pb-0">
                  <div className="row">
                    <div className="heading">Record Payment</div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Payment Date
                    </label>
                    <div className="col-sm-8">
                      <DatePicker
                        oneTap
                        onChange={this.set_date_receipt}
                        name="date_receipt"
                        format="dd/MM/yyyy"
                        placeholder="Enter Date of receipt"
                        menuClassName="custom-datepicker-dropdown"
                        style={{ width: "100%" }}
                        value={
                          this.state.date_receipt
                            ? this.state.date_receipt
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: 7 }}>
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Payment type
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        type="select"
                        name="payment_type"
                        errorMessage="Choose Mode of Payment"
                        required
                        // validate={{ required: { value: true } }}
                        value={this.state.payment_type}
                        onChange={(e: any) => this.onChangehandler(e)}
                      >
                        <option value="" key="">
                          --Select--
                        </option>
                        {/* <option value="1" key="">
                          Cash
                        </option> */}
                        {this.props.bankorcash &&
                          this.props.bankorcash.map((bank_cash: BankCash) => {
                            return (
                              <option value={bank_cash.id} key={bank_cash.id}>
                                {bank_cash.name}
                              </option>
                            );
                          })}
                      </AvField>
                    </div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Paid Amount
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        name="received_amount"
                        type="number"
                        // placeholder="Enter Received Amount"
                        errorMessage="Enter Paid Amount"
                        validate={{
                          required: {
                            value: this.state.payment_type ? true : false,
                          },
                        }}
                        value={this.state.received_amount}
                        onChange={(e: any) => this.onChangehandler(e, 1)}
                        onKeyUp={() => this.calcTotal()}
                        // readOnly={(this.state.total_amount_due > 0) ? false : true}
                        readOnly={
                          this.state.payment_type ? this.state.isReadOnly : true
                        }
                        className="mb-1"
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <label
                      htmlFor="progresspill-address-input"
                      className="col-sm-4 col-form-label"
                    >
                      TDS Payable
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        name="tds"
                        type="number"
                        value={this.state.tds}
                        onChange={(e: any) => this.onChangehandler(e, 1)}
                        onKeyUp={() => this.calcTotal()}
                        // readOnly={(this.state.total_amount_due > 0) ? false : true}
                        readOnly={this.state.isReadOnly}
                        className="mb-1"
                      />
                    </div>
                  </div> */}
                  {/* <div className="row">
                                        <label htmlFor="progresspill-firstname-input" className='col-sm-4 col-form-label'>
                                            Discount
                                        </label>
                                        <div className='col-sm-8'>
                                            <AvField
                                                name="discount"
                                                type="number"
                                                value={this.state.discount}
                                                onChange={(e: any) => this.onChangehandler(e, 1)}
                                                onKeyUp={() => this.calcTotal()}
                                                // readOnly={(this.state.total_amount_due > 0) ? false : true}
                                                readOnly={this.state.isReadOnly}
                                                className="mb-1"
                                            />
                                        </div>
                                    </div> */}
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Exchange Gain/Loss
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        name="exchange_loss_or_gain"
                        type="number"
                        value={this.state.exchange_loss_or_gain}
                        onChange={(e: any) => this.onChangehandler(e, 1)}
                        onKeyUp={() => this.calcTotal()}
                        // readOnly={(this.state.total_amount_due > 0) ? false : true}
                        readOnly={this.state.isReadOnly}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Balance Due
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        name="balance_due"
                        type="number"
                        readOnly={true}
                        value={Number(this.state.balance_due).toFixed(2)}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Note
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        name="notes"
                        type="textarea"
                        value={this.state.notes}
                        onChange={(e: any) => this.onChangehandler(e)}
                        rows={1}
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    {this.state.isModalProgress ? (
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                        disabled={true}
                      >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        Submitting
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="button"
                          className="bmt-btn-close itemsubmit"
                          onClick={() => this.props.closeModal()}
                        >
                          Close
                        </Button>{" "}
                        <Button type="submit" className="bmt-btn-submit itemsubmit">
                          Update Payment
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    );
  }
}

const mapStateToProps = (state: { bankorcash: any }) => {
  return {
    bankorcash: state.bankorcash.bankorcash,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setCashOrBank: () => {
      setbankorcashSuccess(dispatch);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecordPaymentModal));
