import {ProductsTypes} from "./actionTypes"

const INIT_STATE = {
  products: [],  
  error: {},
}

const Products = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case ProductsTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.data,
      }
    case ProductsTypes.GET_PRODUCTS:
      return {
        ...state,
        products: state.products,
      }
    case ProductsTypes.GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Products
