import {SacCodeTypes} from "./actionTypes"

export const getSacCode = () => ({
  type: SacCodeTypes.GET_SACCODE,
})

export const getSacCodeSuccess = (taxes : any) => ({
  type: SacCodeTypes.GET_SACCODE_SUCCESS,
  payload: taxes,
})

export const getSacCodeFail = (error : any) => ({
  type: SacCodeTypes.GET_SACCODE_FAIL,
  payload: error,
})



