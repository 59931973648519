import { Redirect } from "react-router-dom"; 
import lazyLoader from "src/pages/Utility/lazyLoader";
//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
// import userProfile from "src/pages/Authentication/user-profile";
const userProfile = lazyLoader(() => import("src/pages/Authentication/user-profile"));
import Verify from "src/pages/Authentication/Verify";
import Error404 from "src/pages/Utility/Error404";
// import Roles from "src/pages/Roles";
const Roles = lazyLoader(() => import("src/pages/Roles"));
// import RoleCreate from "src/pages/Roles/RoleCreate";
const RoleCreate = lazyLoader(() => import("src/pages/Roles/RoleCreate"));
// import RoleModify from "src/pages/Roles/RoleModify";
const RoleModify = lazyLoader(() => import("src/pages/Roles/RoleModify"));
// import UserCreate from "src/pages/Users/UserCreate";
const UserCreate = lazyLoader(() => import("src/pages/Users/UserCreate"));
// import UserModify from "src/pages/Users/UserModify";
const UserModify = lazyLoader(() => import("src/pages/Users/UserModify"));
// import Users from "src/pages/Users";
const Users = lazyLoader(() => import("src/pages/Users"));
// import Permissions from "src/pages/Roles/Permissions";
const Permissions = lazyLoader(() => import("src/pages/Roles/Permissions"));
// import CompanyProfile from "src/pages/company/CompanyProfile";
const CompanyProfile = lazyLoader(() => import("src/pages/company/CompanyProfile"));
import Products from "src/pages/Products";
import Inventory from "src/pages/Products/Inventory";
import ProductCreate from "src/pages/Products/ProductCreate";
import ProductModify from "src/pages/Products/ProductModify";
// import Banks from "src/pages/Banks";
const Banks = lazyLoader(() => import("src/pages/Banks"));
// import BankCreate from "src/pages/Banks/BankCreate";
const BankCreate = lazyLoader(() => import("src/pages/Banks/BankCreate"));
// import BankModify from "src/pages/Banks/BankModify";
const BankModify = lazyLoader(() => import("src/pages/Banks/BankModify"));
import Invoices from "src/pages/Invoices";
import TDSDeducted from "src/pages/TDSDeducted/index";
import InvoiceCreate from "src/pages/Invoices/InvoiceCreate";
import InvoiceModify from "src/pages/Invoices/InvoiceModify";
import InvoiceDetails from "src/pages/Invoices/InvoiceDetails";
import Customers from "src/pages/Customers";
import CustomerCreate from "src/pages/Customers/CustomerCreate";
import CustomerModify from "src/pages/Customers/CustomerModify";
import Estimations from "src/pages/Estimations";
import EstimationCreate from "src/pages/Estimations/EstimationCreate";
import EstimationModify from "src/pages/Estimations/EstimationModify";
// import ForgetPassword from "src/pages/Authentication/ForgetPassword";
// import ResetPassword from "src/pages/Authentication/ResetPassword";
const ResetPassword = lazyLoader(() => import("src/pages/Authentication/ResetPassword"));
import EstimationDetails from "src/pages/Estimations/EstimationDetails";
import EstimationView from "src/pages/Estimations/EstimationView";
import ProformaView from "src/pages/Performa/PerformaView"
import InvoiceView from "src/pages/Invoices/InvoiceView";
// import MicroFrontend from "src/components/MicroFrontend";

import { createBrowserHistory } from "history";
import ExpCategories from "src/pages/ExpCategories";
import ExpCategoryCreate from "src/pages/ExpCategories/ExpCategoryCreate";
import ExpItems from "src/pages/ExpItems";
import ExpItemCreate from "src/pages/ExpItems/ExpItemCreate";
import ExpItemModify from "src/pages/ExpItems/ExpItemModify";
import ExpCategoryModify from "src/pages/ExpCategories/ExpCategoryModify";
// import Vendors from "src/pages/Vendors";
const Vendors = lazyLoader(() => import("src/pages/Vendors"));
// import VendorCreate from "src/pages/Vendors/VendorCreate";
const VendorCreate = lazyLoader(() => import("src/pages/Vendors/VendorCreate"));
// import VendorModify from "src/pages/Vendors/VendorModify";
const VendorModify = lazyLoader(() => import("src/pages/Vendors/VendorModify"));
import Incomes from "src/pages/Income";
import IncomeCreate from "src/pages/Income/IncomeCreate";
import IncomeEditModal from "src/pages/Income/IncomeEditModal";
import Expenses from "src/pages/Expenses";
import ExpenseCreate from "src/pages/Expenses/ExpenseCreate";
import ExpenseModify from "src/pages/Expenses/ExpenseModify";
import PurchaseCreate from "src/pages/Purchase/PurchaseCreate";
import PurchaseModify from "src/pages/Purchase/PurchaseModify";
import ExpenseBillCreate from "src/pages/Expenses/ExpenseBillCreate";
import ExpenseBillModify from "src/pages/Expenses/ExpenseBillModify";
// import LoginOtp from "src/pages/Authentication/LoginOtp";
const LoginOtp = lazyLoader(() => import("src/pages/Authentication/LoginOtp"));
import Purchases from "src/pages/Purchase/Purchases";
import ExpenseBill from "src/pages/Expenses/ExpenseBill";
// import GstReturn from "src/pages/GST/Return";
const GstReturn = lazyLoader(() => import("src/pages/GST/Return"));
// import Reports from "src/pages/Reports";
const Reports = lazyLoader(() => import("src/pages/Reports"));
const ProfitLossIndex = lazyLoader(() => import("src/pages/Reports/ProfitLossIndex"));
// import SalesGstr1 from "src/pages/Reports/SalesGstr1";
const SalesGstr1 = lazyLoader(() => import("src/pages/Reports/SalesGstr1"));
// import Payments from "src/pages/GST/Payments";
const Payments = lazyLoader(() => import("src/pages/GST/Payments"));
// import Services from "src/pages/GST/Services";
const Services = lazyLoader(() => import("src/pages/GST/Services"));
// import MyOrders from "src/pages/GST/MyOrders";
const MyOrders = lazyLoader(() => import("src/pages/GST/MyOrders"));
// import EWayCreate from "src/pages/Invoices/EWayCreate";
const EWayCreate = lazyLoader(() => import("src/pages/Invoices/EWayCreate"));
// import EwayBillList from "src/pages/Invoices/EwayBillList";
const EwayBillList = lazyLoader(() => import("src/pages/Invoices/EwayBillList"));
// import LoginNew from "src/pages/Authentication/LoginNew";
const LoginNew = lazyLoader(() => import("src/pages/Authentication/LoginNew"));
// import ForgetPasswordNew from "src/pages/Authentication/ForgetPasswordNew";
const ForgetPasswordNew = lazyLoader(() => import("src/pages/Authentication/ForgetPasswordNew"));
// import RegisterNew from "src/pages/Authentication/RegisterNew";
const RegisterNew = lazyLoader(() => import("src/pages/Authentication/RegisterNew"));
// import Files from "src/pages/Files";
const Files = lazyLoader(() => import("src/pages/Files"));
import SystemInfo from "src/pages/System"
import Performa from "src/pages/Performa";
import PerformaCreate from "src/pages/Performa/PerformaCreate";
import PerformaModify from "src/pages/Performa/PerformaModify";
import PerformaDetails from "src/pages/Performa/PerformaDetails";

import PaymentReceviedIndex from "../pages/PaymentRecevied/index"
import checkFb from "src/pages/Authentication/checkFb";
import ConvertInvoice from "src/pages/Invoices/ConvertInvoice";
import JournalEntry from "src/pages/JournalEntry/JournalEntry";

// import Settings from "src/pages/Settings/Settings";
const Settings = lazyLoader(() => import("src/pages/Settings/Settings"));

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const defaultHistory = createBrowserHistory();

/* const {
  REACT_APP_PAYROLL_HOST: payrollHost
} = process.env;


function Payroll({ history }: any) { 
  return <MicroFrontend history={history} host={payrollHost} name="Payroll" />;
} */

const userRoutes: Array<RouteProps> = [

  //User Profile
  { path: "/profile", component: userProfile },

  //dashboard
  { path: "/dashboard", component: Dashboard },

  //roles
  // { path: "/roles", component: Roles },
  { path: "/role_add", component: RoleCreate },
  { path: "/role_edit/:id", component: RoleModify },
  { path: "/permissions/:role/:id", component: Permissions },

  //user
  // { path: "/users", component: Users },
  { path: "/user_add", component: UserCreate },
  { path: "/user_edit/:id", component: UserModify },

  //Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-fontawesome", component: IconFontawesome },

  //Company
  { path: "/company-profile", component: CompanyProfile },
  { path: "/settings/:tab?", component: Settings },

  //products
  { path: "/products", component: Products },
  { path: "/inventory", component: Inventory },
  { path: "/product_add", component: ProductCreate },
  { path: "/product_edit/:id", component: ProductModify },

  //banks
  { path: "/banks", component: Banks },
  { path: "/bank_add", component: BankCreate },
  { path: "/bank_edit/:id", component: BankModify },

  //customers
  { path: "/customers", component: Customers },
  { path: "/customer_add", component: CustomerCreate },
  { path: "/customer_edit/:id", component: CustomerModify },

  //invoices
  { path: "/invoices", component: Invoices },
  { path: "/invoice_add", component: InvoiceCreate },
  { path: "/invoice_edit/:id", component: InvoiceModify },
  { path: "/invoice_convert/:id", component: ConvertInvoice },
  { path: "/invoice_details/:id", component: InvoiceDetails },
  { path: "/eway_create/:id", component: EWayCreate },
  { path: "/eway_list", component: EwayBillList },

  //estimation
  { path: "/estimations", component: Estimations },
  { path: "/estimation_add", component: EstimationCreate },
  { path: "/estimation_edit/:id", component: EstimationModify },
  { path: "/estimation_details/:id", component: EstimationDetails },

  //Proforma
  { path: "/proforma", component: Performa },
  { path: "/proforma_add", component: PerformaCreate },
  { path: "/proforma_edit/:id", component: PerformaModify },
  { path: "/proforma_details/:id", component: PerformaDetails },

  // { path: "/paroll", component: Payroll },

  //expenses categories
  { path: "/exp_categories", component: ExpCategories },
  { path: "/exp_category_add", component: ExpCategoryCreate },
  { path: "/exp_category_edit/:id", component: ExpCategoryModify },

  //expenses items
  { path: "/exp_items", component: ExpItems },
  { path: "/exp_item_add", component: ExpItemCreate },
  { path: "/exp_item_edit/:id", component: ExpItemModify },

  //expenses items
  { path: "/vendors", component: Vendors },
  { path: "/purchase/vendors", component: Vendors },
  { path: "/vendor_add", component: VendorCreate },
  { path: "/vendor_edit/:id", component: VendorModify },
  //income
  { path: "/income", component: Incomes },
  { path: "/income_add", component: IncomeCreate },
  { path: "/income_edit/:id", component: IncomeEditModal },
  { path: "/journal_entry", component: JournalEntry },
  //expenses
  { path: "/expenses", component: Expenses },
  { path: "/purchases", component: Purchases },
  { path: "/expense_add", component: ExpenseCreate },
  { path: "/expense_edit/:id", component: ExpenseModify },
  { path: "/purchase_add", component: PurchaseCreate },
  { path: "/purchase_edit/:id", component: PurchaseModify },

  { path: "/expense_bill", component: ExpenseBill },
  { path: "/expense_bill_add", component: ExpenseBillCreate },
  { path: "/expense_bill_edit/:id", component: ExpenseBillModify },

  //workspace
  { path: "/gst_return", component: GstReturn },
  { path: "/gst_payments", component: Payments },
  { path: "/gst_services", component: Services },
  { path: "/my_orders", component: MyOrders },

  //reports
  { path: "/reports", component: Reports },
  { path: "/sales_gstr1", component: SalesGstr1 },

  //Files
  { path: "/file_manager", component: Files },

  { path: "/profit_loss", component: ProfitLossIndex },

  //PaymentRecevied
  { path: "/payment_recevied", component: PaymentReceviedIndex },
  { path: "/tds_deducted", component: TDSDeducted },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/invoices" /> },

  { path: "*", component: Error404 },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/system_info", component: SystemInfo },
  // { path: "/login", component: Login },
  { path: "/checkfb", component: checkFb },
  { path: "/login", component: LoginNew },
  { path: "/login_otp", component: LoginOtp },
  { path: "/logout", component: Logout },
  // { path: "/register", component: Register },
  { path: "/register", component: RegisterNew },
  // { path: "/forgotpassword", component: ForgetPassword },
  { path: "/forgotpassword", component: ForgetPasswordNew },
  { path: "/verify/:code", component: Verify },
  { path: "/change_password/:code", component: ResetPassword },
  { path: "/pages-404", component: Error404 },
  { path: "/estimation-view/:instanceId/:id", component: EstimationView },
  { path: "/proforma-view/:instanceId/:id", component: ProformaView },
  { path: "/invoice-view/:instanceId/:id", component: InvoiceView },
];

export { userRoutes, authRoutes }; 
