import React, { useState, useMemo } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import ItemLedger from "./itemLedger";
import Select from "react-select";
import GstLedger from "./GstLedger";
import { isNoEditableDisItem, isNoEditableItem } from "src/helpers/common";

function ItemMenu(props: any) {
  const [item_name, set_item_name] = useState("");
  const [item_id, set_item_id] = useState("");
  const [modelLedger, setModelLedger] = useState(false);
  const [modelgstLedger, setModelGSTLedger] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mouseTab, setmouseTab] = useState(false);

  const { datas }: { datas: Array<any> } = useSelector((state: any) => ({
    datas: state.exp_items.exp_items,
  }));

  const itemOptions = useMemo(() => {
    return datas.map((data) => ({
      label: (data.item_name +' ('+data.category_name+')'),
      value: data.id,
      data: data,
    }));
  }, [datas]);

  const ledgerOpen = (row: any) => {
    if(isNoEditableItem(row.value)){
      setModelGSTLedger(true);
    } else {
      setModelLedger(true);
    }
    set_item_id(row.value);
    set_item_name(row.label);
  };

  const handleMouseEnter = () => {
    setmouseTab(true);
  };
  const handleMouseLeave = () => {
    setmouseTab(false);
  };
  return (
    <>
      <Dropdown
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center p-1"
          id="page-header-user-dropdown"
          tag="button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="icon-block-01">
            <i
              className="fas fa-align-justify font-size-24"
              style={{ color: "#176955" }}
            ></i>
            {mouseTab && <div className="tooltip-header">Ledger</div>}
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="cash-dropDown">
            <h6 className="dropDown-header cash-list-item">Ledger/Items</h6>
            <div className="m-3">
              <Select
              styles={{
                option: (provided: any, state: any) => ({
                  ...provided,
                  ":hover": {
                    backgroundColor: "#10a37f",
                    color: "#fff"
                  },
                  backgroundColor: state.isSelected
                    ? "#10a37f"
                    : "inherit",
                }),
              }}
                options={itemOptions}
                name="items"
                placeholder="Items"
                onChange={ledgerOpen}
              />
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
      {modelLedger ? (
        <ItemLedger
          openModal={() => setModelLedger(true)}
          modal_is_open={modelLedger}
          closeModal={() => setModelLedger(false)}
          item_name={item_name}
          item_id={item_id}
        />
      ) : null}

      {modelgstLedger ? (
        <GstLedger
          openModal={() => setModelGSTLedger(true)}
          modal_is_open={modelgstLedger}
          closeModal={() => setModelGSTLedger(false)}
          item_name={item_name}
          item_id={item_id}
        />
      ) : null}
    </>
  );
}

export default React.memo(ItemMenu);
