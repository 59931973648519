import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Modal, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

interface Params extends RouteComponentProps {
  ref_modal_open: boolean;
  ref_openModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  ref_closeModal: () => void;
  parentCallback: (values: any) => void;
  prop_reference1?: string;
  prop_reference2?: string;
  prop_reference3?: string;
  prop_reference4?: string;
  title: string;
}

type State = {
  reference1: string;
  reference2: string;
  reference3: string;
  reference4: string;
  title: string;
};

class ReferenceModal extends Component<Params, State> {
  constructor(props: Params) {
    super(props);
    this.state = {
      reference1: "",
      reference2: "",
      reference3: "",
      reference4: "",
      title:"invoivce",
    };
  }

  componentDidUpdate(prevProps: Params) {
    if (prevProps.ref_modal_open !== this.props.ref_modal_open) {
      this.setState({
        reference1: this.props.prop_reference1 || "",
        reference2: this.props.prop_reference2 || "",
        reference3: this.props.prop_reference3 || "",
        reference4: this.props.prop_reference4 || "",
        title: this.props.title || "invoivce",
      });
    }
  }
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  handleValidSubmit = async (values: any) => {
    values["reference1"] = this.state.reference1;
    values["reference2"] = this.state.reference2;
    values["reference3"] = this.state.reference3;
    values["reference4"] = this.state.reference4;
    this.props.parentCallback(values);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.ref_modal_open}
        toggle={this.props.ref_openModal}
        className="md-400"
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-body pt-1">
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="inv_form pt-0 pb-0">
                  <div className="row">
                    <div className="heading">Custom Fields</div>
                    <span>This will be displayed on the {this.state.title}</span>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <AvField
                        name="reference1"
                        type="text"
                        placeholder="Custom Field 1"
                        value={this.state.reference1}
                        onChange={this.onChangehandler}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="row" style={{ paddingBottom: 7 }}>
                    <div className="col-sm-12">
                      <AvField
                        name="reference2"
                        type="text"
                        placeholder="Custom Field 2"
                        value={this.state.reference2}
                        onChange={this.onChangehandler}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <AvField
                        name="reference3"
                        type="text"
                        placeholder="Custom Field 3"
                        value={this.state.reference3}
                        onChange={this.onChangehandler}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <AvField
                        name="reference4"
                        type="text"
                        placeholder="Custom Field 4"
                        value={this.state.reference4}
                        onChange={this.onChangehandler}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    <>
                      <Button
                        type="button"
                        className="bmt-btn-close itemsubmit"
                        onClick={this.props.ref_closeModal}
                      >
                        Close
                      </Button>{" "}
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                      >
                        Save
                      </Button>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    );
  }
}

export default withRouter(ReferenceModal);
