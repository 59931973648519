import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const InvoiceLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal isOpen={modalVisible} centered={true} className="exampleModal">
        <ModalBody>
         <h5 className="invoice-guard-heading">Are you sure you want to leave this page?</h5>
         <span className="text-muted">Your unsaved data will be discarded.</span>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-guard-close"
            onClick={handleConfirmNavigationClick}
          >
            LEAVE
          </Button>
          <Button
            className="btn-guard-submit"
            onClick={closeModal}
          >
            STAY
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};
export default InvoiceLeavingGuard;
