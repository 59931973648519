import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { get } from "src/helpers/api_helper";

interface Params {
  instanceId: string;
  id: string;
}

type State = {
  id: string;
  instanceId: string;
  downloadStarted: boolean;
  error: string | null;
};

class ProformaView extends Component<RouteComponentProps<Params>, State> {
  constructor(props: any) {
    super(props);
    const { instanceId, id } = this.props.match.params;
    this.state = {
      id: id,
      instanceId: instanceId,
      downloadStarted: false,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const { instanceId, id } = this.state;
      const response: any = await get(`${process.env.REACT_APP_API_URL}/api/proforma_invoice_view_get/${instanceId}/${id}`);
      
      if (response.success) {
        // Extract base64 PDF data and filename from the response
        const fileData = response.file;
        const filename = response.filename;

        // Create a link element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = fileData;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.setState({ downloadStarted: true }, () => {
          setTimeout(() => {
            window.location.href = 'https://simblebooks.com/';
          }, 500);
        });
      } else {
        throw new Error(response.message || "Failed to retrieve the file.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      this.setState({ error: "There was an error downloading the file. Please try again later." });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ProformaView);
