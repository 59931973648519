import { call, put, takeEvery } from "redux-saga/effects"

import {
  getStatesSuccess,
  getStatesFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { StatesTypes } from "./actionTypes"

export const getStates = () => get(process.env.REACT_APP_API_URL + '/api/states')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

export function* fetchStates() {
  try {
    const response : apiRes = yield call(getStates);  
    if (response.success) {
      yield put(getStatesSuccess(response)) 
    } 
  } catch (error) {
    yield put(getStatesFail(error))
  }
}


function* stateSaga() {  
  yield takeEvery(StatesTypes.GET_STATES, fetchStates);
}

export default stateSaga
