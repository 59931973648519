import { call, put, takeEvery } from "redux-saga/effects"

import {
  getUnitsSuccess,
  getUnitsFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { UnitsTypes } from "./actionTypes"

export const getUnits = () => get(process.env.REACT_APP_API_URL + '/api/units')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchUnits() {
  try {
    const response : apiRes = yield call(getUnits);  
    if (response.success) {
      yield put(getUnitsSuccess(response))
    } 
  } catch (error) {
    yield put(getUnitsFail(error))
  }
}


function* unitSaga() {  
  yield takeEvery(UnitsTypes.GET_UNITS, fetchUnits);
}

export {fetchUnits}
export default unitSaga
