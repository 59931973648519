import React, { useEffect, useState } from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import { get, post } from "src/helpers/api_helper";

interface Data {
  customer_name: string;
  shipping_address1: string;
  shipping_address2: string;
  shipping_town_or_city: string;
  shipping_country: string;
  shipping_pincode: string;
  shipping_state: string;
  home_country: string;
}

function ShippingAddressSaveModal(props: any) {
  const [isProgress, setIsProgress] = useState(false);
  const [shipping_is_indian_state, set_shipping_is_indian_state] = useState(0);
  const [data, setData] = useState<Data>({
    customer_name: "",
    shipping_address1: "",
    shipping_address2: "",
    shipping_town_or_city: "",
    shipping_country: "",
    shipping_pincode: "",
    shipping_state: "",
    home_country: "99",
  });
  const { countries, states } = useSelector((state: any) => ({
    states: state.states.states,
    countries: state.countries.countries,
  }));
  const handleInvalidSubmit = (event: any, errors: any, values: any) => {};
  const handleValidSubmit = async (event: any, values: any) => {
    setIsProgress(true);
    values["id"] = props.active_address_id;
    values["customer_id"] = props.active_customer;
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/shipping_address_save",
      values
    );
    setIsProgress(false);
    if (resp.success == true) {
      setData({
        customer_name: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_town_or_city: "",
        shipping_country: "",
        shipping_pincode: "",
        shipping_state: "",
        home_country: "99",
      });
      props.closeModal();
    }
  };
  const onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let newData: any = data;
    newData[name] = value;
    setData(newData);
    if (name == "shipping_country" && value == data.home_country) {
      set_shipping_is_indian_state(1);
    } else {
      set_shipping_is_indian_state(0);
    }
  };
  const getAddress = async () => {
    let shipping_country = "";
    if (props.active_address_id) {
      const gstResp = await get(
        process.env.REACT_APP_API_URL +
          "/api/shipping_address_get/" +
          props.active_address_id
      );
      shipping_country = gstResp.data.shipping_country
      if (shipping_country == data.home_country) {
        set_shipping_is_indian_state(1);
      } else {
        set_shipping_is_indian_state(0);
      }
      setData({
        customer_name: gstResp.data.customer_name,
        shipping_address1: gstResp.data.shipping_address1,
        shipping_address2: gstResp.data.shipping_address2,
        shipping_town_or_city: gstResp.data.shipping_town_or_city,
        shipping_country: gstResp.data.shipping_country,
        shipping_pincode: gstResp.data.shipping_pincode,
        shipping_state: gstResp.data.shipping_state,
        home_country: "99",
      });
    }
  };
  useEffect(() => {
    if (props.modal_is_open) {
      set_shipping_is_indian_state(0);
      setData({
        customer_name: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_town_or_city: "",
        shipping_country: "",
        shipping_pincode: "",
        shipping_state: "",
        home_country: "99",
      });
    }
    if (props.active_address_id) {
      getAddress();
    }
  }, [props.modal_is_open]);
  return (
    <React.Fragment>
      <Modal isOpen={props.modal_is_open} className="md-700 cusCreatePopup">
        <AvForm
          onValidSubmit={handleValidSubmit}
          onInvalidSubmit={handleInvalidSubmit}
        >
          <div className="row d-flex align-items-center">
            <div className="col-md-12">
              <div className="item_form border-none-sm pt-0 pb-0">
                <div className="row">
                  <h5 className="mb-3">Shiping Address</h5>
                </div>
                <div>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      Customer Name
                    </Label>
                    <Col>
                      <AvField
                        name="customer_name"
                        type="text"
                        placeholder="Enter Customer Name"
                        // errorMessage="Enter Customer Name"
                        value={data.customer_name}
                        validate={{
                          required: true,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      Flat/House/Building
                    </Label>
                    <Col>
                      <AvField
                        name="shipping_address1"
                        type="text"
                        placeholder="Enter Address"
                        // errorMessage="Enter Address"
                        value={data.shipping_address1}
                        validate={{
                          required: true,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      Area/Street/Village
                    </Label>
                    <Col>
                      <AvField
                        name="shipping_address2"
                        type="text"
                        placeholder="Enter Address"
                        // errorMessage="Enter Address"
                        value={data.shipping_address2}
                        // validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      Town/City
                    </Label>
                    <Col>
                      <AvField
                        name="shipping_town_or_city"
                        type="text"
                        placeholder="Enter Town Or City"
                        errorMessage="Enter Town Or City"
                        value={data.shipping_town_or_city}
                        // validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      Country
                    </Label>

                    <Col>
                      <AvField
                        type="select"
                        name="shipping_country"
                        value={data.shipping_country}
                        validate={{
                          required: true,
                        }}
                        onChange={onChangehandler}
                      >
                        <option key={-1} value={""}>
                          --Select--
                        </option>
                        {countries.map(
                          (country: { id: string; name: string }) => (
                            <option value={country.id} key={country.id}>
                              {country.name}
                            </option>
                          )
                        )}
                      </AvField>
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      State / Province
                    </Label>

                    <Col>
                      {shipping_is_indian_state ? (
                        <AvField
                          type="select"
                          name="shipping_state"
                          value={data.shipping_state}
                          validate={{
                            required: true,
                          }}
                        >
                          <option key={-1} value={""}>
                            --Select--
                          </option>
                          {states.map(
                            (state: { id: string; state: string }) => (
                              <option value={state.id} key={state.id}>
                                {state.state}
                              </option>
                            )
                          )}
                        </AvField>
                      ) : (
                        <AvField
                          name="shipping_state"
                          type="text"
                          placeholder="Enter state or province"
                          // errorMessage="Enter state or province"
                          value={data.shipping_state}
                          validate={{ required: { value: true } }}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-4 col-lg-4 col-form-label"
                    >
                      Pincode
                    </Label>
                    <Col>
                      <AvField
                        name="shipping_pincode"
                        type="text"
                        placeholder="Enter Pincode"
                        errorMessage="Enter Pincode"
                        value={data.shipping_pincode}
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            {isProgress ? (
              <>
                <Button
                  type="submit"
                  className="bmt-btn-submit itemsubmit"
                  disabled={true}
                >
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  Submitting
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  onClick={() => props.closeModal()}
                  className="bmt-btn-close itemsubmit"
                  data-dismiss="modal"
                >
                  Close
                </Button>{" "}
                <Button type="submit" className="bmt-btn-submit itemsubmit">
                  Submit
                </Button>
              </>
            )}
          </div>
        </AvForm>
      </Modal>
      <div></div>
    </React.Fragment>
  );
}

export default ShippingAddressSaveModal;
