import { call, put, takeEvery } from "redux-saga/effects"

import {
  getTdsHeadSuccess,
  getTdsHeadFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { TdsHeadTypes } from "./actionTypes"

export const getTdsHead = () => get(process.env.REACT_APP_API_URL + '/api/tdsHead')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchtdshead() {
  try {
    const response : apiRes = yield call(getTdsHead);  
    if (response.success) {
      yield put(getTdsHeadSuccess(response))
    } 
  } catch (error) {
    yield put(getTdsHeadFail(error))
  }
}


function* tdsHeadSaga() {  
  yield takeEvery(TdsHeadTypes.GET_TDSHEAD, fetchtdshead);
}

export {fetchtdshead}
export default tdsHeadSaga
