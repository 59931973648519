import { call, put, takeEvery } from "redux-saga/effects"

import {
  getCurrenciesSuccess,
  getCurrenciesFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { CurrenciesTypes } from "./actionTypes"

export const getCurrencies = () => get(process.env.REACT_APP_API_URL + '/api/currencies')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

export function* fetchCurrencies() {
  try {
    const response : apiRes = yield call(getCurrencies);  
    if (response.success) {
      yield put(getCurrenciesSuccess(response))
    } 
  } catch (error) {
    yield put(getCurrenciesFail(error))
  }
}


export default function* currencySaga() {  
  yield takeEvery(CurrenciesTypes.GET_CURRENCIES, fetchCurrencies);
}