import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Import Editor
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { get, post } from "src/helpers/api_helper";
import { connect } from "react-redux";

interface Company {
  invoice_email_template: string;
}

interface Params extends RouteComponentProps {
  id: string;
  isOpen: boolean;
  toggle: () => void;
  email: string;
  receipt_no: string;
  companies: Company;
}

type State = {
  id: string;
  modal: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  receipt_no: string;
};

class SendReceiptModel extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    const html = `<p>download receipt</p>`;
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.state = {
      id: "",
      modal: false,
      isSending: false,
      editorState: EditorState.createWithContent(content),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      receipt_no: "",
    };
  }

  async componentDidMount() {
    try {
      // Fetch dynamic content from API
      // const response = await get(process.env.REACT_APP_API_URL + '/api/get_email_templates');
      
      // if (response.success) {
      //   const html = response.data.invoice_email_template; // Assuming the API response has a 'content' property
        const html = this.props.companies.invoice_email_template;       
        const blocksFromHTML = convertFromHTML(html || '');
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
  
        this.setState({
          editorState: EditorState.createWithContent(content),
        });
      /* } else {
        console.error("Failed to fetch dynamic content");
      } */
    } catch (error) {
      console.error("Error fetching dynamic content", error);
    }
  }
  
  componentWillReceiveProps(props: Params) {
    this.setState({
      id: props.id,
      modal: props.isOpen,
      email: props.email,
      receipt_no: props.receipt_no,
    });
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };

  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/send_payment_receipt",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <ModalHeader toggle={this.props.toggle}>Compose</ModalHeader>
              <ModalBody>
                <UncontrolledAlert
                  hidden={this.state.isHideSuccessMgs}
                  color="success"
                >
                  Mail send successfully
                </UncontrolledAlert>

                <UncontrolledAlert
                  hidden={this.state.isHideErrorMgs}
                  color="danger"
                >
                  Somthing went wrong, Please try again
                </UncontrolledAlert>

                <div className="mb-3">
                  <AvField
                    name="email"
                    type="email"
                    placeholder="To"
                    value={this.state.email}
                    validate={{ required: { value: true } }}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    value={`Receipt #${this.state.receipt_no}`}
                    validate={{ required: { value: true } }}
                  />
                </div>
                <AvField
                  name="content"
                  tag={Editor}
                  placeholder="content"
                  value={""}
                  validate={{ required: { value: false } }}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                  defaultEditorState={this.state.editorState}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  className="bmt-btn-close itemsubmit"
                  onClick={() => this.props.toggle()}
                >
                  Close
                </Button>
                {this.state.isSending ? (
                  <Button
                    type="submit"
                    className="bmt-btn-submit"
                    disabled={true}
                  >
                    Sending{" "}
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </Button>
                ) : (
                  <Button type="submit" className="bmt-btn-submit itemsubmit">
                    Send <i className="fab fa-telegram-plane ms-1"></i>
                  </Button>
                )}
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state: {
  companies: any;  
}) => {
  return {
    companies: state.companies.companies    
  };
};
export default connect(mapStateToProps)(withRouter(SendReceiptModel));
