import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

// Import Editor
import { AvForm, AvField } from "availity-reactstrap-validation";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { post, get } from "src/helpers/api_helper"; // Assuming post and get are available

interface Params extends RouteComponentProps {
  id: string;
  isOpen: boolean;
  toggle: () => void;
  customer_name: string;
}

type State = {
  id: string;
  customer_name: string;
  modalInfo: boolean;
  isHideSuccessMgs: boolean;
  modelLedger: boolean;
  note: string;
  isProgress: number;
};

class CustomerNote extends Component<Params, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      customer_name: "",
      modalInfo: false,
      isHideSuccessMgs: true,
      modelLedger: false,
      isProgress: 0,
      note: "",
    };
  }
  async componentDidUpdate(prevProps: Params) {
    if (
      this.props.id !== prevProps.id ||
      this.props.isOpen !== prevProps.isOpen
    ) {
      this.setState({
        id: this.props.id,
        modalInfo: this.props.isOpen,
        customer_name: this.props.customer_name,
      });
      if (this.props.id !== "0") {
        await this.fetchNote();
      }
    }
  }
  fetchNote = async () => {
    try {
      const response = await get(
        `${process.env.REACT_APP_API_URL}/api/customer_comment/${this.props.id}`
      );
      if (response.success) {
        this.setState({ note: response.note || "" });
      }
    } catch (error) {
      console.error("Error fetching note:", error);
    }
  };
  saveNote = async () => {
    this.setState({ isProgress: 1 });
    try {
      const { id, note } = this.state;
      const payload = {
        id,
        note,
      };
      const response = await post(
        process.env.REACT_APP_API_URL + "/api/save_customer_comment",
        payload
      );
      if (response.success) {
        this.downloadPdfClose();
      }
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      this.setState({ isProgress: 0 });
    }
  };
  downloadPdfClose = () => {
    this.setState({ modalInfo: false, note: "" });
    this.props.toggle();
  };

  render() {
    const { note } = this.state;
    const maxNoteLength = 1000;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            <ModalBody style={{ paddingBottom: 0 }}>
              <div className="row pt-0 pb-0">
                <span
                  className="px-2 pb-2"
                  style={{
                    fontSize: 14,
                    marginBottom: "-5px",
                  }}
                >
                  Customer: {this.state.customer_name}
                </span>
              </div>
              <AvForm>
              <AvField
                  name="note"
                  type="textarea"
                  rows="13"
                  placeholder="Note"
                  value={note}
                  maxLength={maxNoteLength}
                  errorMessage={{
                    maxLength: `Note must be less than ${maxNoteLength} characters.`,
                  }}
                  onChange={(e: any) => this.setState({ note: e.target.value })}
                />
              </AvForm>
            </ModalBody>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              {this.state.isProgress ? (
                <Button
                  type="submit"
                  className="bmt-btn-submit w-50"
                  disabled={true}
                >
                  Submitting{" "}
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    className="bmt-btn-close inv-info"
                    onClick={this.downloadPdfClose}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    className="bmt-btn-submit inv-info"
                    onClick={this.saveNote}
                  >
                    Save Note
                  </Button>
                </>
              )}
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerNote);
