import React, { Component } from "react";
import { Alert, Button, Col, Modal, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { post, get } from "src/helpers/api_helper";
import moment from "moment";
import DatePicker from "rsuite/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { round } from "lodash";
import Skeleton from "react-loading-skeleton";

interface Params extends RouteComponentProps {
  modal_is_open: boolean;
  products: Array<any>;
  openModal?: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  closeModal: () => void;
  callback: () => void;
  id?: string | undefined;
}

interface Option {
  label: string;
  value: string;
}

interface State {
  success: number;
  id: string | undefined;
  msg: string;
  dtls: Array<any>;
  prodtls: Array<any>;
  items: Array<any>;
  products: Array<any>;
  conversion_date: Date | null;
  doc_no: string;
  loading: boolean;
  isProgress: number;
}
const createOption = (label: string, value: string) => ({
  label,
  value,
});
class StockConversion extends Component<Params, State> {
  static defaultProps: {
    id: string | undefined;
  };
  _isMounted: boolean;
  constructor(props: Params) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: this.props.id,
      success: 0,
      msg: "",
      conversion_date: new Date(),
      doc_no: "",
      dtls: [
        {
          item_id: { label: "", value: "" },
          qty: 0,
          type: 1,
        },
      ],
      prodtls: [
        {
          item_id: { label: "", value: "" },
          unit_qty: 0,
          unit_price: 0,
          amount: 0,
          type: 2,
        },
      ],
      items: [],
      products: [],
      loading: false,
      isProgress: 0,
    };
  }

  closeThis = () => {
    this.props.closeModal();
  };

  handleItemChange = (newValue: Option | null, actionMeta: any, i: number) => {
    const dtls = [...this.state.dtls];
    dtls[i].item_id = newValue || { label: "", value: "" };
    this.setState({ dtls });
  };

  onChangehandlerDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let dtls = [...this.state.dtls];
    dtls[i][name] = value;
    this.setState({ dtls });
  };

  handleProductChange = (
    newValue: Option | null,
    actionMeta: any,
    i: number
  ) => {
    const prodtls = [...this.state.prodtls];
    prodtls[i].item_id = newValue || { label: "", value: "" };
    this.setState({ prodtls });
  };

  onChangehandlerProDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "unit_qty") {
      dtl["amount"] = round(value * dtl.unit_price, 2);
      dtl["amount"] = dtl["amount"].toFixed(2);
    }
    if (name === "unit_price") {
      if (dtl.unit_qty > 0) {
        dtl["amount"] = round(dtl.unit_qty * value, 2);
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }
    let prodtls = [...this.state.prodtls];
    prodtls[i][name] = value;
    this.setState({ prodtls });
  };
  addRow = () => {
    const dtls = [...this.state.dtls];
    dtls.push({
      item_id: { label: "", value: "" },
      qty: 0,
      type: 1,
    });
    this.setState({ dtls });
  };
  addProRow = () => {
    const prodtls = [...this.state.prodtls];
    prodtls.push({
      item_id: { label: "", value: "" },
      unit_qty: 0,
      unit_price: 0,
      amount: 0,
      type: 2,
    });
    this.setState({ prodtls });
  };

  removeRow = (index: number) => {
    const dtls = [...this.state.dtls];
    if (dtls.length > 1) {
      dtls.splice(index, 1);
      this.setState({ dtls });
    }
  };
  removeProRow = (index: number) => {
    const prodtls = [...this.state.prodtls];
    if (prodtls.length > 1) {
      prodtls.splice(index, 1);
      this.setState({ prodtls });
    }
  };
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isProgress: 1 });
    values["id"] = this.state.id;
    values["dtls"] = this.state.dtls;
    values["prodtls"] = this.state.prodtls;
    values["conversion_date"] = this.state.conversion_date;
    values["doc_no"] = this.state.doc_no;
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/save_stock_conversion",
      values
    );
    if (resp.success == true) {
      this.setState({
        success: 1,
        msg: resp.message,
        loading: false,
        isProgress: 0,
      });
      setTimeout(() => {
        this.props.closeModal();
        this.props.callback();
      }, 1000);
    } else {
      this.setState({
        success: 2,
        msg: resp.message,
        isProgress: 0,
      });
    }
    this.setState({ isProgress: 0 });
  };
  genExpItemOptions = () => {
    const options = this.props.products
      .filter((item: { is_inventory: number }) => item.is_inventory === 1)
      .map((item: { name: string; id: string }) =>
        createOption(item.name, item.id)
      );
    this.setState({ products: options });
    this.setState({ items: options });
  };
  setConversion_date = (date: any) => {
    if (date == null) {
      this.setState({ conversion_date: new Date() });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ conversion_date: formattedDate });
    }
  };
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  componentDidUpdate(prevProps: Params) {
    if (prevProps.products.length !== this.props.products.length) {
      this.genExpItemOptions();
    }
  }
  loadData = async () => {
    if (this.props.id != undefined) {
      const respP = await get(
        process.env.REACT_APP_API_URL +
          "/api/get_stock_conversion/" +
          this.state.id
      );
      if (this._isMounted) {
        const conversion_date = moment(respP.data.conversion_date);
        this.setState({
          conversion_date: new Date(conversion_date.format("YYYY-MM-DD")),
        });
        this.setState({ doc_no: respP.data.doc_no });
        var dtls: Array<any> = [];
        respP.data.dtls.map(
          async (dtl: { item_id: string; qty: number; type: number }) => {
            let item = this.state.items.find(
              ({ value }) => value === dtl.item_id
            );
            const itemOption = createOption(item.label, item.value);
            dtls.push({
              item_id: itemOption,
              qty: dtl.qty,
              type: 1,
            });
          }
        );
        var prodtls: Array<any> = [];
        respP.data.prodtls.map(
          async (prodtl: {
            item_id: string;
            qty: number;
            unit_price: number;
            amount: number;
            type: number;
          }) => {
            let item = this.state.products.find(
              ({ value }) => value === prodtl.item_id
            );
            const itemOption = createOption(item.label, item.value);
            prodtls.push({
              item_id: itemOption,
              unit_qty: prodtl.qty,
              unit_price: prodtl.unit_price,
              amount: prodtl.amount,
              type: 2,
            });
          }
        );
        this.setState({ dtls: dtls });
        this.setState({ prodtls: prodtls });
      }
    }else{
      const respE: any = await get(
        process.env.REACT_APP_API_URL + "/api/get_isc_docNo"
      );
      if (this._isMounted) {
        this.setState({ 
          doc_no: respE.data.doc_no,
        });
      }
    }
  };
  componentDidMount() {
    const fetchData = async () => {
      try {
        this._isMounted = true;
        this.genExpItemOptions();
        this.loadData();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <Modal
        isOpen={this.props.modal_is_open}
        toggle={this.props.openModal}
        className="modal-dialog modal-dialog-centered md-1000 my-0"
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-header d-block border-0 ledger-header justify-content-center py-1">
            {this.state.success === 2 ? (
              <Alert color="danger">{this.state.msg}</Alert>
            ) : null}
            {this.state.success === 1 ? (
              <Alert color="success">{this.state.msg}</Alert>
            ) : null}
            <Row>
              <Col lg={6} className="text-primary">
                <h4 className="modal-title mt-0 fw-normal">Stock Conversion</h4>
                <span>
                  Use this form for the process of converting raw materials into
                  finished products or final products that are ready for sale or
                  use.
                </span>
              </Col>
              <Col lg={3} className="align-items-center">
                <div className="mt-4">
                  {/* <label htmlFor="docNoInput">Doc No:</label> */}
                  <AvField
                    type="text"
                    id="docNoInput"
                    name="doc_no"
                    placeholder="Doc No"
                    readOnly={true}
                    value={this.state.doc_no}
                    validate={{
                      required: { value: true },
                    }}
                    className="form-control h-25"
                    onChange={this.onChangehandler}
                  />
                </div>
              </Col>
              <Col lg={3} className="align-items-center">
                <div className="mt-4">
                {/* <label htmlFor="docNoInput">Date:</label> */}
                  <DatePicker
                    oneTap
                    onChange={this.setConversion_date}
                    name="conversion_date"
                    format="dd/MM/yyyy"
                    menuClassName="custom-datepicker-dropdown"
                    style={{ width: "100%" }}
                    placeholder="date:"
                    value={
                      this.state.conversion_date
                        ? this.state.conversion_date
                        : null
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-body py-0">
              <Row>
                <Col xs="12">
                  <div className="form-inline">
                    <Row>
                      <div className="row">
                        <div className="table-responsive unset-overflow mb-2">
                          {/* Stock Out Table */}
                          <Table className="table mb-0 table-borderless -0 tbl-stock-conversion">
                            <thead>
                              <tr>
                                <th colSpan={2}>
                                  <h5 className="text-center text-danger fw-normal">
                                    Stock Out *
                                  </h5>
                                </th>
                                <th>
                                  <Button
                                    className="btn btn-primary"
                                    onClick={this.addRow}
                                  >
                                    <i className="bx bx-plus"></i>
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.dtls.map((dtl, i) => (
                                <tr key={i}>
                                  <td style={{ width: "50%" }}>
                                    <CreatableSelect
                                      isClearable
                                      onChange={(e, actionMeta) =>
                                        this.handleItemChange(e, actionMeta, i)
                                      }
                                      styles={{
                                        option: (provided: any, state: any) => ({
                                          ...provided,
                                          ":hover": {
                                            backgroundColor: "#10a37f",
                                            color: "#fff"
                                          },
                                          backgroundColor: state.isSelected
                                            ? "#10a37f"
                                            : "inherit",
                                        }),
                                      }}
                                      options={this.state.items}
                                      value={dtl.item_id || ""}
                                      name="item_id"
                                      className="me-2"
                                    />
                                  </td>
                                  <td style={{ width: "45%" }}>
                                    <AvField
                                      name="qty"
                                      type="number"
                                      placeholder="Enter Quantity"
                                      errorMessage="Enter Quantity"
                                      value={dtl.qty}
                                      onChange={(e: any) =>
                                        this.onChangehandlerDtl(e, i, dtl)
                                      }
                                      className="me-2"
                                    />
                                  </td>
                                  <td style={{ width: "5%" }}>
                                    <Link
                                      to="#"
                                      className="btn tbl-btn"
                                      onClick={() => this.removeRow(i)}
                                    >
                                      <i className="fas fa-trash text-danger"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        {/* Stock In Table */}
                        <div className="table-responsive unset-overflow mb-2">
                          <Table className="table mb-0 table-borderless -0 tbl-stock-conversion">
                            <thead>
                              <tr>
                                <th colSpan={4}>
                                  <h5 className="text-center text-primary fw-normal">
                                    Stock In *
                                  </h5>
                                </th>
                                <th>
                                  <Button
                                    className="btn btn-primary"
                                    onClick={this.addProRow}
                                  >
                                    <i className="bx bx-plus"></i>
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.prodtls.map((dtl, i) => (
                                <tr key={i}>
                                  <td style={{ width: "50%" }}>
                                    <CreatableSelect
                                      isClearable
                                      onChange={(e, actionMeta) =>
                                        this.handleProductChange(
                                          e,
                                          actionMeta,
                                          i
                                        )
                                      }
                                      styles={{
                                        option: (provided: any, state: any) => ({
                                          ...provided,
                                          ":hover": {
                                            backgroundColor: "#10a37f",
                                            color: "#fff"
                                          },
                                          backgroundColor: state.isSelected
                                            ? "#10a37f"
                                            : "inherit",
                                        }),
                                      }}
                                      options={this.state.products}
                                      value={dtl.item_id || ""}
                                      name="item_id"
                                      className="me-2"
                                    />
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    <AvField
                                      name="unit_qty"
                                      type="number"
                                      placeholder="Enter Quantity"
                                      errorMessage="Enter Quantity"
                                      value={dtl.unit_qty}
                                      onChange={(e: any) =>
                                        this.onChangehandlerProDtl(e, i, dtl)
                                      }
                                      className="me-2"
                                    />
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    <AvField
                                      name="unit_price"
                                      type="number"
                                      placeholder="Average Cost"
                                      errorMessage="Average Cost"
                                      value={dtl.unit_price}
                                      onChange={(e: any) =>
                                        this.onChangehandlerProDtl(e, i, dtl)
                                      }
                                      className="me-2"
                                    />
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    <AvField
                                      name="amount"
                                      type="number"
                                      placeholder="Amount"
                                      errorMessage="Amount"
                                      value={dtl.amount}
                                      readOnly={true}
                                      onChange={(e: any) =>
                                        this.onChangehandlerProDtl(e, i, dtl)
                                      }
                                      className="me-2"
                                    />
                                  </td>
                                  <td style={{ width: "5%" }}>
                                    <Link
                                      to="#"
                                      className="btn tbl-btn"
                                      onClick={() => this.removeProRow(i)}
                                    >
                                      <i className="fas fa-trash text-danger"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* Action Buttons */}
                      <div className="d-flex justify-content-center gap-2 mb-4 mt-2">
                        {this.state.loading ? (
                          <>
                            <Skeleton width={100} height={37} />{" "}
                            <Skeleton width={100} height={37} />
                          </>
                        ) : (
                          <>
                            {this.state.isProgress ? (
                              <>
                                <Button
                                  type="submit"
                                  className="bmt-btn-submit itemsubmit"
                                  disabled={true}
                                >
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  Submitting
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  type="reset"
                                  className="bmt-btn-close itemsubmit"
                                  onClick={this.closeThis}
                                >
                                  Cancel
                                </Button>{" "}
                                <Button
                                  type="submit"
                                  className="bmt-btn-submit itemsubmit"
                                >
                                  Submit
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
          </div>
        </AvForm>
      </Modal>
    );
  }
}

const mapStateToProps = (state: {
  products: any;
  companies: any;
  taxes: any;
  tdshead: any;
}) => ({
  products: state.products.products,
  taxes: state.taxes.taxes,
  companies: state.companies.companies,
  tdshead: state.tdshead.tdshead,
});

export default connect(mapStateToProps)(withRouter(StockConversion));
