import {BankOrCashTypes} from "./actionTypes"

const INIT_STATE = {
  bankorcash: [],  
  error: {},
}

const bankorcash = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case BankOrCashTypes.GET_BANK_OR_CASH_SUCCESS:
      return {
        ...state,
        bankorcash: action.payload.data.results,
      }
    case BankOrCashTypes.GET_BANK_OR_CASH:
      return {
        ...state,
        bankorcash: state.bankorcash,
      }
    case BankOrCashTypes.GET_BANK_OR_CASH_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default bankorcash
