import {CustomersTypes} from "./actionTypes"

export const getCustomers = () => ({
  type: CustomersTypes.GET_CUSTOMERS,
})

export const getCustomersSuccess = (customers : any) => ({
  type: CustomersTypes.GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = (error : any) => ({
  type: CustomersTypes.GET_CUSTOMERS_FAIL,
  payload: error,
})



