import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Col,
  Label,
  Modal,
  Row,
  Table,
  UncontrolledAlert,
  FormGroup,
  Button,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { post, get, del } from "src/helpers/api_helper";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import Moment from "moment";

import Select from "react-select";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";
import { connect } from "react-redux";
import CreatableSelect from "react-select/creatable";
import ExpItemModal from "../ExpItems/ExpItemModal";
import { setbankorcashSuccess } from "src/store/actions";
import moment from "moment";
import DatePicker from "rsuite/DatePicker";

interface Option {
  label: string;
  value: string;
}
interface Params extends RouteComponentProps {
  modal_is_open: boolean;
  openModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
  exp_items?: Array<any>;
  bankorcash?: any;
  setCashOrBank: () => void;
  handleRefresh: () => void;
}
const createOption = (label: string, value: string, heads_id: string) => ({
  label,
  value,
  heads_id,
});

type State = {
  banksCash: Array<any>;
  items: Array<any>;
  dtls: Array<any>;
  isModalProgress: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  income_date: Date | null;
  income_number: number;
  income_prefix: string;
  modalExpItem: boolean;
  newItemName: string;
  valueItem: Option | null | undefined;
  selectedOption: Option | null;
  selectedItem: Option | null;
  error: string;
  errorAmount: string;
  errorItem: string;
};

class IncomeCreate extends Component<Params, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      income_number: 0,
      income_prefix: "",
      income_date: new Date(),
      banksCash: [],
      items: [],
      dtls: [
        {
          income_no: 0,
          item_id: { label: "", value: "" },
          amount: 0,
          heads_id: { label: "", value: "" },
          description: "",
        },
      ],
      isModalProgress: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      valueItem: undefined,
      modalExpItem: false,
      newItemName: "",
      selectedOption: null,
      selectedItem: null,
      error: "",
      errorAmount: "",
      errorItem: "",
    };
  }
  removeRow = (i: number) => {
    const dtls = this.state.dtls;
    dtls.splice(i, 1);

    const filterDtls = dtls.map((dtl, i) => {
      dtl.income_no = this.state.income_number + i;
      return dtl;
    });

    this.setState({ dtls: filterDtls });
  };
  addRow = () => {
    const max_exp_no: number = this.state.dtls.reduce(
      (Pre, Cur) => (Pre = Math.max(Pre, Cur.income_no)),
      this.state.income_number
    );
    let newRaw = {
      income_no: max_exp_no + 1,
      item_id: { label: "", value: "" },
      amount: 0,
      heads_id: { label: "", value: "" },
      description: "",
    };
    this.setState({ dtls: [...this.state.dtls, newRaw] });
  };
  onChangehandlerDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let dtls = this.state.dtls;
    if (name == "amount") {
      if (!value) {
        this.setState({ errorAmount: "Please enter an amount" });
      } else {
        this.setState({ errorAmount: "" });
      }
    }
    dtl[name] = value;
    dtls[i] = dtl;
    this.setState({ dtls: dtls });
  };
  handleItemChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>,
    i: number,
    dtl: any
  ) => {
    let dtls = this.state.dtls;
    if (newValue?.value) {
      dtl.item_id = newValue;
    } else {
      dtl.item_id = "";
    }
    dtls[i] = dtl;
    this.setState({
      dtls: dtls,
      selectedItem: newValue || null,
      errorItem: newValue ? "" : "Please select an item",
    });
  };

  handleBankChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>,
    i: number,
    dtl: any
  ) => {
    let dtls = this.state.dtls;
    if (newValue?.value) {
      const selectedBank = this.state.banksCash.find(
        bankCashbook => bankCashbook.value === newValue.value
      );
      dtl.heads_id = selectedBank?.heads_id || null;
      dtl.bank_cashbook_id = newValue.value;
    } else {
      dtl.heads_id = null;
      dtl.bank_cashbook_id = "";
    }
    dtls[i] = dtl;
    this.setState({
      dtls: dtls,
      selectedOption: newValue || null,
      error: newValue ? "" : "Please select an option",
    });
  };
  checkOptionValue = () => {
    const selectedOption = this.state.selectedOption;
    if (selectedOption && selectedOption.value) {
      this.setState({ error: "" });
    } else {
      this.setState({ selectedOption: null, error: "Please select an option" });
    }
  };

  handleValidSubmit = async (event: any, values: any) => {
    let hasError = false;
    if (!this.state.dtls[0].amount) {
      this.setState({ errorAmount: "Please enter an amount" });
      hasError = true;
    }
    const heads_id = this.state.dtls[0].heads_id;
    if (!heads_id || heads_id.value === "") {
      this.setState({ error: "Please select a mode" });
      hasError = true;
    }
    const item_id = this.state.dtls[0].item_id;
    if (!item_id || item_id.value === "") {
      this.setState({ errorItem: "Please select an item" });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    this.setState({ isModalProgress: true });
    const selectedOption = values.bank_cashbook_id;

    if (selectedOption) {
      const bank_cashbook_id = selectedOption.value;
      const heads_id = selectedOption.heads_id;
      values["bank_cashbook_id"] = bank_cashbook_id;
      values["heads_id"] = heads_id;
    }
    values["dtls"] = this.state.dtls;
    values["id"] = 0;
    values["income_date"] = this.state.income_date;
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/income_save",
      values
    );
    if (resp.success == true) {
      this.setState({
        isHideSuccessMgs: false,
      });      
      this.props.setCashOrBank();
      this.props.handleRefresh();
      this.props.closeModal();
    } else {
      this.setState({
        isHideErrorMgs: false,
      });
    }
    this.setState({ isModalProgress: false });
    
  };

  componentDidUpdate(prevProps: Params) {
    if (prevProps.exp_items?.length != this.props.exp_items?.length) {
      this.genExpItemOptions();
    }
  }
  genExpItemOptions = () => {
    let options: Option[] = [];
    this.props.exp_items?.filter((item: { is_system: number }) => item.is_system === 0)
    .sort((a: { category_id: string }, b: { category_id: string }) => {
      const catA = parseInt(a.category_id, 10);
      const catB = parseInt(b.category_id, 10);
      if (catA === 4 && catB !== 4) return -1;
      if (catA !== 4 && catB === 4) return 1;
      return 0;
    })
    .map(
      (item: { item_name: string;category_name: string; id: string; heads_id: string }) => {
        let newOption = createOption((item.item_name +' ('+item.category_name+')'), item.id, item.heads_id);
        options.push(newOption);
      }
    );
    this.setState({ items: options });
  };
  genBankCashOptions = () => {
    let options: Option[] = [];
    this.props.bankorcash?.map(
      (bankCashbook: { name: string; id: string; heads_id: string }) => {
        let newOption = createOption(bankCashbook.name, bankCashbook.id, bankCashbook.heads_id);
        options.push(newOption);
      }
    );
    this.setState({ banksCash: options });
  };
  componentDidMount() {
    this.genBankCashOptions();
    const fetchData = async () => {
      try {
        this.genExpItemOptions();
        let urls = [
          process.env.REACT_APP_API_URL + "/api/get_income_no",
        ];
        Promise.all(urls.map(url => get(url))).then(
          ([respE]) => {
            this.setState({
              income_number: respE.data.income_no,
              income_prefix: respE.data.income_prefix,
            });
            let newRaw = {
              income_no: respE.data.income_no,
              item_id: { label: "", value: "" },
              amount: 0,
              heads_id: { label: "", value: "" },
              description: "",
              bank_cashbook_id: "",
            };
            this.setState({ dtls: [newRaw] });
          }
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }
  set_date_receipt = (date: any) => {
    if (date == null) {
      this.setState({ income_date: null });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ income_date: formattedDate });
    }
  };
  openModalExpItem = () => {
    this.setState({ modalExpItem: true });
  };
  openModalExpItemCreate = (inputValue: string) => {
    this.setState({ modalExpItem: true, newItemName: inputValue });
  };
  closeModalExpItem = () => {
    this.setState({ modalExpItem: false });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modal_is_open}
        toggle={this.props.openModal}
        className={"md-700"}
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-body">
            <UncontrolledAlert
              hidden={this.state.isHideSuccessMgs}
              color="success"
            >
              Incomes save successfully
            </UncontrolledAlert>

            <UncontrolledAlert
              hidden={this.state.isHideErrorMgs}
              color="danger"
            >
              Somthing went wrong, Please try again
            </UncontrolledAlert>
            <div className="">
              <Row className="green_form pt-0 pb-0">
                <div
                  className="item_form pt-0 pb-0 ps-2 pe-2"
                  style={{ borderLeft: "none" }}
                >
                  <div className="row pt-0 pb-1">
                    <div className="heading">Add Income</div>
                  </div>
                  <Row>
                    <Label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                      style={{ color: "#000" }}
                      for="horizontal-date_receipt-input"
                    >
                      Date
                    </Label>
                    <div className="col-8">
                    <DatePicker
                        oneTap
                        onChange={this.set_date_receipt}
                        name="date_receipt"
                        format="dd/MM/yyyy"
                            placeholder="Enter Date of receipt"
                        menuClassName="custom-datepicker-dropdown"
                        style={{ width: "100%" }}
                        value={
                          this.state.income_date
                            ? this.state.income_date
                            : null
                        }
                      />
                    </div>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                      style={{ color: "#000" }}
                      for="horizontal-income_no-input"
                    >
                      Income No
                    </Label>
                    <div className="col-8">
                      <AvField
                        name="income_no"
                        type="text"
                        placeholder="Income No"
                        value={`${this.state.income_prefix}${this.state.dtls[0].income_no}`}
                        onChange={(e: any) =>
                          this.onChangehandlerDtl(e, 0, this.state.dtls[0])
                        }
                        readOnly={true}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                      style={{ color: "#000" }}
                      for="horizontal-item-input"
                    >
                      Item
                    </Label>
                    <div className="col-8">
                      <CreatableSelect
                        isClearable={true}
                        onChange={(
                          newValue: OnChangeValue<Option, false>,
                          actionMeta: ActionMeta<Option>
                        ) =>
                          this.handleItemChange(
                            newValue,
                            actionMeta,
                            0,
                            this.state.dtls[0]
                          )
                        }
                        styles={{
                          option: (provided: any, state: any) => ({
                            ...provided,
                            ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                            backgroundColor: state.isSelected
                              ? "#10a37f"
                              : "inherit",
                          }),
                        }}
                        onCreateOption={this.openModalExpItemCreate}
                        options={this.state.items}
                        value={this.state.dtls[0].item_id} 
                        name="item_id2"
                      />
                      {this.state.errorItem && (
                        <div className="error-message" style={{ color: "red" }}>
                          {this.state.errorItem}
                        </div>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-amount-input"
                      className="col-sm-4 col-form-label"
                      style={{ color: "#000" }}
                    >
                      Amount
                    </Label>
                    <div className="col-8">
                      <AvField
                        name="amount"
                        type="number"
                        placeholder="Amount"
                        value={this.state.dtls[0].amount}
                        onChange={(e: any) =>
                          this.onChangehandlerDtl(e, 0, this.state.dtls[0])
                        }
                      />
                      {this.state.errorAmount && (
                        <div style={{ color: "red" }}>
                          {this.state.errorAmount}
                        </div>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                      style={{ color: "#000" }}
                      for="horizontal-heads_id-input"
                    >
                      Mode
                    </Label>
                    <div className="col-8">
                      <Select
                        isClearable
                        options={this.state.banksCash}
                        value={this.state.banksCash.find(
                          bankCashbook =>
                            bankCashbook.value === this.state.dtls[0].heads_id
                        )}
                        onChange={(
                          newValue: OnChangeValue<Option, false>,
                          actionMeta: ActionMeta<Option>
                        ) =>
                          this.handleBankChange(
                            newValue,
                            actionMeta,
                            0,
                            this.state.dtls[0]
                          )
                        }
                        name="bank_cashbook_id"
                        key={
                          this.state.banksCash.find(
                            bankCashbook =>
                              bankCashbook.value === this.state.dtls[0].heads_id
                          )?.value
                        }
                      />
                      {this.state.error && (
                        <div style={{ color: "red" }}>{this.state.error}</div>
                      )}
                    </div>
                  </Row>

                  <Row>
                    <Label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                      style={{ color: "#000" }}
                      for="horizontal-item-input"
                    >
                      Description
                    </Label>
                    <div className="col-8">
                      <AvField
                        name="description"
                        type="text"
                        placeholder="Description"
                        value={this.state.dtls[0].description}
                        onChange={(e: any) =>
                          this.onChangehandlerDtl(e, 0, this.state.dtls[0])
                        }
                      />
                    </div>
                  </Row>
                </div>
              </Row>
            </div>

            <div className="modal-footer justify-content-center pb-0 pt-1">
              {this.state.isModalProgress ? (
                <Button
                  type="submit"
                  className="bmt-btn-submit"
                  disabled={true}
                >
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  Submitting
                </Button>
              ) : (
                <div className="modal-footer justify-content-center pb-0 pt-1">
                  <Button
                    type="button"
                    onClick={() => this.props.closeModal()}
                    className="bmt-btn-close itemsubmit"
                    data-dismiss="modal"
                  >
                    Close
                  </Button>{" "}
                  <Button
                    type="submit"
                    onClick={this.checkOptionValue}
                    className="bmt-btn-submit itemsubmit"
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </div>
          {this.state.modalExpItem && (
            <ExpItemModal
              modal_is_open={this.state.modalExpItem}
              openModal={this.openModalExpItem}
              closeModal={this.closeModalExpItem}
              id={"0"}
              item_name={this.state.newItemName}
            />
          )}
        </AvForm>
      </Modal>
    );
  }
}
const mapStateToProps = (state: { bankorcash: any, exp_items: any }) => {
  return {
    exp_items: state.exp_items.exp_items,
    bankorcash: state.bankorcash.bankorcash,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {    
    setCashOrBank: () => {
      setbankorcashSuccess(dispatch);
    },
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(IncomeCreate));
