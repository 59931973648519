import React, { useCallback, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, BreadcrumbItem, Card, CardHeader, CardBody, TabContent, TabPane, NavLink } from "reactstrap";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datatable from "../Datatable";
import { post, del } from "src/helpers/api_helper";
import { Link } from "react-router-dom";
import ProductList from "./ProductList";
import AllList from "./AllList";
import ServiceList from "./ServiceList";
import ProductCreateModalNew from "./ProductCreateModalNew";
import bannerImg from '../../assets/images/gst-banner.jpg';


const Products = () => {
    const title = "Products";
    const breadcrumbItem = "Products List";
    const [activeTab, setActiveTab] = useState<number>(1);
    const [allCount, setAllCount] = useState<number>(0);
    const [productCount, setProductCount] = useState<number>(0);
    const [servicesCount, setServicesCount] = useState<number>(0);
    const [modal_pro_is_open, setProductModalOpen] = useState(false);
    const [productsCount, setProductsCount] = useState<number>(0);
    const [processingCount, setProcessingCount] = useState<number>(0);
    const [refresh, setRefresh] = useState(false);

    const openProductModal = () => {
        setProductModalOpen(true);
    }
    const closeProductModal = () => {
        setProductModalOpen(false);
    }
    const callBack = () => {
        setRefresh(!refresh);
    }
    
    return (
        <React.Fragment>
            <div className="page-content" style={{ minHeight: '685px', background: '#F1F1F1' }}>
                <MetaTags>
                    <title>{title}</title>
                </MetaTags>
                <div className="container-fluid">
                    <Row>
                        <Col xs="12">
                        <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                                <div className="header"> <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                                <button type="button" className="btn btn-primary create" onClick={() => openProductModal()} style={{marginLeft: 228}}><i className="fa fa-plus"></i> Create</button>
                                </div>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="#">{title}</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">{breadcrumbItem}</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardHeader className='cus-card'>
                                    <Row>
                                        <Col xl={6}>
                                        <div className="tabs-wrap">
                                        <ul className="tab_style1 myorder-tabs">

                                        <li className={activeTab == 1 ? "active blue" : "blue"}>
                                                <NavLink
                                                  className="eway-tab-link"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setActiveTab(1)
                                                    }}
                                                >
                                                     <span className="eway-tab">All</span>
                                                     <label className="eway-tab-count">{allCount} Items</label>
                                                </NavLink>
                                            </li>
                                            <li className={activeTab == 2 ? "active grey" : "grey"}>
                                                <NavLink
                                                 className="eway-tab-link"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setActiveTab(2)
                                                    }}
                                                >
                                                 
                                                    <span className="eway-tab">Products</span>
                                                     <label className="eway-tab-count">{productCount} Items</label>

                                                </NavLink>
                                            </li>
                                            <li className={activeTab == 3 ? "active green" : "green"}>
                                                <NavLink
                                                 className="eway-tab-link"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setActiveTab(3)
                                                    }}
                                                >
                                                    <span className="eway-tab">Services</span>
                                                    <label className="eway-tab-count">{servicesCount} Items</label>

                                                </NavLink>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                        </Col>
                                        <Col xl={6}>
                                        <div className='banner text-xl-end'>
                                                <NavLink to="/">
                                                    <img src={bannerImg} alt="" className='gst-banner' />
                                                </NavLink>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </CardHeader>

                                <CardBody className="pt-0">

                                    <TabContent
                                        activeTab={activeTab}
                                        className="text-muted my-order product-serivces"
                                    >
                                        <TabPane tabId={1}>
                                            <Row>
                                                <Col sm="12">
                                                    <AllList key={1} refresh={refresh} setRefresh={setRefresh} setCount={setAllCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <Row>
                                                <Col sm="12">
                                                    <ProductList key={2} refresh={refresh} setRefresh={setRefresh} setCount={setProductCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <Row>
                                                <Col sm="12">
                                                    <ServiceList key={3} refresh={refresh} setRefresh={setRefresh} setCount={setServicesCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ProductCreateModalNew modal_pro_is_open={modal_pro_is_open} openProductModal={openProductModal} closeProductModal={closeProductModal} parentCallback={callBack} />
        </React.Fragment>
    );
};

export default Products;
