import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container, Input, Label, Modal } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { getProducts } from "src/store/actions";
import HSNModal from "./HSNModal";
import SACModal from "./SACModal";

interface Params extends RouteComponentProps {
  newProductName?: string;
  modal_pro_is_open: boolean;
  openProductModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>;
  closeProductModal: Function;
  parentCallback: Function;
  units?: Array<any>;
  taxes?: Array<any>;
  getProducts?: () => void;
}

type State = {
  id: string;
  name: string | undefined;
  description: string;
  rate: number;
  unit_id: number;
  tax_id: number;
  units: Array<any>;
  taxes: Array<any>;
  type: string;
  hsn_or_sac: string;
  loading: boolean;
  isProgress: number;
  isinventoryApplicable: boolean;
  searchHSNModal: boolean;
  searchSACModal: boolean;
};

class ProductCreateModalNew extends Component<Params, State> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "0",
      name: "",
      description: "",
      unit_id: 0,
      tax_id: 0,
      rate: 0,
      units: [],
      taxes: [],
      type: "product",
      hsn_or_sac: "",
      loading: false,
      isProgress: 0,
      isinventoryApplicable: false,
      searchSACModal: false,
      searchHSNModal: false,
    };
  }

  componentDidUpdate(prevProps: Params) {
    if (prevProps.newProductName !== this.props.newProductName) {
      this.setState({
        name: this.props.newProductName,
      });
      this.loadData();
    }
    if (prevProps.modal_pro_is_open !== this.props.modal_pro_is_open) {
      this.loadData();
    }
  }
  inventorycheck = async (e: any) => {
    if (e.target.checked) {
      this.setState({
        isinventoryApplicable: true,
      });
    } else {
      this.setState({
        isinventoryApplicable: false,
      });
    }
  };

  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    if (name == "type") {
      this.setState({
        hsn_or_sac: "",
      });
    }
    data[name] = value;
    this.setState(data);
  };
  loadData = async () => {
    let units: any = this.props.units;
    let taxes: any = this.props.taxes;
    if (this._isMounted) {
      this.setState({ units: units, taxes: taxes, loading: false });
      this.setState({
        description: "",
        hsn_or_sac: "",
        unit_id: 0,
        tax_id: 0,
        rate: 0,
      });
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isProgress: 1 });
    values["id"] = this.state.id;
    values["type"] = this.state.type;
    if (this.state.isinventoryApplicable == true) {
      values["is_inventory"] = 1;
    } else if (this.state.isinventoryApplicable == false) {
      values["is_inventory"] = 0;
    }
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/product_save",
      values
    );
    if (resp.success === true) {
      let product = { name: resp.product_name, id: resp.product_id };
      this.props.getProducts?.();
      this.props.parentCallback(product);
      this.setState({
        id: "0",
        name: "",
        description: "",
        unit_id: 0,
        tax_id: 0,
        rate: 0,
        units: [],
        taxes: [],
        type: "product",
        hsn_or_sac: "",
        loading: false,
        isProgress: 0,
        isinventoryApplicable: false,
      });
    } else {
    }
    this.setState({ isProgress: 0 });
  };
  SearchHsnOrSAac = () => {
    try {
      if (this.state.type == "service") {
        this.setState({ searchSACModal: !this.state.searchSACModal });
      } else {
        this.setState({ searchHSNModal: !this.state.searchHSNModal });
      }
    } catch (err) {
      console.error(err);
    }
  };
  toggleHSN = () => {
    try {
      this.setState({ searchHSNModal: !this.state.searchHSNModal });
    } catch (err) {
      console.error(err);
    }
  };
  toggleSAC = () => {
    try {
      this.setState({ searchSACModal: !this.state.searchSACModal });
    } catch (err) {
      console.error(err);
    }
  };
  handleReference = async (reference: string) => {
    this.setState({ hsn_or_sac: reference });
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal_pro_is_open}
          toggle={this.props.openProductModal}
          className="md-700"
        >
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <div className="modal-body pt-1">
              <div className="item_form border-none-sm">
                <div className="row">
                  <div className="heading">Create Product/Service</div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label"
                  >
                    Product name
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      name="name"
                      type="text"
                      placeholder="Enter Product name"
                      errorMessage="Enter Product name"
                      validate={{ required: { value: true } }}
                      value={this.state.name}
                    />
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label sm-d-none"
                  >
                    Product Types
                  </label>
                  <div className="col-sm-8 hstack gap-1 sm-t-1">
                    <AvRadioGroup
                      inline
                      name="type"
                      required
                      onChange={this.onChangehandler}
                      value={this.state.type}
                    >
                      <AvRadio label="Product" value="product" />
                      <AvRadio label="Service" value="service" />
                    </AvRadioGroup>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label sm-d-none"
                  >
                    Inventory Item
                  </label>
                  <div className="col-sm-8 hstack gap-1 sm-t-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={this.inventorycheck}
                      checked={this.state.isinventoryApplicable}
                    />{" "}
                    <label className="mt-2">
                      Enable for inventory tracking
                    </label>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label"
                  >
                    HSN/SAC
                  </label>
                  <div className="col-sm-8 d-flex">
                    <div className="col-md-6">
                      <AvField
                        name="hsn_or_sac"
                        type="text"
                        value={this.state.hsn_or_sac}
                        onChange={this.onChangehandler}
                        readOnly
                      />
                    </div>
                    <div
                      className="col-md-6 ms-1 input-group-text"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.SearchHsnOrSAac()}
                    ><i className="fa fa-search"></i>&nbsp;
                      {this.state.type === "service"
                        ? "Search SAC"
                        : "Search HSN"}
                      
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label"
                  >
                    GST Tax Rate
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      type="select"
                      name="tax_id"
                      errorMessage="Choose Tax"
                      // validate={{ required: { value: true } }}
                      value={this.state.tax_id}
                    >
                      <option value="" key="">
                        --Select--
                      </option>
                      {this.state.taxes.map(tax => (
                        <option value={tax.id} key={tax.id}>
                          {tax.code}
                        </option>
                      ))}
                    </AvField>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label"
                  >
                    Rate
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      name="rate"
                      type="number"
                      placeholder="Enter rate"
                      errorMessage="Enter rate"
                      // validate={{ required: { value: true } }}
                    />
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label"
                  >
                    Unit
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      type="select"
                      name="unit_id"
                      errorMessage="Choose Unit"
                      // validate={{ required: { value: true } }}
                      value={this.state.unit_id}
                      onChange={this.onChangehandler}
                    >
                      <option value="" key="">
                        --Select--
                      </option>
                      {this.state.units.map(unit => (
                        <option value={unit.id} key={unit.id}>
                          {unit.unit}
                        </option>
                      ))}
                    </AvField>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="progresspill-firstname-input"
                    className="col-sm-4 col-form-label"
                  >
                    Description
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      name="description"
                      type="textarea"
                      placeholder="Enter description"
                      maxLength={500}
                      // errorMessage="Enter description"
                      // validate={{ required: { value: true } }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center pt-0">
              {this.state.loading ? (
                <>
                  <Skeleton width={100} height={37} />{" "}
                  <Skeleton width={100} height={37} />
                </>
              ) : (
                <>
                  {this.state.isProgress ? (
                    <>
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                        disabled={true}
                      >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        Submitting
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        className="bmt-btn-close itemsubmit"
                        onClick={() => this.props.closeProductModal()}
                      >
                        Close
                      </Button>{" "}
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </AvForm>
        </Modal>
        <HSNModal
          isOpen={this.state.searchHSNModal}
          toggle={this.toggleHSN}
          value={this.state.hsn_or_sac}
          parentCallback={this.handleReference}
        ></HSNModal>
        <SACModal
          isOpen={this.state.searchSACModal}
          toggle={this.toggleSAC}
          value={this.state.hsn_or_sac}
          parentCallback={this.handleReference}
        ></SACModal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: { units: any; taxes: any }) => {
  return {
    units: state.units.units,
    taxes: state.taxes.taxes,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductCreateModalNew));
