import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Card, CardBody, Table, Modal } from "reactstrap";
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { get } from "src/helpers/api_helper";
import { number_format } from "src/helpers/common";
import { connect } from "react-redux";
import { isNull } from "lodash";

interface Params extends RouteComponentProps {
  id: string;
  isOpen: boolean;
  close: () => void;
  countries: Array<any>;
  units: Array<any>;
  taxes: Array<any>;
  states: Array<any>;
  customers: Array<any>;
}

type State = {
  isOpen: boolean;
  id: string;
  customers_name: string;
  billing_address: string;
  invoice_prefix: string;
  invoice_no: string;
  rounded_amount: number;
  invoice_date: string | null;
  details: Array<any>;
  tax_details: Array<any>;
  countries: Array<any>;
  customer_currency: string;
  states: Array<any>;
  gross_total: number;
  gross_total_base: number;
};
class InvoiceitemDetailsModel extends Component<Params, State> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "",
      isOpen: true,
      customers_name: "",
      billing_address: "",
      invoice_prefix: "",
      invoice_no: "",
      rounded_amount: 0,
      invoice_date: null,
      details: [],
      tax_details: [],
      countries: [],
      customer_currency: "",
      states: [],
      gross_total: 0,
      gross_total_base: 0,
    };
  }
  fetchData = (id: any) => {
    const sendGetRequest = async () => {
      try {
        const respE: any = await get(
          process.env.REACT_APP_API_URL +
            "/api/invoice_get_details/" +
            this.props.id
        );
        if (this._isMounted) {
          if (respE.success) {
            this.setState({
              details: respE.data.dtls,
              tax_details: respE.data.tax_dtls,
            });
            let invoice_date = respE.data.hdr.invoice_date
              ? moment(respE.data.hdr.invoice_date).format("DD-MM-YYYY")
              : null;
            this.setState({ invoice_prefix: respE.data.hdr.prefix });
            this.setState({ invoice_no: respE.data.hdr.invoice_no });
            this.setState({ rounded_amount: respE.data.hdr.rounded_amount });
            this.setState({ gross_total: respE.data.hdr.gross_total });
            this.setState({
              gross_total_base: respE.data.hdr.gross_total_base,
            });
            this.setState({
              customer_currency: respE.data.hdr.customer_currency,
            });
            this.setState({ countries: this.props.countries });
            this.setState({ states: this.props.states });
            const customer = await this.props.customers.find(
              ({ id }) => id === respE.data.hdr.customer_id
            );
            let billing_state = "";
            if (customer.shipping_country == "99") {
              billing_state = this.state.states.find(
                ({ id }) => id === customer.billing_state
              ).state;
            } else {
              billing_state = customer.shipping_state;
            }
            let billing_country = this.state.countries.find(
              ({ id }) => id === customer.billing_country
            );
            let billing_address = "";
            if (!isNull(customer.billing_address1)) {
              billing_address += `${customer.billing_address1} `;
            }
            if (!isNull(customer.billing_address2)) {
              billing_address += `${customer.billing_address2} `;
            }
            if (!isNull(customer.billing_town_or_city)) {
              billing_address += `${customer.billing_town_or_city}\n`;
            }
            if (!isNull(billing_state)) {
              billing_address += `${billing_state} - `;
            }
            if (!isNull(customer.billing_pincode)) {
              billing_address += `${customer.billing_pincode} `;
            }
            billing_address += `${billing_country.name}`;
            this.setState({
              billing_address: billing_address,
              customers_name: customer.customer_name,
            });
            this.setState({
              invoice_date: invoice_date,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
  };

  loadData = async () => {
    try {
      if (this._isMounted) {
        this.setState({
          id: this.props.id,
          isOpen: this.props.isOpen,
        });
        this.fetchData({
          id: this.props.id,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  calculateTotals = () => {
    let totalAmount = 0;
    let totalTax = 0;
    this.state.details.forEach(item => {
      totalAmount += parseFloat(item.amount) || 0;
    });
    this.state.tax_details.forEach(tax => {
      totalTax += parseFloat(tax.tax_amount) || 0;
    });
    return {
      totalAmount,
      total: totalAmount + totalTax,
    };
  };
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { total, totalAmount } = this.calculateTotals();
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
        >
          <div className="modal-content">
            <div
              className="modal-body inv_modal_body"
            >
              <div className="border-0">
                <CardBody className="p-0">
                  <div className="header-info">
                    <p className="font-size-16 font-weight-500 m-0">
                      {this.state.customers_name}
                    </p>
                    <p className="font-size-14 m-0">
                      {this.state.billing_address}
                    </p>
                    <p className="font-size-14 m-0">
                      Invoice No: {this.state.invoice_prefix}
                      {this.state.invoice_no}
                    </p>
                    <p className="font-size-14 m-0">
                      Invoice Date: {this.state.invoice_date}
                    </p>
                  </div>
                  <div className="mx-2">
                    <Table className="align-middle mb-0 detail_table mt-1">
                      <thead className="p-0">
                        <tr>
                          <th>Item</th>
                          <th style={{ textAlign: "center" }}>Qty</th>
                          <th style={{ textAlign: "center" }}>Rate</th>
                          <th style={{ textAlign: "center" }}>Tax amount</th>
                          <th style={{ textAlign: "center" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody className="p-0">
                        {this.state.details.map(
                          (
                            statement: {
                              item_name: string;
                              qty: number;
                              rate: number;
                              tax_amt: number;
                              amount: number;
                            },
                            index: number
                          ) => (
                            <tr key={index}>
                              <td>{statement.item_name}</td>
                              <td style={{ textAlign: "center" }}>
                                {statement.qty}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.rate)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.tax_amt)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.amount)}
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            {number_format(totalAmount)}
                          </td>
                        </tr>
                        {this.state.tax_details.map(
                          (
                            statement: {
                              label: string;
                              tax_rate: string;
                              tax_amount: number;
                            },
                            index: number
                          ) => (
                            <tr key={index}>
                              <td>
                                {statement.label}({statement.tax_rate})
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: "right" }}>
                                {number_format(statement.tax_amount)}
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td>Round Off</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            {number_format(this.state.rounded_amount)}
                          </td>
                        </tr>
                        <tr>
                          <td>Gross Total({this.state.customer_currency})</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            {number_format(this.state.gross_total)}
                          </td>
                        </tr>
                        {this.state.customer_currency != "INR" && (
                          <tr>
                            <td>Gross Total(INR)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: "right" }}>
                            {number_format(this.state.gross_total_base)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </div>
              <div
                className="text-center w-100 my-2"
                style={{ textAlign: "center", display: "block" }}
              >
                <Button
                  type="button"
                  className="bmt-btn-close inv-info"
                  onClick={() => this.props.close()}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: {
  units: any;
  taxes: any;
  countries: any;
  states: any;
  customers: any;
  products: any;
  companies: any;
  permissions: any;
}) => {
  return {
    units: state.units.units,
    taxes: state.taxes.taxes,
    countries: state.countries.countries,
    states: state.states.states,
    customers: state.customers.customers,
    products: state.products.products,
    companies: state.companies.companies,
    permissions: state.permissions.permissions,
  };
};

export default connect(mapStateToProps)(withRouter(InvoiceitemDetailsModel));
