import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PdfIcon from "src/assets/images/icons/Pdf";
import { post, get } from "src/helpers/api_helper";
import {
  number_format,
  padLeadingZeros,
  predefinedRanges,
} from "src/helpers/common";
import moment from "moment";
import { useHistory } from "react-router-dom";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import InvoiceitemDetailsModel from "./InvoiceitemDetailsModel";

interface Props {
  modal_is_open: boolean;
  openModal?:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
  invoice_id?: string;
  customer_name: string;
  customer_id: string;
}
interface Invoice {
  id: string;
  invoice_no: number;
  due_date: string;
}

interface Statement {
  invoice_no: number;
}

function Ledger(props: Props) {
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()));
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [data, setData] = useState<Array<any> | any>([]);
  const [openDate, setOpendate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [isProgress, setIsProgress] = useState(false);
  const [openBal, setOpenBal] = useState<number>(0);
  const [invPrefix, setPrefix] = useState<string>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [hdr_id, sethdrId] = useState("");
  const [openModalInv, setModalInv] = useState(false);
  const [getInvoiceList, setGetInvoiceList] = useState<Array<any> | any>([]);

  const closeThis = () => {
    setStartDate(null);
    setEndDate(null);
    setData([]);
    setOpenBal(0);
    setIsSubmit(false);
    props.closeModal();
    setOpendate(null);
  };
  const setStartDateEndDate = (dateRange: Array<any>) => {
    const [startDate, endDate] = dateRange;
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const viewInfo = (id: any) => {
    sethdrId(id);
    invoiceisOpen();
  };
  const invoiceisOpen = () => {
    setModalInv(true);
  };
  const invoiceisClose = () => {
    setModalInv(false);
    sethdrId("");
  };
  const stockConversionCallback = () => {
    search(startDate, endDate);
  };
  const setStartDateNew = (dateRange: any) => {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    let start_date = moment(startDate);
    const formatted_start_date = new Date(start_date.format("YYYY-MM-DD"));
    setDateRange(dateRange);
    setStartDate(formatted_start_date);
    let end_date = moment(endDate);
    const formatted_end_date = new Date(end_date.format("YYYY-MM-DD"));
    setEndDate(formatted_end_date);
  };
  const submit = async (startD: Date | null, endD: Date | null) => {
    setIsProgress(true);
    search(startD, endD);
  };
  const search = async (startD: Date | null, endD: Date | null) => {
    try {
      let start_date = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      let postValues = {
        customer_id: props.customer_id,
        startDate: start_date,
        endDate: end_date,
      };
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/customer_statement`,
        postValues
      );
      if (resp.success) {
        setIsProgress(false);
        setOpendate(startDate);
        setData(resp.data.statements);
        setOpenBal(resp.data.openBal);
        setPrefix(resp.data.inv_prefix);
        setIsSubmit(true);
      }
    } catch (err) {
      // Handle Error Here
      setIsProgress(false);
      console.error(err);
    }
  };
  const fetchData = async () => {
    try {
      let postValues = {
        customer_id: props.customer_id,
      };
      const response = await post(
        process.env.REACT_APP_API_URL + `/api/invoice_list_ledger`,
        postValues
      );
      if (response.success) {
        setGetInvoiceList(response.data);
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.modal_is_open) {
      let startD = startOfMonth(new Date());
      let endD = endOfMonth(new Date());
      setStartDate(startD);
      setEndDate(endD);
      search(startD, endD);
      fetchData();
    }
  }, [props.modal_is_open, props.customer_id]);

  const downloadPdf = async () => {
    let start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    let postValues = {
      customer_id: props.customer_id,
      startDate: start_date,
      endDate: end_date,
    };
    const pdfResp = await post(
      process.env.REACT_APP_API_URL + `/api/customer_statement_pdf`,
      postValues
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfResp.file;
    downloadLink.download = pdfResp.fname;
    downloadLink.click();
  };

  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });

  var balance = openBal;
  var totalCredit = 0;
  var totalDebit = 0;
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.openModal}
        className={
          "md-1000 ledger-modal modal-dialog-centered modal-dialog-scrollable h-100 my-0"
        }
      >
        <div className="modal-header d-block ledger-header border-0 justify-content-center pb-0">
          <Row className="pb-2">
            <Col lg={12}>
              <h5 className="modal-title text-center mt-0 fw-normal">
                Customer Statement
              </h5>
            </Col>
            <Col lg={12}>
              <div
                className="text-center text-uppercase"
                style={{ fontSize: 20 }}
              >
                {props.customer_name}
              </div>
            </Col>
            <Col lg={4} className="offset-lg-3 pe-0">
              <DateRangePicker
                ranges={predefinedRanges}
                format="dd/MM/yyyy"
                placeholder=" "
                value={dateRange}
                style={{ width: 330 }}
                onChange={(date: DateRange | null) => setStartDateNew(date)}
              />
            </Col>
            <Col lg={4}>
              {isProgress ? (
                <>
                  <Button
                    type="submit"
                    className="bmt-btn-submit-1"
                    disabled={true}
                  >
                    Submitting
                    <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    style={{ padding: "5px 25px" }}
                    className="bmt-btn-submit me-1"
                    onClick={() => submit(startDate, endDate)}
                  >
                    Submit
                  </Button>
                </>
              )}
              <Link to="#" onClick={(e: any) => downloadPdf()}>
                <button className="btn round-btn btn-pdf" title="PDF">
                  <PdfIcon />
                </button>
              </Link>
            </Col>
          </Row>
          <button
            type="button"
            onClick={() => closeThis()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <div className="mb-3">
            <Row>
              <Col xs="12">
                <div>
                  <div className="form-inline">
                    {isSubmit && (
                      <Row>
                        <Col lg={12}>
                          <Table
                            className="align-middle mb-0 ledger-table mt-1"
                            style={{
                              width: "100%",
                            }}
                            id="printRef"
                          >
                            <thead>
                              <tr>
                                <th style={{ width: "110px" }}>Date</th>
                                <th>Voucher</th>
                                <th
                                  style={{
                                    width: "50px",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "120px",
                                  }}
                                >
                                  Debit
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "120px",
                                  }}
                                >
                                  Credit
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "120px",
                                  }}
                                >
                                  Balance
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{moment(openDate).format("DD/MM/YYYY")}</td>
                                <td>Opening Balance</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>
                                  {number_format(openBal)}
                                </td>
                              </tr>
                              {data.map(
                                (
                                  statement: {
                                    invoice_date: string;
                                    debit: number;
                                    credit: number;
                                    type: number;
                                    heads_id: string;
                                    invoice_no: number;
                                    bank_name: string;
                                    status: number;
                                    prefix: string;
                                    id: string;
                                  },
                                  index: number
                                ) => {
                                  let voucher: JSX.Element | string = "";
                                  let info: JSX.Element | string = "";
                                  const filteredInvoice = getInvoiceList.find(
                                    (invoice: Invoice) =>
                                      invoice.invoice_no ===
                                      statement.invoice_no
                                  );
                                  if (statement.type === 1 && filteredInvoice) {
                                    const due_date = moment(
                                      filteredInvoice.due_date
                                    );
                                    const cur_date = moment();
                                    const days = cur_date.diff(
                                      due_date,
                                      "days"
                                    );
                                    voucher = (
                                      <>
                                        Invoice ({statement.prefix}
                                        {statement.invoice_no})
                                        {(() => {
                                          if (filteredInvoice.status === 4) {
                                            return (
                                              <>
                                                <span
                                                  style={{ display: "block" }}
                                                >
                                                  <span className="status sent ms-3">
                                                    Partially Paid
                                                  </span>
                                                </span>
                                              </>
                                            );
                                          } else if (
                                            filteredInvoice.status === 5
                                          ) {
                                            return (
                                              <>
                                                <span
                                                  style={{ display: "block" }}
                                                >
                                                  <span className="status partially ms-3">
                                                    Paid
                                                  </span>
                                                </span>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span
                                                  style={{ display: "block" }}
                                                >
                                                  <span className="status pending ms-3">
                                                    Unpaid
                                                  </span>
                                                </span>
                                              </>
                                            );
                                          }
                                        })()}
                                      </>
                                    );
                                    info = (
                                      <span
                                        onClick={e => viewInfo(statement.id)}
                                        // className="status info"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fas fa-info-circle info-icon"></i>
                                      </span>
                                    );
                                  } else {
                                    if (statement.heads_id === "1") {
                                      voucher =
                                        "Cash (" +
                                        statement.prefix +
                                        statement.invoice_no +
                                        ")";
                                    } else if (statement.heads_id === "3") {
                                      voucher =
                                        "TDS (" +
                                        statement.prefix +
                                        statement.invoice_no +
                                        ")";
                                    } else if (statement.heads_id === "4") {
                                      voucher =
                                        "Discount (" +
                                        statement.prefix +
                                        statement.invoice_no +
                                        ")";
                                    } else if (statement.heads_id === "5") {
                                      voucher =
                                        "Exchange Gain/Loss (" +
                                        statement.prefix +
                                        statement.invoice_no +
                                        ")";
                                    } else {
                                      voucher =
                                        statement.bank_name +
                                        " (" +
                                        statement.prefix +
                                        statement.invoice_no +
                                        ")";
                                    }
                                  }
                                  balance = Number(
                                    (
                                      Number(balance) +
                                      Number(statement.debit) -
                                      Number(statement.credit)
                                    ).toFixed(2)
                                  );
                                  totalCredit =
                                    Number(totalCredit) +
                                    Number(statement.credit);
                                  totalDebit =
                                    Number(totalDebit) +
                                    Number(statement.debit);
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {moment(statement.invoice_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        <div style={{ display: "flex" }}>
                                          {voucher}
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                        }}
                                      >
                                        {info}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {statement.debit > 0
                                          ? number_format(statement.debit)
                                          : "-"}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {statement.credit > 0
                                          ? number_format(statement.credit)
                                          : "-"}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {number_format(balance)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}

                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>
                                  <b>{number_format(totalDebit)}</b>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <b>{number_format(totalCredit)}</b>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <b>{number_format(balance)}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      {openModalInv && (
        <InvoiceitemDetailsModel
          id={hdr_id}
          isOpen={openModalInv}
          close={invoiceisClose}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(Ledger);
