import React from 'react';

const Addcustomer2Icon = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
  <g id="icons_add_customer" transform="translate(-3.321 6)">
    <g id="Rectangle_1" data-name="Rectangle 1" transform="translate(3.321 -6)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
      <rect width="44" height="44" stroke="none"/>
      <rect x="0.5" y="0.5" width="43" height="43" fill="none"/>
    </g>
    <g id="Group_1" data-name="Group 1" transform="translate(5 -4.2)">
      <path id="Path_1" data-name="Path 1" d="M16.7,36.8A28.07,28.07,0,0,1,2.9,33.1l-.5-.3V31.4c-.2-3.1-.7-11.3,8.5-13.2l.5-.1.4.3a7.6,7.6,0,0,0,9.6,0l.3-.3.4.1a9.337,9.337,0,0,1,3.6,1.4,1.075,1.075,0,0,1,.2,1.4,1.058,1.058,0,0,1-1.4.2,6.715,6.715,0,0,0-2.3-1c-1.4.9-6.2,3.7-11.3,0C3.8,22,4.2,28,4.4,31.3v.3a26.017,26.017,0,0,0,17.7,2.6,1.02,1.02,0,0,1,.4,2A24.62,24.62,0,0,1,16.7,36.8Z" fill="#1d6f41"/>
      <path id="Path_2" data-name="Path 2" d="M16.4,18.8A7.8,7.8,0,1,1,24.2,11,7.81,7.81,0,0,1,16.4,18.8Zm0-13.6A5.8,5.8,0,1,0,22.2,11,5.741,5.741,0,0,0,16.4,5.2Z" fill="#1d6f41"/>
      <path id="Path_3" data-name="Path 3" d="M29.9,36.8A7.8,7.8,0,1,1,37.7,29,7.81,7.81,0,0,1,29.9,36.8Zm0-13.7a5.8,5.8,0,1,0,5.8,5.8A5.8,5.8,0,0,0,29.9,23.1Z" fill="#1d6f41"/>
      <path id="Path_4" data-name="Path 4" d="M29.9,33.2a.945.945,0,0,1-1-1V25.4a1,1,0,0,1,2,0v6.8A1.075,1.075,0,0,1,29.9,33.2Z" fill="#1d6f41"/>
      <path id="Path_5" data-name="Path 5" d="M33.3,29.8H26.5a1,1,0,0,1,0-2h6.8a.945.945,0,0,1,1,1A1,1,0,0,1,33.3,29.8Z" fill="#1d6f41"/>
    </g>
  </g>
</svg>

  );
}

export default Addcustomer2Icon;
