import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Alert,
  Modal,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Creatable, { useCreatable } from "react-select/creatable";
import CreatableSelect from "react-select/creatable";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";
import CustomerCreateModal from "../Customers/CustomerCreateModal";
import ProductCreateModal from "../Products/ProductCreateModal";
import Moment from "moment";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import RenewPlan from "../Payments/RenewPlan";
import { isNull, round } from "lodash";
import { number_format } from "src/helpers/common";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";

import DatePicker from "rsuite/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import ReferenceModal from "../Common/Invoice/ReferenceModal";
import CustomerNoteModal from "../Common/Invoice/CustomerNoteModal";
import TermsAndConditionsModal from "../Common/Invoice/TermsAndConditionsModal";
import moment from "moment";
import InvoiceLeavingGuard from "../Common/InvoiceLeavingGuard";
import ShippngAddressListModal from "../Customers/ShippngAddressListModal";
import MediaQuery from "../Media";
import ItemAddMobile from "../Common/Invoice/ItemAddMobile";
import { get_addrress_type } from "src/helpers/common";
import { addrress_type } from "src/helpers/common";
interface Params extends RouteComponentProps {
  countries: Array<any>;
  units: Array<any>;
  taxes: Array<any>;
  states: Array<any>;
  customers: Array<any>;
  products: Array<any>;
  permissions: Array<any>;
  companies: Company;
}
interface Option {
  label: string;
  value: string;
}
interface State {
  isLoading: boolean;
  options: Option[];
}

interface Company {
  is_indian_state: number;
  company_state: string | undefined;
  company_country: string | undefined;
  currency: string;
  terms_and_conditions: string;
}

interface States extends State {
  id: string | undefined;
  invoice_dtls: Array<any>;
  tax_dtls: Array<any>;
  products: Array<any>;
  customers: Array<any>;
  company: Company;
  taxes: Array<any>;
  net_total: number;
  tax_id: number;
  // discount_type: string | undefined;
  discount: number;
  gross_total: number;
  gross_total_base: number;
  invoice_number: number;
  invoice_prefix: string | undefined;
  data: Array<any>;
  success: number;
  msg: string | undefined;
  isLoading: boolean;
  options: Array<any>;
  isLoad: boolean;
  optionproduct: Array<any>;
  valueProduct: Option | null | undefined;
  valueCustomer: Option | null | undefined;
  newCustomerName: string | undefined;
  newProductName: string | undefined;
  modal_cust_is_open: boolean;
  ref_modal_open: boolean;
  modal_pro_is_open: boolean;
  is_success: boolean;
  isProgress: number;
  isModalProgress: number;
  isValid: boolean;
  cust_name: string | undefined;
  cust_id: string | undefined;
  prod_name: string | undefined;
  prod_id: string | undefined;
  units: Array<any>;
  tax_amount: number;
  customer_currency: string | undefined;
  company_currency: string | undefined;
  currency_rate: number;
  permissions: Array<any>;
  billingAddress: string | undefined;
  shipping_company: string | undefined;
  po_or_estimate_date: Date | null;
  shippingAddress: string | undefined;
  countries: Array<any>;
  banks: Array<any>;
  states: Array<any>;
  loading: boolean;
  due_date: Date | null;
  isPlanExpired: number;
  modalPlan: boolean;
  dropdownVisible: boolean;
  document_type: any;
  label: string;
  message: any;
  reference1: string;
  reference2: string;
  reference3: string;
  reference4: string;
  isRounded: boolean;
  rounded_amount: number;
  note_modal_open: boolean;
  terms_modal_open: boolean;
  footer_note: string;
  termsAndConditions: string;
  invoice_date: Date | null;
  showSubmitButton: boolean;
  currency_value: number;
  shipping_modal_open: boolean;
  shipping_modal_save_open: boolean;
  to_addrress_visible: boolean;
  to_addrress_type: any;
  to_addrress: any;
  bank_cashbook_id: string;
  isAuthorized: boolean;
  current_dtl: any;
  current_i: number;
}
const createOption = (label: string, value: string) => ({
  label,
  value,
});

const createOptionProduct = (label: string, value: string) => ({
  label,
  value,
});

class InvoiceCreate extends Component<Params, States> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "0",
      net_total: 0,
      tax_id: 0,
      // discount_type: "amount",
      discount: 0,
      gross_total: 0,
      gross_total_base: 0,
      invoice_dtls: [
        {
          product_id: { label: "", value: "" },
          product_description: "",
          hsn_or_sac: "",
          qty: 0,
          rate: 0,
          discount_per: 0,
          discount_amt: 0,
          tax_id: "",
          tax_per: 0,
          tax_amt: 0,
          amount: 0,
        },
      ],
      tax_dtls: [],
      products: [],
      customers: [],
      company: {
        is_indian_state: 1,
        company_state: undefined,
        company_country: undefined,
        currency: "INR",
        terms_and_conditions: "",
      },
      taxes: [],
      invoice_number: 0,
      invoice_prefix: undefined,
      data: [],
      success: 0,
      msg: undefined,
      isLoading: true,
      options: [],
      valueCustomer: undefined,
      isLoad: true,
      optionproduct: [],
      valueProduct: undefined,
      modal_cust_is_open: false,
      ref_modal_open: false,
      modal_pro_is_open: false,
      is_success: false,
      isProgress: 0,
      isModalProgress: 0,
      isValid: false,
      cust_name: undefined,
      cust_id: undefined,
      prod_name: undefined,
      prod_id: undefined,
      po_or_estimate_date: null,
      units: [],
      newCustomerName: undefined,
      newProductName: undefined,
      tax_amount: 0,
      customer_currency: "INR",
      company_currency: "INR",
      currency_rate: 0.0,
      permissions: [],
      billingAddress: "",
      shippingAddress: "",
      shipping_company: "",
      countries: [],
      banks: [],
      states: [],
      loading: false,
      isPlanExpired: 0,
      due_date: new Date(),
      modalPlan: false,
      dropdownVisible: false,
      document_type: 1,
      label: "Estimate",
      message: "",
      reference1: "",
      reference2: "",
      reference3: "",
      reference4: "",
      isRounded: true,
      rounded_amount: 0,
      note_modal_open: false,
      footer_note: "",
      terms_modal_open: false,
      termsAndConditions: "",
      invoice_date: new Date(),
      showSubmitButton: false,
      currency_value: 0.0,
      shipping_modal_open: false,
      shipping_modal_save_open: false,
      to_addrress_visible: false,
      to_addrress_type: 1,
      to_addrress: get_addrress_type(1),
      bank_cashbook_id: "",
      current_dtl: "",
      current_i: 0,
      isAuthorized: true,
    };
    this.onChangehandlerDtl = this.onChangehandlerDtl.bind(this);
    this.onChangehandler = this.onChangehandler.bind(this);
  }

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  openShippingModal = () => {
    if (this.state.valueCustomer) {
      this.setState({
        success: 1,
      });
      this.setState({ shipping_modal_open: true });
    } else {
      this.setState({
        success: 2,
        message: "Please select a customer to continue",
      });
    }
  };
  add_item = async (invoice_dtls: any) => {
    this.setState({ invoice_dtls: invoice_dtls });
    setTimeout(() => {
      this.calcTotal();
    }, 1000);
  };
  ChangeShippingAddress = async (address_id: string) => {
    const gstResp = await get(
      process.env.REACT_APP_API_URL + "/api/shipping_address_get/" + address_id
    );
    let shipping_state = undefined;
    let shipping_address = "";
    let shipping_company = gstResp.data.customer_name;
    let shipping_country = this.state.countries.find(
      ({ id }) => id === gstResp.data.shipping_country
    );
    shipping_address += `${gstResp.data.shipping_address1} `;
    shipping_address += `${gstResp.data.shipping_address2} `;
    shipping_address += `${gstResp.data.shipping_town_or_city}\n`;
    if (gstResp.data.shipping_country === "99") {
      shipping_state = this.state.states.find(
        ({ id }) => id === gstResp.data.shipping_state
      ).state;
    } else {
      shipping_state = gstResp.data.shipping_state;
    }
    shipping_address += `${shipping_state} - `;
    shipping_address += `${gstResp.data.shipping_pincode} `;
    if (shipping_country) {
      shipping_address += `${shipping_country.name}`;
    }
    this.setState({
      shippingAddress: shipping_address,
      shipping_company: shipping_company,
    });
    this.setState({ shipping_modal_open: false });
  };
  closeShippingModal = () => {
    this.setState({ shipping_modal_open: false });
  };

  openShippingSaveModal = () => {
    this.setState({ shipping_modal_save_open: true });
  };

  closeShippingSaveModal = () => {
    this.setState({ shipping_modal_save_open: false });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };

  successModalPlan = () => {
    this.setState({ isPlanExpired: 1, modalPlan: false });

    const getAuthUser: any = localStorage.getItem("authUser");
    const objUser = JSON.parse(getAuthUser);
    objUser.isPlanExpired = 1;
    localStorage.setItem("authUser", JSON.stringify(objUser));
  };

  resetForm = () => {
    this.setState({ showSubmitButton: false });
    setTimeout(() => {
      this.props.history.push("/invoices");
    }, 1000);
  };
  onChangeCurrencyRate = async (e: any) => {
    let currency_rate = 0.0;
    let currency_value = 0.0;
    let value = e.target.value;
    currency_value = round(value, 4);
    currency_rate = 1 / value;
    this.setState({
      currency_value: currency_value,
      currency_rate: currency_rate,
    });
    let dtls = this.state.invoice_dtls;
    const products = this.state.products;

    dtls.forEach(async (dtl: any, index: number) => {
      const value = dtl.product_id?.value;
      const product = products.find(({ id }) => id === value);

      if (product) {
        let taxes: Array<any> = [];
        if (this.state.valueCustomer) {
          const company = this.state.company;
          const comp_is_indian_state: number = company.is_indian_state;
          const company_state: string | undefined = company.company_state;

          const customer = await this.props.customers.find(
            ({ id }) => id === this.state.valueCustomer?.value
          );
          const billing_state: string = customer.billing_state;
          const place_supply: number = customer.place_supply;

          if (place_supply == 1) {
            if (company_state == billing_state) {
              taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
            } else {
              taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
            }
          } else {
            taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
          }
        } else {
          taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
        }
        dtl["tax_per"] = taxes.reduce(
          (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
          0
        );
        dtl["rate"] = dtl.rate;
        dtl["rate"] = round(dtl.rate, 2);
        dtl["hsn_or_sac"] = product.hsn_or_sac;
        dtl["product_description"] = product.description;
        if (dtl.qty > 0) {
          dtl["amount"] = round(dtl.qty * dtl.rate, 2);
        } else {
          dtl["qty"] = 1;
          dtl["amount"] = round(1 * dtl.rate, 2);
        }
        dtl["taxable_amount"] = round(dtl.amount, 2);
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["amount"] = parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]);
        dtl["tax_amt"] = round(dtl.tax_amt, 2);
        dtl["amount"] = round(dtl.amount, 2);
        dtl["tax_id"] = product.tax_id;
        dtl["tax_per"] = dtl.tax_per;

        dtl["product_id"] = dtl.product_id;
      } else {
        dtl["qty"] = 0;
        dtl["rate"] = 0;
        dtl["amount"] = 0;
        dtl["tax_amt"] = 0;
        dtl["tax_id"] = "";
        dtl["tax_per"] = 0;
        dtl["hsn_or_sac"] = "";
        dtl["product_description"] = "";
        dtl["product_id"] = { label: "", value: "" };
      }

      dtls[index] = dtl;
    });
    this.setState({ invoice_dtls: dtls });
    setTimeout(() => {
      this.calcTotal();
    }, 1000);
  };
  onChangehandlerestimation = async (e: any) => {
    this.setState({
      success: 1,
    });
    let name = e.target.name;
    let value = e.target.value;
    const respE: any = await get(
      process.env.REACT_APP_API_URL + "/api/check_invoice_no/" + value
    );
    if (respE.success == true) {
      this.setState({
        success: 1,
      });
    } else {
      this.setState({
        success: 2,
        message: respE.message,
      });
    }
  };
  setInvoice_date = (date: any) => {
    this.setState({ showSubmitButton: true });
    if (date == null) {
      this.setState({ invoice_date: new Date() });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ invoice_date: formattedDate });
    }
  };

  setDue_date = (date: any) => {
    this.setState({ showSubmitButton: true });
    if (date == null) {
      this.setState({ due_date: null });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ due_date: formattedDate });
    }
  };
  setPo_Date = (date: any) => {
    this.setState({ showSubmitButton: true });
    if (date == null) {
      this.setState({ po_or_estimate_date: null });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ po_or_estimate_date: formattedDate });
    }
  };
  onChangehandler = async (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
    /* if (name === "discount" && value) {
            let discAmt = parseFloat(value);
            this.setState({ ...this.state, [name]: value });
        } else {
            this.setState({ ...this.state, [name]: value });
        } */
  };
  roundtotal = async (e: any) => {
    if (e.target.checked) {
      this.calcTotal();
      this.setState({
        isRounded: true,
      });
    } else {
      this.calcTotal();
      this.setState({
        isRounded: false,
      });
    }
  };
  sign = async (e: any) => {
    if (e.target.checked) {
      this.setState({
        isAuthorized: true,
      });
    } else {
      this.setState({
        isAuthorized: false,
      });
    }
  };
  calcTotal = async () => {
    this.setState({ showSubmitButton: true });
    const dtls = this.state.invoice_dtls;
    const net_total: number = await dtls.reduce(
      (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.amount)),
      0
    );

    //tax calculation
    const company = await this.state.company;
    const comp_is_indian_state: number = company.is_indian_state;
    const company_state: string | undefined = company.company_state;

    if (this.state.valueCustomer) {
      const customer = await this.props.customers.find(
        ({ id }) => id === this.state.valueCustomer?.value
      );

      const billing_is_indian_state: number = customer.billing_is_indian_state;
      const billing_country: string = customer.billing_country;
      const billing_state: string = customer.billing_state;
      const place_supply: number = customer.place_supply;

      // if (comp_is_indian_state && billing_is_indian_state) {

      let tax_dtls: Array<any> = [];
      dtls.forEach(async item => {
        let product: any = this.state.products.find(
          ({ id }) => id === item.product_id.value
        );
        let taxes: Array<any> = [];

        if (product) {
          if (place_supply == 1) {
            if (company_state == billing_state) {
              let rowTax: any = this.state.taxes.find(
                (tax: any) => tax.id == item.tax_id
              );
              if (rowTax) {
                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
              }
              // taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
            } else {
              let rowTax: any = this.state.taxes.find(
                (tax: any) => tax.id == item.tax_id
              );
              if (rowTax) {
                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
              }
              // taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
            }
          } else {
            let rowTax: any = this.state.taxes.find(
              (tax: any) => tax.id == item.tax_id
            );
            if (rowTax) {
              taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
            }
          }
          if (item.qty > 0 && item.rate > 0) {
            taxes.forEach(async tax => {
              let split_name = tax.split_name;
              let split_per = tax.split_per;
              let row_total =
                item.qty * item.rate - parseFloat(item.discount_amt);
              let tax_amount = (row_total * split_per) / 100;
              tax_amount = parseFloat(tax_amount.toFixed(2));
              let newRaw = {
                label: split_name,
                tax_rate: split_per,
                tax_amount: tax_amount,
              };
              tax_dtls.push(newRaw);
            });
          }
        }
      });

      //sum the tax per
      let tax_dtls2: Array<any> = [];
      tax_dtls.forEach((a, index) => {
        let tax_amount = tax_dtls
          .filter(el => {
            return el.label == a.label && el.tax_rate == a.tax_rate;
          })
          .reduce(
            (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.tax_amount)),
            0
          );

        if (isNaN(tax_amount)) {
          tax_amount = 0;
        }

        tax_dtls2.push({
          label: a.label,
          tax_rate: a.tax_rate,
          tax_amount: tax_amount,
        });
      });

      tax_dtls = tax_dtls2.filter(
        (arr, index, self) =>
          index ===
          self.findIndex(
            t => t.label === arr.label && t.tax_rate === arr.tax_rate
          )
      );

      this.setState({ tax_dtls: tax_dtls });
    }

    const tax_amount: number = await this.state.tax_dtls.reduce(
      (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.tax_amount)),
      0
    );
    this.setState({ tax_amount: tax_amount });
    // const discAmt: number = await this.state.discount_type == "per" ? (this.state.net_total * this.state.discount / 100) : this.state.discount;
    let discAmt: number = await round(
      this.state.invoice_dtls.reduce(
        (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.discount_amt)),
        0
      ),
      2
    );
    if (isNaN(discAmt)) {
      discAmt = 0;
    }
    if (this.state.isRounded == true) {
      const gross_total: number = await net_total;
      const inr_rate: number = 1 / this.state.currency_rate;
      const rounded_gross_total: number = Math.round(gross_total);
      const gross_total_base: number =
        this.state.currency_rate > 0
          ? Math.round(rounded_gross_total * inr_rate)
          : 0.0;
      const round_difference: number = rounded_gross_total - gross_total;
      this.setState({
        discount: discAmt,
        net_total: net_total,
        gross_total: rounded_gross_total,
        gross_total_base: gross_total_base,
        rounded_amount: round_difference,
      });
    } else {
      const gross_total: number = await net_total; /*+ tax_amount  - discAmt */
      const inr_rate: number = 1 / this.state.currency_rate;
      const gross_total_base: number =
        this.state.currency_rate > 0 ? Math.round(gross_total * inr_rate) : 0.0;
      this.setState({
        discount: discAmt,
        net_total: net_total,
        gross_total: gross_total,
        gross_total_base: gross_total_base,
        rounded_amount: 0,
      });
    }
  };

  addRow = () => {
    let newRaw = {
      product_id: { label: "", value: "" },
      product_description: "",
      hsn_or_sac: "",
      qty: 0,
      rate: 0,
      discount_per: 0,
      discount_amt: 0,
      tax_per: 0,
      tax_id: "",
      tax_amt: 0,
      amount: 0,
    };
    this.setState({ invoice_dtls: [...this.state.invoice_dtls, newRaw] });
  };

  removeRow = (i: number) => {
    const dtls = this.state.invoice_dtls;
    dtls.splice(i, 1);
    this.setState({ invoice_dtls: dtls });
    this.calcTotal();
  };

  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ showSubmitButton: false });
    this.setState({ isProgress: 1 });
    values["id"] = this.state.id;
    values["customer_currency"] = this.state.customer_currency;
    values["exchange_rate"] = this.state.currency_value;
    values["gross_total_base"] =
      this.state.gross_total_base > 0
        ? this.state.gross_total_base
        : this.state.gross_total;
    values["customer"] = this.state.valueCustomer?.value;
    values["dtls"] = this.state.invoice_dtls;
    values["tax_dtls"] = this.state.tax_dtls;
    values["invoice_date"] = this.state.invoice_date;
    values["due_date"] = this.state.due_date;
    values["po_or_estimate_date"] = this.state.po_or_estimate_date;
    values["net_total"] = this.state.net_total;
    values["discount"] = this.state.discount;
    values["tax_amount"] = this.state.tax_amount;
    values["gross_total"] = this.state.gross_total;
    values["prefix"] = this.state.invoice_prefix;
    values["document_type"] = this.state.document_type;
    values["to_addrress_type"] = this.state.to_addrress_type;
    values["reference1"] = this.state.reference1;
    values["reference2"] = this.state.reference2;
    values["reference3"] = this.state.reference3;
    values["reference4"] = this.state.reference4;
    values["bank_cashbook_id"] = this.state.bank_cashbook_id;
    values["footer_note"] = this.state.footer_note;
    values["terms_and_conditions"] = this.state.termsAndConditions;
    if (this.state.isRounded == true) {
      values["is_rounded"] = 2;
    } else if (this.state.isRounded == false) {
      values["is_rounded"] = 1;
    }
    if (this.state.isAuthorized == true) {
      values["is_authorized"] = 1;
    } else if (this.state.isAuthorized == false) {
      values["is_authorized"] = 2;
    }
    values["rounded_amount"] = this.state.rounded_amount;
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/invoice_save",
      values
    );
    if (resp.isRenew == 1) {
      this.openModalPlan();
    } else {
      if (resp.success == true) {
        this.setState({
          success: 1,
        });
        this.props.history.push("/invoices");
      } else {
        this.setState({
          success: 2,
          message: resp.message,
        });
      }
      this.setState({ isProgress: 0 });
    }
  };

  handlemodalProductSubmit = async (event: any, values: any) => {
    this.setState({
      isModalProgress: 1,
    });
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/product_save",
      values
    );

    if (resp.success === true) {
      this.setState({ modal_pro_is_open: false });

      this.setState({ prod_name: resp.product_name, prod_id: resp.product_id });
      const prod_name = this.state.prod_name ?? "";
      const prod_id = this.state.prod_id ?? "";

      let newOptionproduct = createOptionProduct(prod_name, prod_id);

      this.setState({
        isLoad: false,
        optionproduct: [...this.state.optionproduct, newOptionproduct],
        valueProduct: newOptionproduct,
      });
    } else {
    }
  };

  handleCreate = (inputValue: string) => {
    this.setState({
      isLoading: true,
      modal_cust_is_open: true,
      newCustomerName: inputValue,
    });

    setTimeout(() => {
      const { options } = this.state;
      this.setState({
        isLoading: false,
      });
    }, 1000);
  };

  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    let customer = this.props.customers.find(
      ({ id }) => id === newValue?.value
    );
    var rate = 0.0;
    var currency_value = 0.0;
    var customer_currency = "INR";
    if (customer) {
      if (this.state.company_currency != customer.currency) {
        const respR: any = await get(
          process.env.REACT_APP_API_URL +
            `/api/get_currency_rate/${customer.currency}`
        );
        rate = respR.data.rate;
        customer_currency = customer.currency;
        currency_value = 1 / respR.data.rate;
        currency_value = round(currency_value, 4);
      }
      let billing_state = undefined;
      let shipping_state = undefined;
      if (customer.billing_country == "99") {
        billing_state = this.state.states.find(
          ({ id }) => id === customer.billing_state
        ).state;
      } else {
        billing_state = customer.billing_state;
      }
      if (customer.shipping_country == "99") {
        shipping_state = this.state.states.find(
          ({ id }) => id === customer.shipping_state
        ).state;
      } else {
        shipping_state = customer.shipping_state;
      }
      let billing_country = this.state.countries.find(
        ({ id }) => id === customer.billing_country
      );
      let shipping_country = this.state.countries.find(
        ({ id }) => id === customer.shipping_country
      );

      let billing_address = "";
      let shipping_address = "";
      let shippingcompany = customer.customer_name;

      if (!isNull(customer.billing_address1)) {
        billing_address += `${customer.billing_address1} `;
      }
      if (!isNull(customer.billing_address2)) {
        billing_address += `${customer.billing_address2} `;
      }
      if (!isNull(customer.billing_town_or_city)) {
        billing_address += `${customer.billing_town_or_city}\n`;
      }
      billing_address += `${billing_state} - `;
      if (!isNull(customer.billing_pincode)) {
        billing_address += `${customer.billing_pincode} `;
      }
      billing_address += `${billing_country.name}`;

      if (!isNull(customer.shipping_address1)) {
        shipping_address += `${customer.shipping_address1} `;
      }
      if (!isNull(customer.shipping_address2)) {
        shipping_address += `${customer.shipping_address2} `;
      }
      if (!isNull(customer.shipping_town_or_city)) {
        shipping_address += `${customer.shipping_town_or_city}\n`;
      }
      shipping_address += `${shipping_state} - `;
      if (!isNull(customer.shipping_pincode)) {
        shipping_address += `${customer.shipping_pincode} `;
      }
      shipping_address += `${shipping_country.name}`;
      this.setState({
        billingAddress: billing_address,
        shippingAddress: shipping_address,
        shipping_company: shippingcompany,
        due_date: new Date(
          Moment()
            .add(Number(customer.credit_period), "days")
            .format("YYYY-MM-DD")
        ),
        // invoice_dtls: [
        //   {
        //     product_id: { label: undefined, value: undefined },
        //     product_description: "",
        //     hsn_or_sac: "",
        //     qty: 0,
        //     rate: 0,
        //     discount_per: 0,
        //     discount_amt: 0,
        //     tax_per: 0,
        //     tax_id: "",
        //     tax_amt: 0,
        //     amount: 0,
        //   },
        // ],
      });
      this.calcTotal();
    } else {
      this.setState({ showSubmitButton: false });
      this.setState({
        billingAddress: "",
        shippingAddress: "",
        shipping_company: "",
        due_date: null,
        tax_dtls: [],
        // invoice_dtls: [
        //   {
        //     product_id: { label: undefined, value: undefined },
        //     product_description: "",
        //     hsn_or_sac: "",
        //     qty: 0,
        //     rate: 0,
        //     discount_per: 0,
        //     discount_amt: 0,
        //     tax_per: 0,
        //     tax_id: "",
        //     tax_amt: 0,
        //     amount: 0,
        //   },
        // ],
      });
    }

    this.setState({
      valueCustomer: newValue,
      customer_currency: customer_currency,
      currency_rate: rate,
      currency_value: currency_value,
    });
    this.calcTotal();
  };

  handleProductCreate = (i: number, dtl: any, inputValue: string) => {
    this.setState({ isLoad: true, modal_pro_is_open: true });
    this.setState({
      isLoad: false,
      current_i: i,
      current_dtl: dtl,
      newProductName: inputValue,
    });
  };

  onChangeCountryhandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
  };

  openModal = () => {
    this.setState({ modal_cust_is_open: true });
  };

  closeModal = () => {
    this.setState({ modal_cust_is_open: false });
  };

  handleCallback = async (customer_param: {
    customer_name: string;
    id: string;
  }) => {
    let newOption = createOption(
      customer_param.customer_name,
      customer_param.id
    );

    const respC: any = await get(
      process.env.REACT_APP_API_URL + "/api/customers"
    );
    this.setState({ customers: respC.data });

    this.setState({
      isLoading: false,
      options: [...this.state.options, newOption],
      valueCustomer: newOption,
      modal_cust_is_open: false,
    });

    let customer = this.state.customers.find(
      ({ id }) => id === newOption?.value
    );
    var rate = 0.0;
    var customer_currency = "INR";
    var currency_value = 0.0;
    if (customer) {
      if (this.state.company_currency != customer.currency) {
        const respR: any = await get(
          process.env.REACT_APP_API_URL +
            `/api/get_currency_rate/${customer.currency}`
        );
        rate = respR.data.rate;
        customer_currency = customer.currency;
        currency_value = 1 / respR.data.rate;
        currency_value = round(currency_value, 4);
      }
      let billing_state = undefined;
      let shipping_state = undefined;
      if (customer.billing_country == "99") {
        billing_state = this.state.states.find(
          ({ id }) => id === customer.billing_state
        ).state;
      } else {
        billing_state = customer.billing_state;
      }
      if (customer.shipping_country == "99") {
        shipping_state = this.state.states.find(
          ({ id }) => id === customer.shipping_state
        ).state;
      } else {
        shipping_state = customer.shipping_state;
      }
      let billing_country = this.state.countries.find(
        ({ id }) => id === customer.billing_country
      );
      let shipping_country = this.state.countries.find(
        ({ id }) => id === customer.shipping_country
      );

      let billing_address = "";
      let shipping_address = "";
      let shippingcompany = "";
      if (isNull(customer.customer_name)) {
        shippingcompany = "";
      } else {
        shippingcompany = customer.customer_name;
      }
      if (!isNull(customer.billing_address1)) {
        billing_address += `${customer.billing_address1} `;
      }
      if (!isNull(customer.billing_address2)) {
        billing_address += `${customer.billing_address2} `;
      }
      if (!isNull(customer.billing_town_or_city)) {
        billing_address += `${customer.billing_town_or_city}\n`;
      }
      billing_address += `${billing_state} - `;
      if (!isNull(customer.billing_pincode)) {
        billing_address += `${customer.billing_pincode} `;
      }
      billing_address += `${billing_country.name}`;

      if (!isNull(customer.shipping_address1)) {
        shipping_address += `${customer.shipping_address1} `;
      }
      if (!isNull(customer.shipping_address2)) {
        shipping_address += `${customer.shipping_address2} `;
      }
      if (!isNull(customer.shipping_town_or_city)) {
        shipping_address += `${customer.shipping_town_or_city}\n`;
      }
      shipping_address += `${shipping_state} - `;
      if (!isNull(customer.shipping_pincode)) {
        shipping_address += `${customer.shipping_pincode} `;
      }
      shipping_address += `${shipping_country.name}`;
      this.setState({
        billingAddress: billing_address,
        shippingAddress: shipping_address,
        shipping_company: shippingcompany,
        due_date: new Date(
          Moment()
            .add(Number(customer.credit_period), "days")
            .format("YYYY-MM-DD")
        ),
        // invoice_dtls: [
        //   {
        //     product_id: { label: undefined, value: undefined },
        //     product_description: "",
        //     hsn_or_sac: "",
        //     qty: 0,
        //     rate: 0,
        //     discount_per: 0,
        //     discount_amt: 0,
        //     tax_per: 0,
        //     tax_id: "",
        //     tax_amt: 0,
        //     amount: 0,
        //   },
        // ],
      });
      this.calcTotal();
    } else {
      this.setState({
        billingAddress: "",
        shippingAddress: "",
        shipping_company: "",
        due_date: null,
        invoice_dtls: [
          {
            product_id: { label: "", value: "" },
            product_description: "",
            hsn_or_sac: "",
            qty: 0,
            rate: 0,
            discount_per: 0,
            discount_amt: 0,
            tax_per: 0,
            tax_amt: 0,
            amount: 0,
          },
        ],
      });
    }
    this.setState({
      customer_currency: customer_currency,
      currency_rate: rate,
      currency_value: currency_value,
    });
    this.calcTotal();
  };

  handleCallbackPdt = async (product: { name: string; id: string }) => {
    this.setState({ modal_pro_is_open: false });
    let newOptionproduct = createOptionProduct(product.name, product.id);
    const respP: any = await get(
      process.env.REACT_APP_API_URL + "/api/products"
    );
    this.setState({ products: respP.data });
    const actionMeta: ActionMeta<Option> = {
      action: "select-option",
      option: newOptionproduct,
      name: "product_id",
    };
    this.handleProductChange(
      newOptionproduct,
      actionMeta,
      this.state.current_i,
      this.state.current_dtl
    );
  };

  openProductModal = () => {
    this.setState({ modal_pro_is_open: true });
  };
  closeProductModal = () => {
    this.setState({
      newProductName: undefined,
    });
    this.setState({ modal_pro_is_open: false });
  };

  handleProductChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>,
    i: number,
    dtl: any
  ) => {
    console.log(newValue);
    this.setState({ valueProduct: newValue });
    let dtls = this.state.invoice_dtls;
    let value = newValue?.value;
    let product = this.state.products.find(({ id }) => id === value);

    if (product) {
      let taxes: Array<any> = [];
      if (this.state.valueCustomer) {
        const company = this.state.company;
        const comp_is_indian_state = company.is_indian_state;
        const company_state = company.company_state;

        const customer = await this.props.customers.find(
          ({ id }) => id === this.state.valueCustomer?.value
        );
        const billing_state = customer.billing_state;
        const place_supply = customer.place_supply;

        // if (comp_is_indian_state) {
        if (place_supply == 1) {
          if (company_state == billing_state) {
            taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
          } else {
            taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
          }
        } else {
          taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
        }
      } else {
        taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
      }
      dtl["tax_per"] = taxes.reduce(
        (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
        0
      );
      dtl["rate"] =
        this.state.currency_rate > 0
          ? this.state.currency_rate * product.rate
          : product.rate;
      dtl["rate"] = round(dtl.rate, 2);
      dtl["hsn_or_sac_text"] = "";
      dtl["hsn_or_sac"] = product.hsn_or_sac;
      if (product.type == "product") {
        dtl["hsn_or_sac_text"] = "(HSN)";
      }
      if (product.type == "service") {
        dtl["hsn_or_sac_text"] = "(SAC)";
      }
      if (dtl.qty > 0) {
        dtl["amount"] = round(dtl.qty * dtl.rate, 2);
      } else {
        dtl["qty"] = 1;
        dtl["amount"] = round(1 * dtl.rate, 2);
      }

      dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
      dtl["taxable_amount"] = round(dtl.amount, 2);
      dtl["amount"] = parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]);
      dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
      dtl["amount"] = round(dtl.amount, 2);
      if (product.tax_id == null) {
        dtl["tax_id"] = "";
      } else {
        dtl["tax_id"] = product.tax_id;
      }
      if (product.description == null) {
        dtl["product_description"] = "";
      } else {
        dtl["product_description"] = product.description;
      }
      dtl["tax_per"] = dtl.tax_per;

      dtl["product_id"] = newValue;
    } else {
      dtl["qty"] = 0;
      dtl["rate"] = 0;
      dtl["amount"] = 0;
      dtl["tax_amt"] = 0;
      dtl["tax_id"] = "";
      dtl["taxable_amount"] = 0;
      dtl["discount_amt"] = 0;
      dtl["discount_per"] = 0;
      dtl["tax_per"] = 0;
      dtl["hsn_or_sac"] = "";
      dtl["product_description"] = "";
      dtl["product_id"] = { label: "", value: "" };
    }

    dtls[i] = dtl;
    this.setState({ invoice_dtls: dtls });
    this.calcTotal();
  };

  onChangehandlerDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let dtls = this.state.invoice_dtls;

    if (name === "tax_id") {
      dtl["tax_id"] = value;
      let product = this.state.products.find(
        ({ id }) => id === dtl.product_id.value
      );
      if (product) {
        let taxes: Array<any> = [];
        if (this.state.valueCustomer) {
          const company = this.state.company;
          const company_state: string | undefined = company.company_state;

          const customer = this.props.customers.find(
            ({ id }) => id === this.state.valueCustomer?.value
          );
          const place_supply: number = customer.place_supply;
          const billing_state: string = customer.billing_state;
          if (value != "") {
            if (place_supply == 1) {
              if (company_state == billing_state) {
                let rowTax: any = this.state.taxes.find(
                  (tax: any) => tax.id == value
                );
                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
              } else {
                let rowTax: any = this.state.taxes.find(
                  (tax: any) => tax.id == value
                );
                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
              }
            } else {
              let rowTax: any = this.state.taxes.find(
                (tax: any) => tax.id == value
              );
              taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
            }
            dtl["tax_per"] = taxes.reduce(
              (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
              0
            );
          } else {
            dtl["tax_per"] = 0;
          }
          dtl["tax_amt"] = round(
            ((dtl.qty * dtl.rate - dtl.discount_amt) * dtl["tax_per"]) / 100,
            2
          );
          dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
          dtl["taxable_amount"] = round(
            dtl.qty * dtl.rate - parseFloat(dtl.discount_amt),
            2
          );
          dtl["taxable_amount"] = dtl["taxable_amount"].toFixed(2);
          dtl["amount"] = round(
            dtl.qty * dtl.rate -
              parseFloat(dtl.discount_amt) +
              parseFloat(dtl["tax_amt"]),
            2
          );
          dtl["amount"] = dtl["amount"].toFixed(2);
        } else {
          if (value == "") {
            dtl["tax_per"] = 0;
            dtl["tax_amt"] = 0;
            dtl["amount"] = round(
              dtl.qty * dtl.rate - dtl.discount_amt + dtl["tax_amt"],
              2
            );
          } else {
            let rowTax: any = this.state.taxes.find(
              (tax: any) => tax.id == value
            );
            taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
            dtl["tax_per"] = taxes.reduce(
              (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
              0
            );
            dtl["tax_amt"] = round(
              ((dtl.qty * dtl.rate - dtl.discount_amt) * dtl["tax_per"]) / 100,
              2
            );
            dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);

            dtl["taxable_amount"] = round(
              dtl.qty * dtl.rate - parseFloat(dtl.discount_amt),
              2
            );
            dtl["taxable_amount"] = dtl["taxable_amount"].toFixed(2);
            dtl["amount"] = round(
              dtl.qty * dtl.rate -
                parseFloat(dtl.discount_amt) +
                parseFloat(dtl["tax_amt"]),
              2
            );
            dtl["amount"] = dtl["amount"].toFixed(2);
          }
        }
      }
    }
    if (name === "qty") {
      dtl["discount_amt"] = round(
        (value * dtl.rate * dtl.discount_per) / 100,
        2
      );
      dtl["amount"] = round(value * dtl.rate - dtl["discount_amt"], 2);
      dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
      dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
      dtl["taxable_amount"] = dtl["amount"].toFixed(2);
      dtl["amount"] = round(
        parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
        2
      );
      dtl["amount"] = dtl["amount"].toFixed(2);
    }
    if (name === "rate") {
      if (dtl.qty > 0) {
        dtl["discount_amt"] = round(
          (dtl.qty * value * dtl.discount_per) / 100,
          2
        );
        dtl["amount"] = round(dtl.qty * value - dtl["discount_amt"], 2);
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
        dtl["taxable_amount"] = dtl["amount"].toFixed(2);
        dtl["amount"] = round(
          parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }
    if (name === "discount_per") {
      if (dtl.qty * dtl.rate > 0) {
        dtl["discount_amt"] = round((dtl.qty * dtl.rate * value) / 100, 2);
        dtl["amount"] = dtl.qty * dtl.rate - dtl["discount_amt"];
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
        dtl["taxable_amount"] = dtl["amount"].toFixed(2);
        dtl["amount"] = round(
          parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }

    if (name === "discount_amt") {
      if (dtl.qty * dtl.rate > 0) {
        dtl["discount_per"] = round((value / (dtl.qty * dtl.rate)) * 100, 2);
        dtl["amount"] = round(dtl.qty * dtl.rate - value, 2);
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
        dtl["taxable_amount"] = dtl["amount"].toFixed(2);
        dtl["amount"] = round(
          parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }

    dtl[name] = value;
    dtls[i] = dtl;
    this.setState({ invoice_dtls: dtls });
    this.calcTotal();
  };
  addCustomField(event: any) {
    this.ref_openModal();
  }
  ref_openModal = () => {
    this.setState({ ref_modal_open: true });
  };

  ref_closeModal = () => {
    this.setState({ ref_modal_open: false });
  };
  handleReference = async (reference: any) => {
    this.setState(reference);
    this.setState({ showSubmitButton: true });
    this.setState({ ref_modal_open: false });
  };
  addCustomerNote(event: any) {
    this.note_openModal();
  }
  note_openModal = () => {
    this.setState({ note_modal_open: true });
  };

  note_closeModal = () => {
    this.setState({ note_modal_open: false });
  };
  handleCustomerNote = async (footer_note: any) => {
    this.setState(footer_note);
    this.setState({ showSubmitButton: true });
    this.setState({ note_modal_open: false });
  };
  addterms(event: any) {
    this.terms_openModal();
  }
  terms_openModal = () => {
    this.setState({ terms_modal_open: true });
  };

  terms_closeModal = () => {
    this.setState({ terms_modal_open: false });
  };
  handleTermsandConditions = async (footer_note: any) => {
    this.setState(footer_note);
    this.setState({ showSubmitButton: true });
    this.setState({ terms_modal_open: false });
  };
  loadData = async () => {
    try {
      let invoicePermissions = this.props.permissions.filter(
        (permission: any) =>
          permission.module_permission_id === PermissionModule.INVOICE
      );
      if (this._isMounted) this.setState({ permissions: invoicePermissions });
      const permission = invoicePermissions.find(
        ({ action, is_active }: any) => action === "create" && is_active === 1
      );

      if (!permission) {
        this.props.history.push("/invoices");
        return;
      }

      const respCom: Company = this.props.companies;
      if (respCom.company_country === null) {
        this.props.history.push("/invoices");
        return;
      }

      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      if (this._isMounted) this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers = this.props.customers;
      let customerOptions: Array<any> = [];
      let products = this.props.products;
      let productOptions: Array<any> = [];

      customers.forEach(customer => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      products.forEach(product => {
        let newOption = createOptionProduct(product.name, product.id);
        productOptions = [...productOptions, newOption];
      });

      if (this._isMounted) {
        this.setState({
          isLoading: false,
          options: customerOptions,
          optionproduct: productOptions,
          products: products,
          customers: customers,
          isLoad: false,
        });
      }

      const respE: any = await get(
        process.env.REACT_APP_API_URL + "/api/get_invoice_no"
      );

      if (this._isMounted) {
        this.setState({
          invoice_number: respE.data.invoice_number,
          invoice_prefix: respE.data.invoice_prefix,
        });
      }

      if (this._isMounted) {
        this.setState({
          company: respCom,
          company_currency: respCom.currency,
          termsAndConditions: respCom.terms_and_conditions,
        });
      }

      if (this._isMounted) this.setState({ units: this.props.units });
      if (this._isMounted) this.setState({ taxes: this.props.taxes });
      if (this._isMounted) this.setState({ countries: this.props.countries });

      const response = await get(
        `${process.env.REACT_APP_API_URL}/api/get_all_bank`
      );
      const banks = response.data;

      if (this._isMounted) {
        this.setState({ banks: banks });
        const defaultBankCashbook = banks.find(
          (bank: any) => bank.is_default === 1
        );
        if (defaultBankCashbook) {
          this.setState({ bank_cashbook_id: defaultBankCashbook.id });
        }
      }

      if (this._isMounted) this.setState({ states: this.props.states });

      if (this._isMounted) {
        this.setState({ isLoading: false, loading: false });
      }
    } catch (err) {
      console.error(err);
    }
  };
  toggleDropdown = () => {
    this.setState({ dropdownVisible: !this.state.dropdownVisible });
  };
  selectLabel = (e: any) => {
    const dataId = e.currentTarget.getAttribute("data-id");
    if (dataId == 1) {
      this.setState({ label: "Estimate" });
    }
    if (dataId == 2) {
      this.setState({ label: "Proforma" });
    }
    if (dataId == 3) {
      this.setState({ label: "Quotation" });
    }
    if (dataId == 4) {
      this.setState({ label: "Purchase order" });
    }
    this.setState({ document_type: dataId });
    this.setState({ dropdownVisible: !this.state.dropdownVisible });
  };
  to_addrress = () => {
    this.setState({ to_addrress_visible: !this.state.to_addrress_visible });
  };
  select_to_addrress = (e: any) => {
    const dataId = e.currentTarget.getAttribute("data-id");
    this.setState({ to_addrress: get_addrress_type(dataId) });
    this.setState({ to_addrress_type: dataId });
    this.setState({ to_addrress_visible: !this.state.to_addrress_visible });
  };
  componentDidUpdate(prevProps: Params) {
    if (prevProps.customers.length != this.props.customers.length) {
      let customers = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map(customer => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      this.setState({
        options: customerOptions,
        customers: customers,
      });
    }
    if (prevProps.products.length != this.props.products.length) {
      let products = this.props.products;
      let productOptions: Array<any> = [];

      products.map(product => {
        let newOption = createOptionProduct(product.name, product.id);
        productOptions = [...productOptions, newOption];
      });

      this.setState({
        optionproduct: productOptions,
        products: products,
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  padLeadingZeros(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  toggleDropdownhide = () => {
    if (this.state.to_addrress_visible == true) {
      this.setState({ to_addrress_visible: false });
    }
    if (this.state.dropdownVisible == true) {
      this.setState({ dropdownVisible: false });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content" onClick={() => this.toggleDropdownhide()}>
          <MetaTags>
            <title>Invoice</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12" className="d-flex">
                <Col lg={10}>
                  <span style={{ float: "right" }}> </span>
                  <span>
                    <Breadcrumbs
                      title="Create Invoice"
                      breadcrumbItem="Create Invoice"
                    />
                  </span>
                </Col>
                {this.state.customer_currency != "INR" && (
                  <Col lg={2} className="d-flex">
                    <div className="col-md-2"></div>
                    <div className="col-lg-4 p-2 mb-2 bg-rate">
                      <span style={{ float: "right" }}>
                        1 {this.state.customer_currency} =
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <AvForm>
                        <AvField
                          name="currency_rate"
                          type="number"
                          placeholder="Currency Rate"
                          errorMessage="Enter Currency Rate"
                          value={this.state.currency_value}
                          onChange={this.onChangeCurrencyRate}
                          validate={{
                            required: { value: false },
                            pattern: {
                              value: "[0-9]+",
                              errorMessage: "Enter Number only",
                            },
                          }}
                        />
                      </AvForm>
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                {this.state.success === 2 ? (
                  <Alert color="danger">{this.state.message}</Alert>
                ) : null}

                <AvForm onValidSubmit={this.handleValidSubmit}>
                  <div className="form-head mb-3">
                    <Row>
                      <Col xl={4}>
                        <div className="row">
                          <div className="col-lg-6">
                            {this.state.loading ? (
                              <>
                                <Skeleton width={100} />
                                <Skeleton height={37} />
                              </>
                            ) : (
                              <>
                                <label
                                  htmlFor="progresspill-address-input"
                                  className="mb-0"
                                >
                                  Invoice Number
                                </label>
                                <div className="row mb-3">
                                  <div className="col-7 col-sm-4 pe-0">
                                    <AvField
                                      name="invoice_prefix"
                                      value={this.state.invoice_prefix}
                                      readOnly={true}
                                      className="ps-2"
                                    />
                                  </div>
                                  <div className="col-5 col-sm-8 ps-0">
                                    <AvField
                                      name="invoice_number"
                                      type="number"
                                      onWheel={(e: any) =>
                                        e.currentTarget.blur()
                                      }
                                      placeholder="Enter Invoice Number"
                                      errorMessage="Enter Invoice Number"
                                      value={this.state.invoice_number}
                                      onChange={this.onChangehandlerestimation}
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: "[0-9]+",
                                          errorMessage: "Enter Number only",
                                        },
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <label
                                    htmlFor="progresspill-address-input"
                                    className="mb-0"
                                  >
                                    Invoice Date
                                  </label>
                                  <div className="row">
                                    <DatePicker
                                      oneTap
                                      onChange={this.setInvoice_date}
                                      name="invoice_date"
                                      format="dd/MM/yyyy"
                                      value={
                                        this.state.invoice_date
                                          ? this.state.invoice_date
                                          : null
                                      }
                                    />
                                  </div>
                                  {/* <AvField
                                    name="invoice_date"
                                    type="date"
                                    placeholder="Enter Invoice Date"
                                    errorMessage="Enter Invoice Date"
                                    value={Moment().format("YYYY-MM-DD")}
                                  /> */}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <label
                                    htmlFor="PI/ Estimate Number"
                                    className="mb-0"
                                  >
                                    {this.state.label} Number{" "}
                                    <a
                                      className="fas fa-caret-down"
                                      onClick={() => this.toggleDropdown()}
                                    ></a>
                                  </label>
                                  {this.state.dropdownVisible && (
                                    <div className="dropdown">
                                      <div
                                        className="dropdown-menu show"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <button
                                          className={
                                            this.state.document_type == 1
                                              ? "active-doc dropdown-item"
                                              : "dropdown-item"
                                          }
                                          data-id={1}
                                          onClick={(e: any) =>
                                            this.selectLabel(e)
                                          }
                                        >
                                          Estimate
                                        </button>
                                        <button
                                          className={
                                            this.state.document_type == 2
                                              ? "active-doc dropdown-item"
                                              : "dropdown-item"
                                          }
                                          data-id={2}
                                          onClick={(e: any) =>
                                            this.selectLabel(e)
                                          }
                                        >
                                          Proforma
                                        </button>
                                        <button
                                          className={
                                            this.state.document_type == 3
                                              ? "active-doc dropdown-item"
                                              : "dropdown-item"
                                          }
                                          data-id={3}
                                          onClick={(e: any) =>
                                            this.selectLabel(e)
                                          }
                                        >
                                          Quotation
                                        </button>
                                        <button
                                          className={
                                            this.state.document_type == 4
                                              ? "active-doc dropdown-item"
                                              : "dropdown-item"
                                          }
                                          data-id={4}
                                          onClick={(e: any) =>
                                            this.selectLabel(e)
                                          }
                                        >
                                          Purchase order
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <AvField
                                    name="po_or_estimate_no"
                                    type="text"
                                    placeholder={`${this.state.label} Number`}
                                    errorMessage={`${this.state.label} Number`}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <label
                                    htmlFor="progresspill-address-input"
                                    className="mb-0"
                                  >
                                    Invoice Due Date
                                  </label>
                                  <div className="row">
                                    <DatePicker
                                      oneTap
                                      onChange={this.setDue_date}
                                      name="due_date"
                                      format="dd/MM/yyyy"
                                      value={
                                        this.state.due_date
                                          ? this.state.due_date
                                          : null
                                      }
                                    />
                                  </div>
                                  {/* <AvField
                                    name="due_date"
                                    type="date"
                                    placeholder="Enter Invoice Due Date"
                                    value={Moment()
                                      .add(this.state.credit_period, "days")
                                      .format("YYYY-MM-DD")}
                                  /> */}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn-cus-fie inv-details w-100 d-none-sm"
                                    onClick={(e: any) => this.addCustomField(e)}
                                    type="button"
                                  >
                                    Add Custom Field
                                  </button>
                                  {/* <AvField
                                    name="reference"
                                    className="inv-details"
                                    type="text"
                                    rows="3"
                                    placeholder="Reference"
                                    errorMessage="Enter PI/ Estimate Number"
                                  /> */}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <label
                                    htmlFor="po_or_estimate_date"
                                    className="mb-0"
                                  >
                                    {this.state.label} Date
                                  </label>
                                  <div className="row">
                                    <DatePicker
                                      oneTap
                                      onChange={this.setPo_Date}
                                      name="po_or_estimate_date"
                                      format="dd/MM/yyyy"
                                      value={
                                        this.state.po_or_estimate_date
                                          ? this.state.po_or_estimate_date
                                          : null
                                      }
                                    />
                                  </div>
                                  {/* <AvField
                                    name="po_or_estimate_date"
                                    type="date"
                                    placeholder={`${this.state.label} Date`}
                                    value={
                                      this.state.po_or_estimate_date
                                        ? Moment(
                                            this.state.po_or_estimate_date
                                          ).format("YYYY-MM-DD")
                                        : ""
                                    }
                                  /> */}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="row d-visible-sm">
                            <button
                              className="btn-cus-fie w-100 mt-2 mb-1"
                              onClick={(e: any) => this.addCustomField(e)}
                              type="button"
                            >
                              Add Custom Field
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={8} className="inv-details">
                        <Row>
                          <div className="col-lg-6 mb-3">
                            {this.state.isLoading ? (
                              <Skeleton height={37} />
                            ) : (
                              <>
                                <CreatableSelect
                                  styles={{
                                    option: (provided: any, state: any) => ({
                                      ...provided,
                                      ":hover": {
                                        backgroundColor: "#10a37f",
                                        color: "#fff",
                                      },
                                      backgroundColor: state.isSelected
                                        ? "#10a37f"
                                        : "inherit",
                                    }),
                                  }}
                                  isClearable={true}
                                  isDisabled={this.state.isLoading}
                                  isLoading={this.state.isLoading}
                                  onChange={this.handleChange}
                                  onCreateOption={this.handleCreate}
                                  options={this.state.options}
                                  placeholder="Select Customer...."
                                  value={this.state.valueCustomer}
                                  name="customer"
                                  className="field-required"
                                />
                                {/* <AvField tag={CreatableSelect}
                                                            name="customer"
                                                            isDisabled={this.state.isLoading}
                                                            isLoading={this.state.isLoading}
                                                            onChange={this.handleChange}
                                                            onCreateOption={this.handleCreate}
                                                            options={this.state.options}
                                                            value={this.state.valueCustomer}
                                                            placeholder="Please select or create customer"
                                                            errorMessage="Please select or create customer"
                                                            validate={{ required: { value: true } }}
                                                        /> */}
                              </>
                            )}
                          </div>
                          <div className="col-lg-6 mb-3 d-none-sm">
                            {this.state.isLoading ? (
                              <Skeleton height={37} />
                            ) : (
                              <>
                                <AvField
                                  name="shipping_company"
                                  type="text"
                                  placeholder={`${this.state.to_addrress} Company`}
                                  maxLength={500}
                                  value={this.state.shipping_company}
                                />
                              </>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl={6}>
                            {this.state.loading ? (
                              <>
                                <Skeleton width={100} />
                                <Skeleton height={37} />
                              </>
                            ) : (
                              <>
                                <div className="mb-3">
                                  <label htmlFor="billingAddress">
                                    Bill To
                                  </label>
                                  <AvField
                                    name="billingAddress"
                                    type="textarea"
                                    placeholder="Bill To"
                                    errorMessage="Bill To"
                                    rows="4"
                                    validate={{ required: { value: false } }}
                                    readOnly={true}
                                    value={this.state.billingAddress}
                                  />
                                </div>
                              </>
                            )}
                          </Col>
                          <div className="col-lg-6 mb-3 d-visible-sm">
                            {this.state.isLoading ? (
                              <Skeleton height={37} />
                            ) : (
                              <>
                                <AvField
                                  name="shipping_company"
                                  type="text"
                                  placeholder={`${this.state.to_addrress} Company`}
                                  maxLength={500}
                                  value={this.state.shipping_company}
                                />
                              </>
                            )}
                          </div>
                          <Col xl={6}>
                            {this.state.loading ? (
                              <>
                                <Skeleton width={100} />
                                <Skeleton height={37} />
                              </>
                            ) : (
                              <>
                                <div className="mb-3">
                                  <div className="d-flex">
                                    <div className="col-md-4">
                                      <label>
                                        {this.state.to_addrress} To {"  "}
                                        <a
                                          className="fas fa-caret-down"
                                          onClick={() => this.to_addrress()}
                                        ></a>
                                      </label>
                                      {this.state.to_addrress_visible && (
                                        <div className="dropdown">
                                          <div
                                            className="dropdown-menu show"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            {addrress_type.map((type: any) => (
                                              <button
                                                key={type.id}
                                                className={
                                                  this.state.to_addrress_type ==
                                                  type.id
                                                    ? "active-doc dropdown-item"
                                                    : "dropdown-item"
                                                }
                                                data-id={type.id}
                                                onClick={(e: any) =>
                                                  this.select_to_addrress(e)
                                                }
                                              >
                                                {type.name}
                                              </button>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {this.state.valueCustomer != undefined && (
                                      <div className="col-md-8 text-end">
                                        <span
                                          className="text-success mx-2"
                                          onClick={(e: any) =>
                                            this.openShippingModal()
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <li className="bx bx-down-arrow-circle"></li>
                                          Address Book
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <AvField
                                    name="shippingAddress"
                                    type="textarea"
                                    placeholder={`${this.state.to_addrress} Address`}
                                    errorMessage={`${this.state.to_addrress} Address`}
                                    rows="4"
                                    validate={{ required: { value: false } }}
                                    value={this.state.shippingAddress}
                                  />
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <MediaQuery
                    mobile={
                      <ItemAddMobile
                        valueCustomer={this.state.valueCustomer}
                        taxes={this.state.taxes}
                        countries={this.state.countries}
                        states={this.state.states}
                        currency_value={this.state.currency_value}
                        currency_rate={this.state.currency_rate}
                        customers={this.state.customers}
                        products={this.state.products}
                        companies={this.state.company}
                        permissions={this.state.permissions}
                        invoice_dtls={this.state.invoice_dtls}
                        parentCallback={this.add_item}
                        handleProductCreate={this.handleProductCreate}
                        mobile_view={true}
                      />
                    }
                    desktop={
                      <div className="row">
                        <div className="table-responsive unset-overflow">
                          {this.state.loading == true ? (
                            <>
                              <Table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                    <th>
                                      <Skeleton width={100} />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                    <td>
                                      <Skeleton height={20} />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </>
                          ) : (
                            <>
                              <Table className="table mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th style={{ width: "2%" }}>#</th>
                                    <th>Product/Service</th>
                                    <th style={{ width: "10%" }}>HSN/SAC</th>
                                    <th style={{ width: "10%" }}>Qty</th>
                                    <th style={{ width: "10%" }}>
                                      Rate({this.state.customer_currency})
                                    </th>
                                    <th style={{ width: "10%" }}>Discount</th>
                                    <th style={{ width: "10%" }}>Tax</th>
                                    <th style={{ width: "10%" }}>
                                      Amount({this.state.customer_currency})
                                    </th>
                                    <th style={{ width: "2%" }}>Remove</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.invoice_dtls.map((dtl, i) => {
                                    return (
                                      <tr key={i}>
                                        <th scope="row" className="count">
                                          {i + 1}
                                        </th>
                                        <td className="slct-td">
                                          <Row>
                                            <div className="mb-3">
                                              {this.state.isLoad ? (
                                                <Skeleton height={37} />
                                              ) : (
                                                <>
                                                  <CreatableSelect
                                                    isClearable
                                                    isDisabled={
                                                      this.state.isLoad
                                                    }
                                                    isLoading={
                                                      this.state.isLoad
                                                    }
                                                    onChange={(
                                                      newValue: OnChangeValue<
                                                        Option,
                                                        false
                                                      >,
                                                      actionMeta: ActionMeta<Option>
                                                    ) =>
                                                      this.handleProductChange(
                                                        newValue,
                                                        actionMeta,
                                                        i,
                                                        dtl
                                                      )
                                                    }
                                                    styles={{
                                                      option: (
                                                        provided: any,
                                                        state: any
                                                      ) => ({
                                                        ...provided,
                                                        ":hover": {
                                                          backgroundColor:
                                                            "#10a37f",
                                                          color: "#fff",
                                                        },
                                                        backgroundColor:
                                                          state.isSelected
                                                            ? "#10a37f"
                                                            : "inherit",
                                                      }),
                                                    }}
                                                    onCreateOption={(
                                                      inputValue: string
                                                    ) =>
                                                      this.handleProductCreate(
                                                        i,
                                                        dtl,
                                                        inputValue
                                                      )
                                                    }
                                                    options={
                                                      this.state.optionproduct
                                                    }
                                                    value={dtl.product_id}
                                                    name="product_id"
                                                    className="field-required"
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </Row>
                                          <Row>
                                            <AvField
                                              name="product_description"
                                              type="text"
                                              placeholder="Description"
                                              maxLength={500}
                                              value={dtl.product_description}
                                              onChange={(e: any) =>
                                                this.onChangehandlerDtl(
                                                  e,
                                                  i,
                                                  dtl
                                                )
                                              }
                                            />
                                          </Row>
                                        </td>
                                        <td className="slct-td">
                                          <Row>
                                            <div className="mb-1">
                                              <AvField
                                                name="hsn_or_sac"
                                                type="text"
                                                placeholder="HSN/SAC"
                                                readOnly={true}
                                                value={dtl.hsn_or_sac}
                                              />
                                            </div>
                                          </Row>
                                          <Row className="text-center">
                                            <span>{dtl.hsn_or_sac_text}</span>
                                          </Row>
                                        </td>
                                        <td>
                                          <AvField
                                            name="qty"
                                            type="number"
                                            placeholder="Enter qty"
                                            errorMessage="Enter qty"
                                            className="field-required"
                                            value={dtl.qty}
                                            validate={{
                                              required: { value: false },
                                              // pattern: {
                                              //   value: "[0-9]+",
                                              //   errorMessage: "Enter Number only",
                                              // },
                                            }}
                                            onChange={(e: any) =>
                                              this.onChangehandlerDtl(e, i, dtl)
                                            }
                                          />
                                        </td>
                                        <td>
                                              <AvField
                                                name="rate"
                                                type="number"
                                                placeholder="Enter rate"
                                                errorMessage="Enter rate"
                                                className="field-required"
                                                value={dtl.rate}
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                onChange={(e: any) =>
                                                  this.onChangehandlerDtl(
                                                    e,
                                                    i,
                                                    dtl
                                                  )
                                                }
                                              />
                                        </td>
                                        <td>
                                          <Row>
                                            <div className="mb-3">
                                              <AvField
                                                name="discount_amt"
                                                type="number"
                                                placeholder="Amount"
                                                value={dtl.discount_amt}
                                                onChange={(e: any) =>
                                                  this.onChangehandlerDtl(
                                                    e,
                                                    i,
                                                    dtl
                                                  )
                                                }
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="col-md-9 pe-0">
                                              <AvField
                                                name="discount_per"
                                                type="number"
                                                value={dtl.discount_per}
                                                onChange={(e: any) =>
                                                  this.onChangehandlerDtl(
                                                    e,
                                                    i,
                                                    dtl
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-2 ps-0 input-group-text bg-white">
                                              <span>&nbsp;%</span>
                                            </div>
                                          </Row>
                                        </td>
                                        <td>
                                          <Row>
                                            <div className="mb-3">
                                              <AvField
                                                name="tax_amt"
                                                type="number"
                                                placeholder="Tax amount"
                                                errorMessage="Tax amount"
                                                value={dtl.tax_amt}
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                readOnly={true}
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <AvField
                                              type="select"
                                              name="tax_id"
                                              value={dtl.tax_id}
                                              onChange={(e: any) =>
                                                this.onChangehandlerDtl(
                                                  e,
                                                  i,
                                                  dtl
                                                )
                                              }
                                            >
                                              <option value="" key="">
                                                Tax Per
                                              </option>
                                              {this.state.taxes.map(tax => (
                                                <option
                                                  value={tax.id}
                                                  key={tax.id}
                                                >
                                                  {tax.tax_name}
                                                </option>
                                              ))}
                                            </AvField>
                                          </Row>
                                        </td>
                                        <td className="slct-td">
                                          <Row>
                                            <div className="mb-3">
                                              <AvField
                                                name="amount"
                                                type="number"
                                                placeholder="Enter amount"
                                                errorMessage="Enter amount"
                                                value={dtl.amount || ""}
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                readOnly={true}
                                              />
                                            </div>
                                          </Row>
                                          <Row>
                                            <div className="icon-wrapper">
                                              <AvField
                                                name="taxable_amount"
                                                type="text"
                                                readOnly={true}
                                                value={dtl.taxable_amount}
                                                placeholder="Taxable value"
                                              />
                                              <div
                                                className="tooltip-icon"
                                                style={{ marginLeft: "-3px" }}
                                              >
                                                Taxable Amount
                                              </div>
                                            </div>
                                          </Row>
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            className="btn tbl-btn"
                                            onClick={() => this.removeRow(i)}
                                          >
                                            <i className="fas fa-trash"></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="mb-5 add-row">
                            {this.state.loading == true ? (
                              <Skeleton height={20} width={100} />
                            ) : (
                              <Link
                                to="#"
                                className="btn"
                                onClick={() => this.addRow()}
                              >
                                <i className="bx bx-plus me-1"></i> Add Row
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />

                  <div className="row justify-content-between">
                    <div className="col-lg-4">
                      <div className="mb-3">
                        {this.state.loading ? (
                          <>
                            <Skeleton width={100} />
                            <Skeleton height={37} />
                          </>
                        ) : (
                          <>
                            <div
                              className="col-lg-10 col-md-6 border-note ps-3 py-2 mb-3 d-flex justify-content-left pointer"
                              onClick={(e: any) => this.addCustomerNote(e)}
                            >
                              Customer Notes (Edit/Add new)
                            </div>
                            <div
                              className="col-lg-10 col-md-6 border-note ps-3 py-2 mb-3 d-flex justify-content-left pointer"
                              onClick={(e: any) => this.addterms(e)}
                            >
                              Terms and Conditions (Edit/Add new)
                            </div>
                            <div className="col-lg-10 col-md-6">
                              <AvField
                                type="select"
                                name="bank_cashbook_id"
                                className="border-note ps-3 py-2"
                                value={this.state.bank_cashbook_id}
                                onChange={this.onChangehandler}
                              >
                                <option value="" key="">
                                  --Select a bank--
                                </option>
                                {this.state.banks &&
                                  this.state.banks.map(bank => (
                                    <option value={bank.id} key={bank.id}>
                                      {bank.bank_name}
                                    </option>
                                  ))}
                              </AvField>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="total">
                        {this.state.loading ? (
                          <>
                            <Skeleton width={100} />
                            <Skeleton height={37} />
                          </>
                        ) : (
                          <>
                            <ul>
                              <li>
                                <label>
                                  Round Off{" "}
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    onChange={this.roundtotal}
                                    checked={this.state.isRounded}
                                  />
                                </label>
                                <span>
                                  {number_format(this.state.rounded_amount)}
                                </span>
                                {/* <span>
                                  {(() => {
                                    let amount = this.state.rounded_amount;
                                    let formattedAmount;
                                    
                                    if (!isNaN(amount)) {
                                        if (amount === 0) {
                                            formattedAmount = "0";
                                        }else if(amount < 0){
                                          let integerAmount = Math.abs(Math.round(amount * 100));
                                          formattedAmount = '-' + integerAmount.toString().padStart(2, "0");
                                        }else {
                                            let integerAmount = Math.abs(Math.round(amount * 100));
                                            formattedAmount = integerAmount.toString().padStart(2, "0");
                                        }
                                    } 
                                    return formattedAmount;
                                  })()}
                                </span> */}
                              </li>
                              <li>
                                <label>
                                  Gross Total ({this.state.customer_currency})
                                </label>
                                <span>
                                  {number_format(this.state.gross_total)}
                                </span>
                              </li>
                              {this.state.customer_currency != "INR" && (
                                <li>
                                  <label>Gross Total (INR)</label>
                                  <span className="bold">
                                    {number_format(this.state.gross_total_base)}
                                  </span>
                                </li>
                              )}
                              {/* <li>
                                                                <label> Sub Total</label>
                                                                <span>{this.state.net_total}</span>
                                                            </li>
                                                            <li>
                                                                <label>Discount</label>
                                                                <span>{this.state.discount}</span>
                                                            </li> */}
                              {this.state.tax_dtls.length > 0 && (
                                <li>
                                  <label>Tax</label>
                                  <span>&nbsp;</span>
                                </li>
                              )}
                              {this.state.tax_dtls.map((tax, i) => {
                                return (
                                  <li key={i}>
                                    <label>
                                      {tax.label} ({number_format(tax.tax_rate)}
                                      %)
                                    </label>
                                    <span>{number_format(tax.tax_amount)}</span>
                                  </li>
                                );
                              })}
                              {/* <li>
                                                                <label>Tax Amount</label>
                                                                <span>{this.state.tax_amount}</span>
                                                            </li> */}
                            </ul>
                          </>
                        )}
                      </div>
                      {this.state.loading ? (
                        <>
                          <Skeleton width={100} />
                          <Skeleton height={37} />
                        </>
                      ) : (
                        <div className="m-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck1"
                            onChange={this.sign}
                            checked={this.state.isAuthorized}
                          />
                          <span>
                            {" "}
                            Display Authorized Signature on the invoice
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center gap-2 mb-4 mt-2">
                    {this.state.loading ? (
                      <>
                        <Skeleton width={100} height={37} />{" "}
                        <Skeleton width={100} height={37} />
                      </>
                    ) : (
                      <>
                        {this.state.isProgress ? (
                          <>
                            <Button
                              type="submit"
                              className="bmt-btn-submit "
                              disabled={true}
                            >
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              Submitting
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="reset"
                              className="bmt-btn-close inv-btn"
                              onClick={this.resetForm}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="bmt-btn-submit inv-btn"
                            >
                              Submit
                            </Button>{" "}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </AvForm>
              </Col>
            </Row>
          </div>
          {this.state.showSubmitButton && (
            <InvoiceLeavingGuard
              when={this.state.showSubmitButton}
              navigate={path => this.props.history.push(path)}
              shouldBlockNavigation={location => {
                if (
                  this.state.showSubmitButton &&
                  location.pathname !== "/invoice_add"
                ) {
                  return true;
                }
                return false;
              }}
            />
          )}
          <div>
            <CustomerCreateFullModal
              newCustomerName={this.state.newCustomerName}
              modal_cust_is_open={this.state.modal_cust_is_open}
              closeModal={this.closeModal}
              parentCallback={this.handleCallback}
            />
          </div>
          <div>
            <ReferenceModal
              ref_modal_open={this.state.ref_modal_open}
              prop_reference1={this.state.reference1}
              prop_reference2={this.state.reference2}
              prop_reference3={this.state.reference3}
              prop_reference4={this.state.reference4}
              ref_openModal={this.ref_openModal}
              ref_closeModal={this.ref_closeModal}
              parentCallback={this.handleReference}
              title={"invoice"}
            />
          </div>
          <div>
            <CustomerNoteModal
              note_modal_open={this.state.note_modal_open}
              prop_footer_note={this.state.footer_note}
              note_openModal={this.note_openModal}
              note_closeModal={this.note_closeModal}
              parentCallback={this.handleCustomerNote}
              title={"invoice"}
            />
          </div>
          <div>
            <TermsAndConditionsModal
              terms_modal_open={this.state.terms_modal_open}
              prop_termsAndConditions={this.state.termsAndConditions}
              terms_openModal={this.terms_openModal}
              terms_closeModal={this.terms_closeModal}
              parentCallback={this.handleTermsandConditions}
              title={"invoice"}
            />
          </div>
          <div>
            {/* <ProductCreateModal newProductName={this.state.newProductName} modal_pro_is_open={this.state.modal_pro_is_open} openProductModal={this.openProductModal} closeProductModal={this.closeProductModal} parentCallback={this.handleCallbackPdt} /> */}
            <ProductCreateModalNew
              newProductName={this.state.newProductName}
              modal_pro_is_open={this.state.modal_pro_is_open}
              openProductModal={this.openProductModal}
              closeProductModal={this.closeProductModal}
              parentCallback={this.handleCallbackPdt}
            />
          </div>
          <RenewPlan
            modal_is_open={this.state.modalPlan}
            openModal={this.openModalPlan}
            closeModal={this.closeModalPlan}
            successModalPlan={this.successModalPlan}
          />
          <ShippngAddressListModal
            modal_is_open={this.state.shipping_modal_open}
            close={this.closeShippingModal}
            modal_is_save_open={this.state.shipping_modal_save_open}
            saveOpen={this.openShippingSaveModal}
            saveClose={this.closeShippingSaveModal}
            active_customer={this.state.valueCustomer?.value}
            parentCallback={this.ChangeShippingAddress}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: {
  units: any;
  taxes: any;
  countries: any;
  states: any;
  customers: any;
  products: any;
  companies: any;
  permissions: any;
}) => {
  return {
    units: state.units.units,
    taxes: state.taxes.taxes,
    countries: state.countries.countries,
    states: state.states.states,
    customers: state.customers.customers,
    products: state.products.products,
    companies: state.companies.companies,
    permissions: state.permissions.permissions,
  };
};

export default connect(mapStateToProps)(withRouter(InvoiceCreate));
