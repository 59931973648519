import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
interface Params extends RouteComponentProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

type State = {
  modalInfo: boolean;
  message: string;
  isProgress: number;
};
class ConfirmationModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalInfo: false,
      message: "",
      isProgress: 0,
    };
  }

  componentWillReceiveProps(nextProps: Params) {
    this.setState({
      modalInfo: nextProps.isOpen,
      message: nextProps.message,
    });
  }
  onConfirm = () => {
    this.setState({ isProgress: 1 });
    this.props.onConfirm();
    setTimeout(() => {
      this.setState({ isProgress: 0, modalInfo: false });
    }, 1000);
  };

  onClose = () => {
    this.setState({ isProgress: 0, modalInfo: false });
  };

  render() {
    const { onClose } = this.props;
    const { isProgress } = this.state;
    return (
      <React.Fragment>
      <Modal
        isOpen={this.state.modalInfo} centered={true} className="exampleModal">
        <div className="modal-content">
          <ModalBody style={{ paddingBottom: 0 }}>
            <h5 className="invoice-guard-heading">{this.state.message}</h5>
            </ModalBody>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              {isProgress === 1 ? (
                <Button
                  type="submit"
                  className="bmt-btn-submit itemsubmit"
                  disabled={true}
                >
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  Submitting
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    className="bmt-btn-close itemsubmit"
                    style={{ width: "100px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                  className="bmt-btn-submit itemsubmit"
                    style={{ width: "100px" }}
                    onClick={this.onConfirm} // Call internal onConfirm
                  >
                    Yes
                  </Button>
                </>
              )}
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(ConfirmationModal);
