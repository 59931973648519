import React, { Component, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Import Editor
import moment from "moment";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { post } from "src/helpers/api_helper";
import Ledger from "../Ledger";
import CustomerInfoModal from "../Customers/CustomerInfoModal";

interface Params extends RouteComponentProps {
  id: string;
  customer_name: string;
  isOpen: boolean;
  toggle: () => void;
  email: string;
  invoice_no: string;
  invoice_id: string;
  phone: string;
  gstin: string;
  shipping_company: string;
  shipping_address: string;
  billing_address: string;
  to_addrress_type: string;
  pan: string;
  customer_id: string;
  log_data: Array<any>;
}

type State = {
  id: string;
  customer_name: string;
  modalInfo: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  invoice_no: string;
  invoice_id: string;
  phone: string;
  gstin: string;
  shipping_company: string;
  shipping_address: string;
  billing_address: string;
  to_addrress_type: string;
  pan: string;
  modelLedger: boolean;
  modelContact: boolean;
  log_data: Array<any>;
};
interface LogDataItem {
  id: number;
  hdr_id: string;
  action: string;
  inv_log_type: string;
  recoded_by: Number;
  recoded_at: Date;
}
class CompanyInfoModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    const html = `<p>download invoice</p>`;
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.state = {
      id: "",
      customer_name: "",
      modalInfo: false,
      isSending: false,
      editorState: EditorState.createWithContent(content),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      invoice_no: "",
      invoice_id: "",
      phone: "",
      gstin: "",
      pan: "",
      shipping_company: "",
      shipping_address: "",
      to_addrress_type: "",
      billing_address: "",
      modelLedger: false,
      modelContact: false,
      log_data: [],
    };
  }
  componentWillReceiveProps(props: Params) {
    this.setState({
      id: props.id,
      customer_name: props.customer_name,
      modalInfo: props.isOpen,
      email: props.email,
      invoice_no: props.invoice_no,
      invoice_id: props.invoice_id,
      log_data: props.log_data,
      phone: props.phone,
      gstin: props.gstin,
      shipping_company: props.shipping_company,
      shipping_address: props.shipping_address,
      to_addrress_type: props.to_addrress_type,
      billing_address: props.billing_address,
      pan: props.pan,
    });
  }
  ledgerOpen = () => {
    this.setState({ modelLedger: true });
  };
  ledgerClose = () => {
    this.setState({ modelLedger: false });
  };
  ContactOpen = () => {
    this.setState({ modelContact: true });
  };
  toggleInfo = () => {
    try {
      this.setState({ modelContact: !this.state.modelContact });
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    const logData = this.state.log_data || [];
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
        >
          <div className="modal-content">
            <div className="modal-header px-2 py-1 border-0">
              <h5
                className="modal-title inv_header_h5"
                id="exampleModalLabel"
                style={{
                  textTransform: "uppercase",
                }}
              >
                Invoice No: {this.state.invoice_no}
              </h5>
            </div>
            <div className="modal-body inv_modal_body">
              <Card className="m-1">
                <CardBody className="px-3 py-1">
                  <CardText className="mb-0">
                    <small className="text-primary">Bill To</small> <br />
                    {this.state.customer_name} <br />
                    <p className="font-size-14 m-0">
                      {this.state.billing_address}
                    </p>
                  </CardText>
                </CardBody>
              </Card>
              <Card className="m-1">
                <CardBody className="px-3 py-1">
                  <CardText className="mb-0">
                    <small className="text-danger">
                      {this.state.to_addrress_type} To
                    </small>{" "}
                    <br />
                    {this.state.shipping_company} <br />
                    <p className="font-size-14 m-0">
                      {this.state.shipping_address}
                    </p>
                  </CardText>
                </CardBody>
              </Card>
              <div className="inv_sec_2 d-flex">
                <div
                  className="text-center w-100"
                  style={{ textAlign: "center", display: "block" }}
                >
                  <Button
                    type="button"
                    className="bmt-btn-close inv-info"
                    onClick={() => this.props.toggle()}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    className="bmt-btn-submit btn-sm inv-info"
                    onClick={() => this.ContactOpen()}
                  >
                    Contact
                  </Button>
                  <Button
                    type="submit"
                    className="bmt-btn-submit btn-sm inv-info"
                    onClick={() => this.ledgerOpen()}
                  >
                    View Ledger
                  </Button>
                </div>
              </div>
              <ul className="inv-list-progress-mn">
                {logData.map((item, index) => (
                  <li
                    key={index}
                    className="inv-list-progress position-relative"
                  >
                    <span className="inv-head position-absolute">
                      {" "}
                      {item.inv_log_type === 1
                        ? "Invoice"
                        : item.inv_log_type === 2
                        ? "Payment"
                        : "Invoice"}{" "}
                      {item.action}
                    </span>
                    <div className="inv-sub-details">
                      <span className="date">
                        {moment(item.recoded_at).format("DD MMM YYYY")}
                      </span>
                      <span className="time">
                        {moment(item.recoded_at).format("h:mm:ss A")}
                      </span>
                      <span className="by">
                        By {item.first_name} {item.second_name}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal>
        <CustomerInfoModal
          phone={this.state.phone}
          gstin={this.state.gstin}
          pan={this.state.pan}
          isOpen={this.state.modelContact}
          toggle={this.toggleInfo}
          email={this.state.email}
        />
        {this.state.modelLedger && (
          <Ledger
            invoice_id={this.state.id}
            openModal={this.ledgerOpen}
            modal_is_open={this.state.modelLedger}
            closeModal={this.ledgerClose}
            customer_name={this.state.customer_name}
            customer_id={this.props.customer_id}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(CompanyInfoModal);
